<template>
  <div class="home-services" v-if="!$root.loading">
    <h4 class="title text-white">Events</h4>
    <div class="ho-event ho-event-mob-bot-sp">
      <ul>
        <li v-for="(item, index) in events" :key="index">
          <div class="ho-ev-date">
            <span>{{item.start_date|formatDate('Do')}}</span>
            <span>{{item.start_date|formatDate('MMM YYYY')}}</span>
          </div>
          <div class="ho-ev-link">
            <router-link
              v-if="item.type==='content'"
              :to="{ name:'event.detail', params: {slug: item.slug }}"
            >
              <h4>{{item.title}}</h4>
            </router-link>
            <a v-else :href="item.FullUrl+ item.file" target="_blank">
              <h4>{{item.title}}</h4>
            </a>
            <p
              style="white-space: normal;"
              v-if="item.type==='content'"
            >{{ getPostBody(item.description,30) }}</p>
            <span v-if="item.start_time">
              {{item.start_time|formatTime("hh:mm A")}}-
              {{item.end_time|formatTime("hh:mm A")}}
            </span>
          </div>
        </li>
      </ul>
    </div>
    <router-link :to="{ name:'events'}" class="btn btn-light mt-3">View All</router-link>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Events",
  data() {
    return {
      events: {}
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/events")
        .then(respons => {
          this.events = respons.data.events;
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
