import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
Vue.use(Vuex);
Vue.use(VueRouter);


import {
    Loader,
    Admission,
    AdmissionView,
    AllLogin,
    ApplicantAdmit,
    ApplicantOnlineExam,
    ApplicantOnlineExamDetail,
    ApplicantDashboard,
    ApplicantDashboardLayout,
    ApplicantPayment,
    UpdateProfile,
    ApplyOnline,
    Career,
    FrontendContact,
    ContentDetail,
    FrontendContent,
    DashboardLayout,
    FrontendEvent,
    GetReq,
    getReturnData,
    Home,
    ApplicantLogin,
    FrontendNews,
    FrontendNotice,
    FrontendPhoto,
    FrontendPhotoGallery,
    ResultView,
    RoutineDetails,
    RoutineList,
    StudentAcademicCalender,
    StudentAttendance,
    StudentClassRoutine,
    StudentDashboard,
    StudentDashboardLayout,
    StudentInvoice,
    LibraryBookList,
    StudentLogin,
    StudentNotification,
    StudentOnlineclass,
    StudentPasswordChange,
    StudentPay,
    StudentPayment,
    StudentPaymentSuccess,
    StudentProfile,
    Quiz,
    QuizArchive,
    QuizDetails,
    OnlineExamResultDetails,
    StudentReset,
    StudentResult,
    StudentCourseFile,
    StudentOnlineVideo,
    StudentList,
    Success,
    TeacherList,
    TeacherView,
    ViewCareer,
    ViewEvent,
    ViewNews,
    ViewNotice,
    NotFoundComponent
} from '../views/frontendView';


// ========== define route==========
const routes = [
    {
        path: '/', component: DashboardLayout,
        children: [
            { path: '/', name: 'home', component: Home },
            { path: '/content/:slug', name: 'content', component: FrontendContent },
            { path: '/content-detail/:slug', name: 'content_detail', component: ContentDetail },
            { path: '/contact', name: 'contact', component: FrontendContact },
            { path: '/careers', name: 'carrers', component: Career },
            { path: '/career/:id', name: 'carrer.detail', component: ViewCareer },
            { path: '/applyOnline/:id', name: 'carrer.applyOnline', component: ApplyOnline },
            { path: '/news', name: 'news', component: FrontendNews },
            { path: '/news/:slug', name: 'news.detail', component: ViewNews },
            { path: '/notice/:category', name: 'notice', component: FrontendNotice },
            { path: '/notice/:category/:slug', name: 'notice.detail', component: ViewNotice },
            { path: '/events', name: 'events', component: FrontendEvent },
            { path: '/event/:slug', name: 'event.detail', component: ViewEvent },
            { path: '/photogallery', name: 'photogallery', component: FrontendPhotoGallery },
            { path: '/photo/:photo_gallery_id', name: 'photo', component: FrontendPhoto },
            { path: '/teacherlist', name: 'teacherList', component: TeacherList },
            { path: '/studentList', name: 'studentList', component: StudentList },
            { path: '/resultView', name: 'resultView', component: ResultView },
            { path: '/routineList', name: 'routineList', component: RoutineList },
            { path: '/routineDetails', name: 'routineDetails', component: RoutineDetails },
            { path: '/teacher/:id', name: 'teacher.detail', component: TeacherView },
            { path: '/applicant/login', name: 'applicant.login', component: ApplicantLogin },
            { path: '/all-login', name: 'home.login', component: AllLogin },
            { path: '/invoiceDetails/:voucher_no', name: 'student.invoiceDetailsApp', component: StudentInvoice },
            {
                path: '/applicant/dashboard', component: ApplicantDashboardLayout,
                children: [
                    { path: '/applicant/dashboard', name: 'applicant.dashboard', component: ApplicantDashboard },
                    { path: '/applicant/profile', name: 'applicant.profile', component: ApplicantDashboard },
                    { path: '/applicant/payment', name: 'applicant.payment', component: ApplicantPayment },
                    { path: '/applicant/update-profile', name: 'applicant.update-profile', component: UpdateProfile },
                    { path: '/applicant/admit', name: 'applicant.admit', component: ApplicantAdmit },
                    { path: '/applicant/online-exam', name: 'applicant.onlineExam', component: ApplicantOnlineExam },
                    { path: '/applicant/quiz-detail/:examId/:applicant_id', name: 'applicant.quiz_detail', component: ApplicantOnlineExamDetail },
                ]
            },
            { path: '/admission', name: 'admission', component: Admission },
            { path: '/admission/:id', name: 'admissionview', component: AdmissionView },
            { path: '/cescas/applicant/postReq', name: 'getReq', component: GetReq },
            { path: '/getReturnData', name: 'getReturnData', component: getReturnData },
            { path: '/student/login', name: 'student.login', component: StudentLogin },
            { path: '/success_message/:trans_id', name: 'success_message', component: Success },
            { path: '/student/reset-password/:studentid/:reset_token', name: 'student.setpassword', component: StudentReset },
            { path: '/student/payment_success', name: 'student.payment_success', component: StudentPaymentSuccess },
            {
                path: '/student/dashboard', component: StudentDashboardLayout,
                children: [
                    { path: '/student/dashboard', name: 'student.dashboard', component: StudentDashboard },
                    { path: '/student/payment', name: 'student.payment', component: StudentPayment },
                    { path: '/student/profile', name: 'student.profile', component: StudentProfile },
                    { path: '/student/pay', name: 'student.pay', component: StudentPay },
                    { path: '/student/invoiceDetails/:voucher_no', name: 'student.invoiceDetails', component: StudentInvoice },
                    { path: '/student/class-routine/:classID/:sectionID', name: 'student.studentRoutine', component: StudentClassRoutine },
                    { path: '/student/course_file/:classID/:sectionID', name: 'student.course_file', component: StudentCourseFile },
                    { path: '/student/academic-calendar', name: 'student.AcademicCalendar', component: StudentAcademicCalender },
                    { path: '/student/attendance', name: 'student.Attendance', component: StudentAttendance },
                    { path: '/student/password-change', name: 'student.PasswordChange', component: StudentPasswordChange },
                    { path: '/student/notification', name: 'student.Notification', component: StudentNotification },
                    { path: '/student/online-class', name: 'student.onlineClass', component: StudentOnlineclass },
                    { path: '/student/online-class/:subject_id', name: 'student.viewVideo', component: StudentOnlineVideo },
                    { path: '/student/quiz', name: 'student.quiz', component: Quiz },
                    { path: '/student/quiz-archive', name: 'student.quiz_archive', component: QuizArchive },
                    { path: '/student/quiz-detail/:examId/:student_id', name: 'student.quiz_detail', component: QuizDetails },
                    { path: '/student/quiz-result-details/:examId', name: 'student.online_exam_result_detail', component: OnlineExamResultDetails },


                    { path: '/student/result', name: 'student.result', component: StudentResult },

                    // library module ---- book list
                    { path: '/student/library/books', name: 'student.libraryBookList', component: LibraryBookList },

                ]
            }
        ]
    },

    {
        path: '/login',
        name: 'login',
        component: Loader,
    },

    {
        path: '*',
        redirect: '/',
        component: NotFoundComponent
    }

];

// ========== define router==========
const router = new VueRouter({
    routes,
    hashbang: false,
    mode: "history",
    base: process.env.MIX_VUE_ROUTER_BASE,
    linkExactActiveClass: "active",
    linkActiveClass: "cesc",
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next = null) => {
    if (next) {
        app.loading = true;
        app.isMobile = false;
        next()
    }

});

router.afterEach((to, from, next = null) => {
    if (next) {
        window.scrollTo(0, 0);
        next()
    }
});

export default router;
