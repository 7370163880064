<template>
  <div id="payment">
    <div class="row align-items-center mb-3">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto;">Online Class</h5>
     
    </div>
    <div class="row" v-if="data.length > 0">
          <div class="col-md-3" v-for="(item, index) in data" :key="index">
              <div class="text-center box-body" >
                <router-link
                  :to="{ name:'student.viewVideo', params: {subject_id: item.id }}"
                  :title="item.name"
                  v-b-tooltip.hover.topleft
                >
                  <h5 class="text-uppercase m-0">{{item.name}}</h5>
                </router-link>
                  
              </div>
          </div>
    </div>
    <div v-else class="card card-body bg-ligh text-center">No Online Class Found</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      count: 0,
      errors: []
    };
  },
  mounted () {
      this.$root.loading = true;
      axios
        .get("student/online-class")
        .then(response => {
          this.data = response.data;
        })
        .catch(errer => {})
        .then(always => {
          this.$root.loading = false;
        });
  },
};
</script>
<style>
  a{
    color:blue;
  }
  .box-body {
  background: #0424520f;
  width: 100%;
  min-height: 100px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 14px 1px #716b6b7d;
}
.box-body h5{
  padding-top:20px;
  font-size: 16px;
}
</style>
