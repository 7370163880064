<template>
	<div id="payment">
		<div class="row align-items-center mb-5">
			<h5 class="font-weight-bold text-uppercase" style="margin: auto;">Library All Book List</h5>
		</div>

		<div class="row" v-if="!$root.loading">
			<slot v-if="result && Object.keys(result).length > 0">
				<table class="table tablesorter border table-hover" border="1" id="table">
					<thead class="bg-light text-secondary">
						<tr>
							<th class="align-text-top text-nowrap">SL</th>
							<th class="align-text-top text-nowrap text-center" style="width:13%;">Image</th>
							<th class="align-text-top text-nowrap">Book Name</th>
							<th class="align-text-top text-nowrap">Author Name</th>
							<th style="width:13%;" class="align-text-top text-nowrap text-center">Download</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in result" :key="index">
							<td>{{ index + 1 }}</td>
							<td class="text-center">
								<span v-if="item.image">
									<img :src="item.image" alt width="100" height="100" />
								</span>
							</td>
							<td>
								<span v-if="item.title">{{ item.title }}</span>
							</td>
							<td>
								<span v-if="item.author">{{ item.author }}</span>
							</td>
							<td class="text-center">
								<span v-if="item.book">
									<a
										target="_blank"
										class="btn btn-sm btn-success"
										:href="item.book"
										:download="item.book"
									>download</a>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</slot>

			<slot v-else>
				<div class="col-12 text-center text-danger mt-5">No book available!</div>
			</slot>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				result: []
			};
		},
		created() {
			axios
				.get("student/library-book-list")
				.then(res => {
					this.result = res.data;
				}).then(() => this.$root.loading = false);
		}
	};
</script>
