<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <slot v-if="event.image">
            <div class="col-xs-12 col-lg-5">
              <img :src="event.FullUrl+event.image" class="img-fluid" alt="..." />
            </div>
            <div class="col-xs-12 col-lg-7" data-aos="fade-right">
              <!--Heading-->
              <h5 class="font-weight-bold text-uppercase">{{event.title}}</h5>
              <!--Text-->
              <p class="text-justify" v-html="event.description"></p>
            </div>
          </slot>
          <slot v-else>
            <div class="col-xs-12 col-lg-12 row" data-aos="fade-right" v-if="event.title">
              <h5 class="font-weight-bold text-uppercase">{{event.title}}</h5>
              <div class="col-lg-12"></div>
              <p class="col-lg-3 pl-0">
                Start Time:
                <b>{{event.start_time|formatTime("hh:mm A")}}</b>
              </p>
              <p class="col-lg-3 pl-0">
                End Time:
                <b>{{event.end_time|formatTime("hh:mm A")}}</b>
              </p>
              <p class="text-justify" v-html="event.description"></p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Event"
  }
];
export default {
  name: "About",
  components: {
    Banner
  },
  data() {
    return {
      event: {},
      breadcrumbTitle: "",
      breadcumb: breadcumb
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/event_details/" + this.$route.params.slug)
        .then(respons => {
          this.$root.loading = false;
          this.event = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = [
            { title: "Event", route_name: "event" },
            { title: this.breadcrumbTitle }
          ];
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
