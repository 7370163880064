<template>
	<div class="home">
		<!--slider-->
		<Slider />
		<!--//slider-->
		<!--Newsticker-->
		<Newsticker />
		<!--//Newsticker-->
		<!--Services-->
		<!-- <Services /> -->
		<!--//Services-->
		<!--Welcome-->
		<Welcome />
		<!--//Welcome-->
		<!--Video Gallery, Notice, Event-->
		<div class="notice events py-5">
			<div class="container">
				<div class="row">
					<div class="col-md-4" data-aos="fade-up">
						<VideoGallery />
					</div>
					<div class="col-md-4" data-aos="fade-up" data-aos-delay="100">
						<Notice />
					</div>
					<div class="col-md-4 mt-3" data-aos="fade-up" data-aos-delay="200">
						<Events />
					</div>
				</div>
			</div>
		</div>
		<!--//Video Gallery, Notice, Event-->
		<!--News-->
		<News />
		<!--//News-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import Slider from "./home/Slider.vue";
	import Newsticker from "./home/Newsticker.vue";
	//import Services from "./home/Services.vue";
	import Welcome from "./home/Welcome.vue";
	import VideoGallery from "./home/VideoGallery.vue";
	import Notice from "./home/Notice.vue";
	import Events from "./home/Events.vue";
	import News from "./home/News.vue";

	export default {
		name: "home",
		head: {
			title: "Mynul Islam",
		},
		components: {
			Slider,
			Newsticker,
			//Services,
			Welcome,
			VideoGallery,
			Notice,
			Events,
			News,
		},
		mounted() {
			this.$root.loading = false;
		},
		created() {
			this.$root.loading = true;
		},
		beforeDestroy() {
			window.scrollTo(0, 0);
		},
	};
</script>
