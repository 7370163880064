<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <h4 class="m-0 font-weight-bold">All Photo Gallery</h4>
            <hr />
          </div>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-lg-3 text-center" v-for="(item, index) in table.datas" :key="index">
                <router-link :to="{ name:'photo', params: {photo_gallery_id: item.id }}">
                  <div class="card-gallery">
                    <img :src="item.FullUrl+item.image" class="img-fluid" alt="..." />
                  </div>
                </router-link>
                {{item.title.length > 50 ? item.title.substring(0, 50) + '...' : item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Photo Gallery"
  }
];
const routesname = {
  view: "photo_gallery"
};
export default {
  name: "photo_gallery",
  components: {
    Banner
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Photo Gallery",
      fields_name: { 0: "Select One", description: "Description" },
      search_data: {
        field_name: 0,
        value: ""
      },
      table: {
        title: "Simple Table",
        routes: routesname,
        datas: []
      },
      pagination: [],
      meta: [],
      links: []
    };
  },
  methods: {
    get_datas: function(pageindex) {
      this.$root.loading = true;
      let url = "/home/photo_galleries";
      if (pageindex > 0) {
        url = "/home/photo_galleries?page=" + pageindex;
      }
      axios
        .get(url)
        .then(respons => {
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.$parent.debug_data = respons;
          this.$root.loading = false;
        })
        .catch(error => {
          alert(error);
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_datas();
  }
};
</script>
