<template>
    <div class="about" v-if="!$root.loading">
        <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
        <div class="welcome-text py-4">
            <div class="container">
                <div v-if="adminssion_info">
                    <router-link
                        v-if="$root.applicant.login == true"
                        :to="{ name: 'applicant.dashboard' }"
                        class="btn btn-success ml-3"
                    >
                        Profile
                    </router-link>
                    <router-link
                        v-else
                        class="btn btn-success ml-3"
                        to="/applicant/login"
                        >Login</router-link
                    >
                    <div class="row align-items-center">
                        <h5
                            class="font-weight-bold text-uppercase"
                            style="margin: auto"
                        >
                            Admission for {{ adminssion_info.session }}
                        </h5>
                    </div>
                    <div class>
                        <div
                            class="alert alert-success"
                            v-if="savingSuccessful"
                        >
                            <a
                                href="#"
                                class="close"
                                data-dismiss="alert"
                                aria-label="close"
                                title="close"
                                >×</a
                            >
                            {{ text }}
                        </div>
                        <form
                            v-on:submit.prevent="submit"
                            enctype="multipart/form-data"
                            id="form"
                            class="form-row px-3"
                        >
                            <input type="hidden" v-model="data.id" />
                            <input
                                type="hidden"
                                name="adminssion_info_id"
                                v-model="data.adminssion_info_id"
                            />
                            <div class="clearfix m-2"></div>
                            <div class="Applicant col-md-12">
                                <fieldset class="border rounded p-2 w-100">
                                    <legend
                                        class="px-2 w-50 border rounded shadow-sm"
                                    >
                                        Applicant Information
                                    </legend>
                                    <div class="row col-12">
                                        <div class="row col-12">
                                            <div
                                                class="form-group col-2 w-100"
                                                :class="{
                                                    'has-error': validation.hasError(
                                                        'image'
                                                    ),
                                                    'has-success': image
                                                }"
                                            >
                                                <label for="Image">
                                                    <img
                                                        class="w-100"
                                                        :src="image_src"
                                                        alt="No Image"
                                                    />
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        >Image(180pxX220px)</label
                                                    >
                                                    <b-form-file
                                                        id="Image"
                                                        v-model="image"
                                                        :state="Boolean(file)"
                                                        size="sm"
                                                        placeholder="Choose a file or drop it here..."
                                                        drop-placeholder="Drop file here..."
                                                        style="display: none;"
                                                    ></b-form-file>
                                                </label>
                                                <span class="help-block">{{
                                                    validation.firstError(
                                                        "image"
                                                    )
                                                }}</span>
                                                <small
                                                    v-if="
                                                        errors && errors.image
                                                    "
                                                    class="text-danger"
                                                    >{{
                                                        errors.image[0]
                                                    }}</small
                                                >
                                            </div>
                                            <div class="row col-10">
                                                <div
                                                    class="form-group col-4 required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.category'
                                                        ),
                                                        'has-success':
                                                            data.category
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="category"
                                                        >Category</label
                                                    >
                                                    <select
                                                        id="category"
                                                        class="form-control form-control-sm"
                                                        name="category"
                                                        v-model="data.category"
                                                    >
                                                        <option disabled value
                                                            >Please select
                                                            one</option
                                                        >
                                                        <option
                                                            v-for="(value,
                                                            id,
                                                            index) in $root
                                                                .variable
                                                                .applicant_category"
                                                            :key="index"
                                                            :value="id"
                                                        >
                                                            {{ value }}
                                                        </option>
                                                    </select>
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.category
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors.category[0]
                                                        }}</small
                                                    >
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.category"
                                                        )
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="form-group col-4 required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.std_class_id'
                                                        ),
                                                        'has-success':
                                                            data.std_class_id
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="std_class_id"
                                                        >Class</label
                                                    >
                                                    <select
                                                        class="form-control form-control-sm"
                                                        id="std_class_id"
                                                        name="std_class_id"
                                                        v-model="
                                                            data.std_class_id
                                                        "
                                                    >
                                                        <option disabled value
                                                            >Please select
                                                            one</option
                                                        >
                                                        <option
                                                            v-for="value in adminssion_info.class_has_admission"
                                                            :key="
                                                                value.std_class_id
                                                            "
                                                            :value="
                                                                value.std_class_id
                                                            "
                                                        >
                                                            {{
                                                                value.class_name
                                                            }}
                                                        </option>
                                                    </select>
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.std_class_id"
                                                        )
                                                    }}</span>
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.std_class_id
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors
                                                                .std_class_id[0]
                                                        }}</small
                                                    >
                                                </div>
                                                <div
                                                    class="form-group col-4 required"
                                                    v-if="has_group"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.group'
                                                        ),
                                                        'has-success':
                                                            data.group
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="group"
                                                        >Group</label
                                                    >
                                                    <select
                                                        id="group"
                                                        class="form-control form-control-sm"
                                                        name="group"
                                                        v-model="data.group"
                                                    >
                                                        <option value="null"
                                                            >Please select
                                                            one</option
                                                        >
                                                        <option
                                                            v-for="(value,
                                                            id,
                                                            index) in $root
                                                                .variable
                                                                .study_group"
                                                            :key="index"
                                                            :value="id"
                                                        >
                                                            {{ value }}
                                                        </option>
                                                    </select>

                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.group
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors.group[0]
                                                        }}</small
                                                    >

                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.group"
                                                        )
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="form-group col-4 required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.bangla_name'
                                                        ),
                                                        'has-success':
                                                            data.bangla_name
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="bangla_name"
                                                        >Full Name (In
                                                        Bengali)</label
                                                    >
                                                    <input
                                                        id="bangla_name"
                                                        name="bangla_name"
                                                        v-model="
                                                            data.bangla_name
                                                        "
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        placeholder="Full Name (In Bengali)"
                                                    />
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.bangla_name
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors
                                                                .bangla_name[0]
                                                        }}</small
                                                    >
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.bangla_name"
                                                        )
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="form-group col-4 required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.name'
                                                        ),
                                                        'has-success': data.name
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="name"
                                                        >In English(Block
                                                        Letter)</label
                                                    >
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        v-model="data.name"
                                                        type="name"
                                                        class="form-control form-control-sm text-uppercase"
                                                        placeholder="In English(Block Letter)"
                                                    />
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.name"
                                                        )
                                                    }}</span>
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.name
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors.name[0]
                                                        }}</small
                                                    >
                                                </div>
                                                <div
                                                    class="form-group col-4 required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.date_of_birth'
                                                        ),
                                                        'has-success':
                                                            data.date_of_birth
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="date_of_birth"
                                                        >Date of Birth</label
                                                    >
                                                    <input
                                                        id="date_of_birth"
                                                        name="date_of_birth"
                                                        v-model="
                                                            data.date_of_birth
                                                        "
                                                        type="date"
                                                        class="form-control form-control-sm"
                                                        placeholder="Date of Birth"
                                                        :max="max"
                                                    />
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.date_of_birth
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors
                                                                .date_of_birth[0]
                                                        }}</small
                                                    >
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.date_of_birth"
                                                        )
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="form-group col-4 align-self-end required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.gender'
                                                        ),
                                                        'has-success':
                                                            data.gender
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0 pr-3"
                                                        >Gender</label
                                                    >
                                                    <br />
                                                    <input
                                                        chacked
                                                        type="radio"
                                                        id="male"
                                                        value="Male"
                                                        name="gender"
                                                        v-model="data.gender"
                                                    />
                                                    <label
                                                        class="col-form-label-sm font-weight-normal mb-0"
                                                        for="male"
                                                        >Male</label
                                                    >
                                                    <input
                                                        type="radio"
                                                        id="female"
                                                        value="Female"
                                                        name="gender"
                                                        v-model="data.gender"
                                                    />
                                                    <label
                                                        class="col-form-label-sm font-weight-normal mb-0"
                                                        for="female"
                                                        >Female</label
                                                    >
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.gender
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors.gender[0]
                                                        }}</small
                                                    >
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.gender"
                                                        )
                                                    }}</span>
                                                </div>

                                                <div
                                                    v-if="jsc_reg || ssc_reg"
                                                    class="form-group col-3 required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.jsc_reg'
                                                        ),
                                                        'has-success':
                                                            data.jsc_reg
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="jsc_reg"
                                                        >JSC Registration
                                                        No</label
                                                    >
                                                    <input
                                                        id="jsc_reg"
                                                        name="jsc_reg"
                                                        v-model="data.jsc_reg"
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        placeholder="JSC Registration No"
                                                    />
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.jsc_reg
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors.jsc_reg[0]
                                                        }}</small
                                                    >
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.jsc_reg"
                                                        )
                                                    }}</span>
                                                </div>

                                                <div
                                                    v-if="ssc_reg"
                                                    class="form-group col-3 required"
                                                    :class="{
                                                        'has-error': validation.hasError(
                                                            'data.ssc_reg'
                                                        ),
                                                        'has-success':
                                                            data.ssc_reg
                                                    }"
                                                >
                                                    <label
                                                        class="col-form-label-sm font-weight-bold mb-0"
                                                        for="ssc_reg"
                                                        >SSC Registration
                                                        No</label
                                                    >
                                                    <input
                                                        id="ssc_reg"
                                                        name="ssc_reg"
                                                        v-model="data.ssc_reg"
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        placeholder="SSC Registration No"
                                                    />
                                                    <small
                                                        v-if="
                                                            errors &&
                                                                errors.ssc_reg
                                                        "
                                                        class="text-danger"
                                                        >{{
                                                            errors.ssc_reg[0]
                                                        }}</small
                                                    >
                                                    <span class="help-block">{{
                                                        validation.firstError(
                                                            "data.ssc_reg"
                                                        )
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.nationality'
                                                ),
                                                'has-success': data.nationality
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="nationality"
                                                >Nationality</label
                                            >
                                            <input
                                                id="nationality"
                                                name="nationality"
                                                v-model="data.nationality"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Nationality"
                                            />
                                            <small
                                                v-if="
                                                    errors && errors.nationality
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.nationality[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.nationality"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.religion'
                                                ),
                                                'has-success': data.religion
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="religion"
                                                >Religion</label
                                            >
                                            <select
                                                id="religion"
                                                class="form-control form-control-sm"
                                                name="religion"
                                                v-model="data.religion"
                                            >
                                                <option value="null"
                                                    >Please select one</option
                                                >
                                                <option
                                                    v-for="(value,
                                                    index) in $root.variable
                                                        .religion"
                                                    :key="index"
                                                    :value="value"
                                                >
                                                    {{ value }}
                                                </option>
                                            </select>
                                            <small
                                                v-if="errors && errors.religion"
                                                class="text-danger"
                                                >{{ errors.religion[0] }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.religion"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.blood_group'
                                                ),
                                                'has-success': data.blood_group
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guard"
                                                >Blood Group</label
                                            >
                                            <select
                                                id="guard"
                                                class="form-control form-control-sm"
                                                name="blood_group"
                                                v-model="data.blood_group"
                                            >
                                                <option disabled value
                                                    >Please select one</option
                                                >
                                                <option
                                                    v-for="(value,
                                                    id,
                                                    index) in $root.variable
                                                        .bloodgroup"
                                                    :key="index"
                                                    :value="id"
                                                >
                                                    {{ value }}
                                                </option>
                                            </select>
                                            <small
                                                v-if="
                                                    errors && errors.blood_group
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.blood_group[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.blood_group"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.email'
                                                ),
                                                'has-success': data.email
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="email"
                                                >Email</label
                                            >
                                            <input
                                                id="email"
                                                name="email"
                                                v-model="data.email"
                                                type="email"
                                                class="form-control form-control-sm"
                                                placeholder="Email"
                                            />
                                            <small
                                                v-if="errors && errors.email"
                                                class="text-danger"
                                                >{{ errors.email[0] }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.email"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mobile'
                                                ),
                                                'has-success': data.mobile
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mobile"
                                                >Mobile</label
                                            >
                                            <input
                                                id="mobile"
                                                name="mobile"
                                                v-model="data.mobile"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Mobile"
                                            />
                                            <small
                                                v-if="errors && errors.mobile"
                                                class="text-danger"
                                                >{{ errors.mobile[0] }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mobile"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-9"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.previous_school'
                                                ),
                                                'has-success':
                                                    data.previous_school
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="previous_school"
                                                >Name of previous school</label
                                            >
                                            <input
                                                id="previous_school"
                                                name="previous_school"
                                                v-model="data.previous_school"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Name of previous school"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.previous_school
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.previous_school[0]
                                                }}</small
                                            >
                                        </div>
                                        <div
                                            class="form-group col-6"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.medium_previous_school'
                                                ),
                                                'has-success':
                                                    data.medium_previous_school
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="medium_previous_school"
                                                >Medium of Instruction of Last
                                                School</label
                                            >
                                            <input
                                                id="medium_previous_school"
                                                name="medium_previous_school"
                                                v-model="
                                                    data.medium_previous_school
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Medium of Instruction of Last School:"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.medium_previous_school
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors
                                                        .medium_previous_school[0]
                                                }}</small
                                            >
                                        </div>
                                        <div
                                            class="form-group col-6"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.class_previous_school'
                                                ),
                                                'has-success':
                                                    data.class_previous_school
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="class_previous_school"
                                                >Class in which the student was
                                                last placed</label
                                            >
                                            <input
                                                id="class_previous_school"
                                                name="class_previous_school"
                                                v-model="
                                                    data.class_previous_school
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Class in which the student was last placed:"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.class_previous_school
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors
                                                        .class_previous_school[0]
                                                }}</small
                                            >
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="clearfix m-2"></div>
                            <div class="Login col-md-12">
                                <fieldset class="border rounded p-2 w-100">
                                    <legend
                                        class="px-2 w-50 border rounded shadow-sm"
                                    >
                                        Father Information
                                    </legend>
                                    <div class="row col-12">
                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.father_name'
                                                ),
                                                'has-success': data.father_name
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="father_name"
                                                >Name</label
                                            >
                                            <input
                                                id="father_name"
                                                name="father_name"
                                                v-model="data.father_name"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Name"
                                            />
                                            <small
                                                v-if="
                                                    errors && errors.father_name
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.father_name[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.father_name"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.father_occupation'
                                                ),
                                                'has-success':
                                                    data.father_occupation
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="father_occupation"
                                                >Occupation</label
                                            >
                                            <input
                                                id="father_occupation"
                                                name="father_occupation"
                                                v-model="data.father_occupation"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Occupation"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.father_occupation
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.father_occupation[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.father_occupation"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.father_organization'
                                                ),
                                                'has-success':
                                                    data.father_organization
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="father_organization"
                                                >Organization/Unit</label
                                            >
                                            <input
                                                id="father_organization"
                                                name="father_organization"
                                                v-model="
                                                    data.father_organization
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Organization/Unit"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.father_organization
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors
                                                        .father_organization[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.father_organization"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.father_designation'
                                                ),
                                                'has-success':
                                                    data.father_designation
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="father_designation"
                                                >Designation</label
                                            >
                                            <input
                                                id="father_designation"
                                                name="father_designation"
                                                v-model="
                                                    data.father_designation
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Designation"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.father_designation
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.father_designation[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.father_designation"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.father_location'
                                                ),
                                                'has-success':
                                                    data.father_location
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="father_location"
                                                >Location</label
                                            >
                                            <input
                                                id="father_location"
                                                name="father_location"
                                                v-model="data.father_location"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Location"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.father_location
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.father_location[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.father_location"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.father_mobile_no'
                                                ),
                                                'has-success':
                                                    data.father_mobile_no
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="father_mobile_no"
                                                >Contact No.</label
                                            >
                                            <input
                                                id="father_mobile_no"
                                                name="father_mobile_no"
                                                v-model="data.father_mobile_no"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Contact No."
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.father_mobile_no
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.father_mobile_no[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.father_mobile_no"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.father_email'
                                                ),
                                                'has-success': data.father_email
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="father_email"
                                                >Email</label
                                            >
                                            <input
                                                id="father_email"
                                                name="father_email"
                                                v-model="data.father_email"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Email"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.father_email
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.father_email[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.father_email"
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="clearfix m-2"></div>
                            <div class="Login col-md-12">
                                <fieldset class="border rounded p-2 w-100">
                                    <legend
                                        class="px-2 w-50 border rounded shadow-sm"
                                    >
                                        Mother Information
                                    </legend>
                                    <div class="row col-12">
                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mother_name'
                                                ),
                                                'has-success': data.mother_name
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mother_name"
                                                >Name</label
                                            >
                                            <input
                                                id="mother_name"
                                                name="mother_name"
                                                v-model="data.mother_name"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Name"
                                            />
                                            <small
                                                v-if="
                                                    errors && errors.mother_name
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.mother_name[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mother_name"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mother_occupation'
                                                ),
                                                'has-success':
                                                    data.mother_occupation
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mother_occupation"
                                                >Occupation</label
                                            >
                                            <input
                                                id="mother_occupation"
                                                name="mother_occupation"
                                                v-model="data.mother_occupation"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Occupation"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.mother_occupation
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.mother_occupation[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mother_occupation"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mother_organization'
                                                ),
                                                'has-success':
                                                    data.mother_organization
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mother_organization"
                                                >Organization/Unit</label
                                            >
                                            <input
                                                id="mother_organization"
                                                name="mother_organization"
                                                v-model="
                                                    data.mother_organization
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Organization/Unit"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.mother_organization
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors
                                                        .mother_organization[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mother_organization"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mother_designation'
                                                ),
                                                'has-success':
                                                    data.mother_designation
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mother_designation"
                                                >Designation</label
                                            >
                                            <input
                                                id="mother_designation"
                                                name="mother_designation"
                                                v-model="
                                                    data.mother_designation
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Designation"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.mother_designation
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.mother_designation[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mother_designation"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mother_location'
                                                ),
                                                'has-success':
                                                    data.mother_location
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mother_location"
                                                >Location</label
                                            >
                                            <input
                                                id="mother_location"
                                                name="mother_location"
                                                v-model="data.mother_location"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Location"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.mother_location
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.mother_location[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mother_location"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mother_mobile_no'
                                                ),
                                                'has-success':
                                                    data.mother_mobile_no
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mother_mobile_no"
                                                >Contact No.</label
                                            >
                                            <input
                                                id="mother_mobile_no"
                                                name="mother_mobile_no"
                                                v-model="data.mother_mobile_no"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Contact No."
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.mother_mobile_no
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.mother_mobile_no[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mother_mobile_no"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.mother_email'
                                                ),
                                                'has-success': data.mother_email
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="mother_email"
                                                >Email</label
                                            >
                                            <input
                                                id="mother_email"
                                                name="mother_email"
                                                v-model="data.mother_email"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Email"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.mother_email
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.mother_email[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.mother_email"
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="clearfix m-2"></div>
                            <div class="Login col-md-12">
                                <fieldset class="border rounded p-2 w-100">
                                    <legend
                                        class="px-2 w-25 border rounded shadow-sm"
                                    >
                                        Address
                                    </legend>
                                    <div class="row col-12">
                                        <div
                                            class="form-group col-6 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.present_address'
                                                ),
                                                'has-success':
                                                    data.present_address
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="present_address"
                                                >Present Address</label
                                            >
                                            <textarea
                                                id="present_address"
                                                name="present_address"
                                                v-model="data.present_address"
                                                class="form-control form-control-sm"
                                                placeholder="Present Address"
                                            ></textarea>
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.present_address
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.present_address[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.present_address"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.present_phone'
                                                ),
                                                'has-success':
                                                    data.present_phone
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="present_phone"
                                                >Present Telephone Number
                                                (Res)</label
                                            >
                                            <input
                                                id="present_phone"
                                                name="present_phone"
                                                v-model="data.present_phone"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Present Telephone Number (Res)"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.present_phone
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.present_phone[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.present_phone"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.present_mobile'
                                                ),
                                                'has-success':
                                                    data.present_mobile
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="present_mobile"
                                                >Present Mobile</label
                                            >
                                            <input
                                                id="present_mobile"
                                                name="present_mobile"
                                                v-model="data.present_mobile"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Present Mobile"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.present_mobile
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.present_mobile[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.present_mobile"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-6 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.permanent_address'
                                                ),
                                                'has-success':
                                                    data.permanent_address
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="permanent_address"
                                                >Permanent Address</label
                                            >
                                            <textarea
                                                id="permanent_address"
                                                name="permanent_address"
                                                v-model="data.permanent_address"
                                                class="form-control form-control-sm"
                                                placeholder="Permanent Address"
                                            ></textarea>
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.permanent_address
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.permanent_address[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.permanent_address"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.permanent_phone'
                                                ),
                                                'has-success':
                                                    data.permanent_phone
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="permanent_phone"
                                                >Permanent Telephone Number
                                                (Res)</label
                                            >
                                            <input
                                                id="permanent_phone"
                                                name="permanent_phone"
                                                v-model="data.permanent_phone"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Permanent Telephone Number (Res)"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.permanent_phone
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.permanent_phone[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.permanent_phone"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.permanent_mobile'
                                                ),
                                                'has-success':
                                                    data.permanent_mobile
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="permanent_mobile"
                                                >Permanent Mobile</label
                                            >
                                            <input
                                                id="permanent_mobile"
                                                name="permanent_mobile"
                                                v-model="data.permanent_mobile"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Permanent Mobile"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.permanent_mobile
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.permanent_mobile[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.permanent_mobile"
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="clearfix m-2"></div>
                            <div class="Login col-md-12">
                                <fieldset class="border rounded p-2 w-100">
                                    <legend
                                        class="px-2 w-50 border rounded shadow-sm"
                                    >
                                        Guardian Information
                                    </legend>
                                    <div class="row col-12">
                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.guardian_name'
                                                ),
                                                'has-success':
                                                    data.guardian_name
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guardian_name"
                                                >Name</label
                                            >
                                            <input
                                                id="guardian_name"
                                                name="guardian_name"
                                                v-model="data.guardian_name"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Name"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.guardian_name
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.guardian_name[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.guardian_name"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.relation'
                                                ),
                                                'has-success':
                                                    data.guardian_name
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="relation"
                                                >Relation</label
                                            >
                                            <input
                                                id="relation"
                                                name="relation"
                                                v-model="data.relation"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Relation"
                                            />
                                            <small
                                                v-if="errors && errors.relation"
                                                class="text-danger"
                                                >{{ errors.relation[0] }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.relation"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.guardian_occupation'
                                                ),
                                                'has-success':
                                                    data.guardian_occupation
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guardian_occupation"
                                                >Occupation</label
                                            >
                                            <input
                                                id="guardian_occupation"
                                                name="guardian_occupation"
                                                v-model="
                                                    data.guardian_occupation
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Occupation"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.guardian_occupation
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors
                                                        .guardian_occupation[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.guardian_occupation"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.guardian_organization'
                                                ),
                                                'has-success':
                                                    data.guardian_organization
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guardian_organization"
                                                >Organization/Unit</label
                                            >
                                            <input
                                                id="guardian_organization"
                                                name="guardian_organization"
                                                v-model="
                                                    data.guardian_organization
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Organization/Unit"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.guardian_organization
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors
                                                        .guardian_organization[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.guardian_organization"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.guardian_designation'
                                                ),
                                                'has-success':
                                                    data.guardian_designation
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guardian_designation"
                                                >Designation</label
                                            >
                                            <input
                                                id="guardian_designation"
                                                name="guardian_designation"
                                                v-model="
                                                    data.guardian_designation
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Designation"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.guardian_designation
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors
                                                        .guardian_designation[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.guardian_designation"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.guardian_location'
                                                ),
                                                'has-success':
                                                    data.guardian_location
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guardian_location"
                                                >Location</label
                                            >
                                            <input
                                                id="guardian_location"
                                                name="guardian_location"
                                                v-model="data.guardian_location"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Location"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.guardian_location
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.guardian_location[0]
                                                }}</small
                                            >

                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.guardian_location"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3 required"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.guardian_mobile_no'
                                                ),
                                                'has-success':
                                                    data.guardian_mobile_no
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guardian_mobile_no"
                                                >Contact No.</label
                                            >
                                            <input
                                                id="guardian_mobile_no"
                                                name="guardian_mobile_no"
                                                v-model="
                                                    data.guardian_mobile_no
                                                "
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Contact No."
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.guardian_mobile_no
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.guardian_mobile_no[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.guardian_mobile_no"
                                                )
                                            }}</span>
                                        </div>
                                        <div
                                            class="form-group col-3"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.guardian_email'
                                                ),
                                                'has-success':
                                                    data.guardian_email
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="guardian_email"
                                                >Email</label
                                            >
                                            <input
                                                id="guardian_email"
                                                name="guardian_email"
                                                v-model="data.guardian_email"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Email"
                                            />
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.guardian_email
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.guardian_email[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.guardian_email"
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="clearfix m-2"></div>
                            <div class="Login col-md-12">
                                <fieldset class="border rounded p-2 w-100">
                                    <legend
                                        class="px-2 w-50 border rounded shadow-sm"
                                    >
                                        Education Information
                                    </legend>
                                    <div class="row col-12">
                                        <div class="form-group col-2">
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                >Exam Name</label
                                            >
                                        </div>
                                        <div class="form-group col-2">
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                >Roll No</label
                                            >
                                        </div>
                                        <div class="form-group col-2">
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                >Year</label
                                            >
                                        </div>
                                        <div class="form-group col-2">
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                >Board</label
                                            >
                                        </div>
                                        <div class="form-group col-2">
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                >GPA</label
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="row col-12"
                                        v-for="(education,
                                        index) in data.educations"
                                        :key="index"
                                    >
                                        <div class="form-group col-2">
                                            <select
                                                name="exam"
                                                id
                                                class="form-control form-control-sm rounded-0"
                                                v-model="education.exam"
                                            >
                                                <option value="null"
                                                    >Please select one</option
                                                >
                                                <option
                                                    v-for="(value,
                                                    name,
                                                    index) in $root.variable
                                                        .board_exams"
                                                    :key="index"
                                                    v-bind:value="name"
                                                >
                                                    {{ value }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-2">
                                            <input
                                                name="roll_no"
                                                v-model="education.roll_no"
                                                type="number"
                                                class="form-control form-control-sm"
                                                placeholder="Roll No"
                                            />
                                        </div>
                                        <div class="form-group col-2">
                                            <input
                                                name="year"
                                                v-model="education.year"
                                                type="number"
                                                class="form-control form-control-sm"
                                                placeholder="Year"
                                            />
                                        </div>
                                        <div class="form-group col-2">
                                            <input
                                                v-model="education.board"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Board"
                                            />
                                        </div>
                                        <div class="form-group col-2">
                                            <input
                                                v-model="education.gpa"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="GPA"
                                            />
                                        </div>
                                        <div class="form-group col-1">
                                            <button
                                                v-if="index > 0"
                                                @click="
                                                    data.educations.splice(
                                                        index,
                                                        1
                                                    )
                                                "
                                                class="btn btn-sm btn-danger w-100"
                                                type="button"
                                            >
                                                <span
                                                    class="fas fa-times"
                                                ></span>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        @click="
                                            data.educations.push({ exam: null })
                                        "
                                        class="btn btn-sm btn-primary float-right"
                                        type="button"
                                    >
                                        Add More
                                    </button>
                                </fieldset>
                            </div>
                            <div class="clearfix m-2"></div>
                            <div class="Login col-md-12">
                                <fieldset class="border rounded p-2 w-100">
                                    <legend
                                        class="px-2 w-50 border rounded shadow-sm"
                                    >
                                        Some Important Data About the Candidate
                                    </legend>
                                    <div class="row col-12">
                                        <div
                                            class="form-group col-12"
                                            :class="{
                                                'has-error': validation.hasError(
                                                    'data.serious_ailment'
                                                ),
                                                'has-success':
                                                    data.serious_ailment
                                            }"
                                        >
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                >Did your son/daughter suffer
                                                from any serious ailment? Mark
                                                in the appropriate box
                                                &nbsp;&nbsp;&nbsp;<span
                                                    style="color: red"
                                                    >*</span
                                                ></label
                                            >
                                            <input
                                                chacked
                                                type="radio"
                                                id="yes"
                                                value="Yes"
                                                name="serious_ailment"
                                                v-model="data.serious_ailment"
                                            />
                                            <label
                                                class="col-form-label-sm font-weight-normal mb-0"
                                                for="yes"
                                                >Yes</label
                                            >
                                            <input
                                                type="radio"
                                                id="no"
                                                value="No"
                                                checked="checked"
                                                name="serious_ailment"
                                                v-model="data.serious_ailment"
                                            />
                                            <label
                                                class="col-form-label-sm font-weight-normal mb-0"
                                                for="no"
                                                >No</label
                                            >
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.serious_ailment
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.serious_ailment[0]
                                                }}</small
                                            >
                                            <span class="help-block">{{
                                                validation.firstError(
                                                    "data.serious_ailment"
                                                )
                                            }}</span>
                                        </div>
                                        <div class="form-group col-12">
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="nature_of_illness"
                                                >If yes, please state the nature
                                                of illness and duration of the
                                                ailment. (with dates)</label
                                            >
                                            <textarea
                                                id="nature_of_illness"
                                                v-model="data.nature_of_illness"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="If yes, please state the nature of illness and duration of the ailment. (with dates)"
                                                name="nature_of_illness"
                                            ></textarea>
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.nature_of_illness
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.nature_of_illness[0]
                                                }}</small
                                            >
                                        </div>

                                        <div class="form-group col-12">
                                            <label
                                                class="col-form-label-sm font-weight-bold mb-0"
                                                for="chronic_disease"
                                                >Is your child suffering from
                                                any chronic disease which
                                                disturbs his / her academic
                                                pursuit? If yes, write its
                                                nature</label
                                            >
                                            <textarea
                                                id="chronic_disease"
                                                v-model="data.chronic_disease"
                                                type="text"
                                                class="form-control form-control-sm"
                                                placeholder="Is your child suffering from any chronic disease which disturbs his / her academic pursuit?  If yes, write its nature"
                                                name="chronic_disease"
                                            ></textarea>
                                            <small
                                                v-if="
                                                    errors &&
                                                        errors.chronic_disease
                                                "
                                                class="text-danger"
                                                >{{
                                                    errors.chronic_disease[0]
                                                }}</small
                                            >
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="clearfix m-2"></div>
                            <div class="form-group col-12">
                                <button
                                    class="btn btn-sm btn-primary mt-3"
                                    process=""
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="panel-body" v-else>
                    <div
                        class="alert alert-danger col-4"
                        role="alert"
                        style="margin: auto"
                    >
                        <b>Admission procedure will start Soon</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const today = new Date().toISOString().substr(0, 10);
const breadcumb = [
    {
        title: "Admission"
    }
];
export default {
    name: "About",
    components: {
        Banner
    },
    data() {
        return {
            max: today,
            today: today,
            has_group: false,
            jsc_reg: false,
            ssc_reg: false,
            file: null,
            image: null,
            image_src: "/images/no-image.png",
            adminssion_info: "",
            extradata: {
                std_class_list: [],
                section_list: []
            },
            data: {
                category: "",
                adminssion_info_id: "",
                blood_group: "",
                student_type: "",
                std_class_id: "",
                group: null,
                educations: [{ exam: null }],
                gender: "Male",
                exam_fee: 0,
                applicant: {}
            },
            savingSuccessful: false,
            breadcrumbTitle: "",
            text: "",
            errors: {},
            focus_field: "",
            breadcumb: breadcumb
        };
    },
    watch: {
        image: function(image, oldImage) {
            this.showImage(image);
        },
        "data.std_class_id": async function(val, oldval) {
            if (val) {
                await this.classHasGroup(val);
                this.classType(this.has_group, val);
                this.examFee(val);
            }
            if (!this.has_group) {
                this.data.group = null;
            }
        }
    },
    methods: {
        submit() {
            const error = this.validation.countErrors();

            this.$validate().then(res => {
                // If there is an error
                if (error > 0) {
                    this.$toaster.error(
                        "You need to fill " +
                            error +
                            " more empty mandatory fields",
                        "warning"
                    );
                    return false;
                }

                // If there is no error
                if (res) {
                    var form = document.getElementById("form");
                    console.log(form);
                    var formData = new FormData(form);
                    if (this.image) {
                        formData.append("image", this.image);
                    } else {
                        formData.append("image", "");
                    }
                    var educations = JSON.stringify(this.data.educations);
                    formData.append("educations", educations);
                    var addmissioninfo = JSON.stringify(this.adminssion_info);

                    this.$root.loading = true;

                    axios
                        .post("admission_apply", formData)
                        .then(respons => {
                            this.$router.push({
                                name: "admissionview",
                                params: { id: respons.data.applicantid }
                            });
                            this.savingSuccessful = true;
                            this.focus_field = "";
                            e.target.reset();
                            this.text = "Thank You for Admission";
                            this.data = "";
                        })
                        .catch(error => {
                            this.loaded = true;
                            if (error.response.status === 422) {
                                this.$toaster.error(
                                    "Invalid data! Please try again."
                                );
                                this.errors = error.response.data.errors || {};
                                this.focus_field = Object.keys(this.errors)[0];
                            }
                        })
                        .then(() => (this.$root.loading = false));
                }
            });
        },
        get_data() {
            this.$root.loading = true;
            let url = "/home/adminssion_info";
            axios
                .get(url)
                .then(respons => {
                    this.adminssion_info = respons.data;
                    this.data.adminssion_info_id = this.adminssion_info.id;
                    this.$root.loading = false;
                })
                .catch(error => {});
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.file = e.target.files[0];
            }
        },
        onImageChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.file = e.target.files[0];
            }
        },
        classType(hasGroup, classId) {
            let classType = this.$root.variable.std_classes.filter(
                c => c.id == classId
            )[0].type;
            if (hasGroup && classType == "School") {
                this.jsc_reg = true;
                this.ssc_reg = false;
            } else if (hasGroup && classType == "College") {
                this.jsc_reg = true;
                this.ssc_reg = true;
            } else {
                this.jsc_reg = false;
                this.ssc_reg = false;
            }
        },
        examFee(classId) {
            //let this.exam_fee = 250;
            this.adminssion_info.class_has_admission.filter((value, index) => {
                let examfee = 0;
                if (value.std_class_id == classId) {
                    // alert("Exam Fee: "+value.exam_fee +  " BDT ");
                    this.data.exam_fee = value.exam_fee;
                }
            });
        }
    },
    mounted() {
        this.getStdClassList();
        this.get_data();
    },
    validators: {
        "data.name": function(value = null) {
            return Validator.value(value).required("Name is required");
        },
        image: function(value = null) {
            return Validator.value(value).required("Image is required");
        },
        "data.category": function(value = null) {
            return Validator.value(value).required("Category is required");
        },
        "data.std_class_id": function(value = null) {
            return Validator.value(value).required("Class is required");
        },
        "data.group": function(value = null) {
            if (this.has_group) {
                return Validator.value(value).required("Group is required");
            } else {
                return Validator.value(value).custom(function() {
                    return;
                });
            }
        },
        "data.jsc_reg": function(value = null) {
            if (this.data.std_class_id) {
                let classType = this.$root.variable.std_classes.filter(
                    c => c.id == this.data.std_class_id
                )[0].type;
                if (
                    this.has_group &&
                    (classType == "School" || classType == "College")
                ) {
                    return Validator.value(value)
                        .required("JSC Registration No is required")
                        .digit();
                } else {
                    return Validator.value(value).custom(function() {
                        return;
                    });
                }
            }
        },
        "data.ssc_reg": function(value = null) {
            if (this.data.std_class_id) {
                let classType = this.$root.variable.std_classes.filter(
                    c => c.id == this.data.std_class_id
                )[0].type;
                if (this.has_group && classType == "College") {
                    return Validator.value(value)
                        .required("SSC Registration No is required")
                        .digit();
                } else {
                    return Validator.value(value).custom(function() {
                        return;
                    });
                }
            }
        },
        "data.bangla_name": function(value = null) {
            return Validator.value(value).required("Bangla Name is required");
        },
        "data.date_of_birth": function(value = null) {
            return Validator.value(value)
                .required("Date of Birth is required")
                .custom(function() {
                    if (new Date() - new Date(value) < 0) {
                        return "Invalid Date of Birth!";
                    }
                });
        },
        "data.gender": function(value = null) {
            return Validator.value(value).required("Gender is required");
        },
        "data.nationality": function(value = null) {
            return Validator.value(value).required("Nationality is required");
        },
        "data.religion": function(value = null) {
            return Validator.value(value).required("Religion Name is required");
        },
        "data.father_name": function(value = null) {
            return Validator.value(value).required("Father name is required");
        },
        "data.mother_name": function(value = null) {
            return Validator.value(value).required("Mother Name is required");
        },
        "data.present_address": function(value = null) {
            return Validator.value(value).required(
                "Present Address is required"
            );
        },
        "data.permanent_address": function(value = null) {
            return Validator.value(value).required(
                "Permanent Address is required"
            );
        },
        "data.guardian_name": function(value = null) {
            return Validator.value(value).required("Guardian Name is required");
        },
        "data.relation": function(value = null) {
            return Validator.value(value).required("Relation is required");
        },
        "data.email": function(value = null) {
            return Validator.value(value).email();
        },
        "data.serious_ailment": function(value = null) {
            return Validator.value(value).required(
                "Did your son/daughter suffer from any serious ailment? Mark in the appropriate box is required"
            );
        },
        "data.mobile": function(value = null) {
            return Validator.value(value)
                .required("Mobile No. is required")
                .digit()
                .regex("01+[0-9+-]*$", "Must start with 01.")
                .minLength(11)
                .maxLength(11);
        },
        "data.present_mobile": function(value = null) {
            return Validator.value(value)
                .digit()
                .regex("01+[0-9+-]*$", "Must start with 01.")
                .minLength(11)
                .maxLength(11);
        },
        "data.present_phone": function(value = null) {
            return Validator.value(value)
                .digit()
                .maxLength(20);
        },
        "data.permanent_phone": function(value = null) {
            return Validator.value(value)
                .digit()
                .maxLength(20);
        },
        "data.guardian_mobile_no": function(value = null) {
            return Validator.value(value)
                .required("Contact No. is required")
                .digit()
                .regex("01+[0-9+-]*$", "Must start with 01.")
                .minLength(11)
                .maxLength(11);
        },
        "data.guardian_email": function(value = null) {
            return Validator.value(value).email();
        },
        "data.permanent_mobile": function(value = null) {
            return Validator.value(value)
                .digit()
                .regex("01+[0-9+-]*$", "Must start with 01.")
                .minLength(11)
                .maxLength(11);
        },
        "data.father_mobile_no": function(value = null) {
            return Validator.value(value)
                .digit()
                .regex("01+[0-9+-]*$", "Must start with 01.")
                .minLength(11)
                .maxLength(11);
        },
        "data.father_email": function(value = null) {
            return Validator.value(value).email();
        },
        "data.mother_mobile_no": function(value = null) {
            return Validator.value(value)
                .digit()
                .regex("01+[0-9+-]*$", "Must start with 01.")
                .minLength(11)
                .maxLength(11);
        },
        "data.mother_email": function(value = null) {
            return Validator.value(value).email();
        }
    }
};
</script>
