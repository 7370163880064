<template>
  <div class="video-gallery" v-if="!$root.loading">
    <h4 class="title text-white">Video Gallery</h4>
    <iframe
      width="100%"
      height="215"
      :src="video.youtube_url"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <h5 class="py-2 text-light">{{video.title}}</h5>
    <p class="text-white">{{video.caption}}</p>
  </div>
</template>
<script>

export default {
  name: "videoGallery",
  data() {
    return {
      video: {}
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/video")
        .then(respons => {
          this.video = respons.data.video;
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
