<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div v-if="content" class="row align-items-center">
          <slot v-if="content.image">
            <div class="col-xs-12 col-lg-5">
              <img :src="content.FullUrl+content.image" class="img-fluid" alt="..." />
            </div>
            <div class="col-xs-12 col-lg-7" data-aos="fade-right">
              <!--Heading-->
              <h5 class="font-weight-bold text-uppercase">{{content.title}}</h5>
              <!--Text-->
              <p class="text-justify" v-html="content.description"></p>
            </div>
          </slot>
          <slot v-else>
            <div class="col-xs-12 col-lg-12" data-aos="fade-right">
              <h5 class="font-weight-bold text-uppercase">{{content.title}}</h5>
              <p class="text-justify" v-html="content.description"></p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
export default {
  name: "Content Details",
  components: {
    Banner
  },
  data() {
    return {
      content: {image:'', title:''},
      breadcrumbTitle: "",
      breadcumb: []
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/content_detail/" + this.$route.params.slug)
        .then(respons => {
          this.breadcumb = [{ title: respons.data.result.title }];
          this.content = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true
    }
  }
};
</script>
