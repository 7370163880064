<template>
  <div id="payment">
    <div class="row align-items-center">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto;">Password Change</h5>
    </div>
    <div class="row card">
      <div class="col-12 card-body">
        <form v-on:submit.prevent="submit" class="form-row px-3">
          <input type="hidden" v-model="data.id" />
          <div class="form-group col-6">
            <label class="col-form-label-sm font-weight-normal mb-0"> Current Password</label>
            <input
              v-model="data.current_password"
              type="password"
              class="form-control form-control-sm"
              placeholder="Current Password"
            />
            <small v-if="errors && errors.current_password" class="text-danger">{{ errors.current_password[0] }}</small>
          </div>
          <div class="form-group col-3">
            <label class="col-form-label-sm font-weight-normal mb-0">New Password</label>
            <input
              v-model="data.new_password"
              type="password"
              class="form-control form-control-sm"
              placeholder="New Password"
            />
            <small v-if="errors && errors.new_password" class="text-danger">{{ errors.new_password[0] }}</small>
          </div>
          
          <div class="form-group col-3">
            <label class="col-form-label-sm font-weight-normal mb-0">Confirm New Password</label>
            <input
              v-model="data.new_password_confirmation"
              type="password"
              class="form-control form-control-sm"
              placeholder="Confirm New Password"
            />
            <small v-if="errors && errors.new_password_confirmation" class="text-danger">{{ errors.new_password_confirmation[0] }}</small>
          </div>
          <div class="form-group col-12">
            <button class="btn btn-sm btn-primary mt-3" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      errors: []
    };
  },
  methods: {
    submit: function() {
      axios
        .post("/student/changePassword", this.data)
        .then(respons => {
          console.log(respons.data);
          if (respons.data.error) {
            this.$toaster.error(respons.data.error)
          }
          if (respons.data.success) {
            this.$toaster.success(respons.data.success)
            this.$router.go();
            // this.data=[];
            // this.errors=[];
          }
          // console.log("roel done");
        })
        .catch(error => {
          this.loaded = true;
          
          if (error.response.status === 422) {
            this.errors = error.response.data.errors || {};
            this.focus_field = Object.keys(this.errors)[0];
          }
        });
    }
  },
  created() {
    // axios
    //   .get(
    //     "class-routine/" +
    //       this.$route.params.classID +
    //       "/" +
    //       this.$route.params.sectionID
    //   )
    //   .then(response => {
    //     this.data.routineDetails = response.data;
    //   });
    this.$root.loading = false;
  }
};
</script>
