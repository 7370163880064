<template>
  <div id="allpage">
      <keep-alive>
          <router-view></router-view>
      </keep-alive>
  </div>
</template>

<script>

export default {
  data: function () {
      return {}
  }
};
</script>
