<template>
  <div id="payment">
    <div class="row align-items-center mb-3">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto;">Class Routine</h5>
    </div>
    <div class="row" v-if="Object.keys(data.routineDetails).length>0">
      <div class="col-12" v-if="Object.keys(data.routineDetails)">
        <table class="table table-bordered tablesorter table-hover tableLay">
          <thead>
            <tr>
              <td>Summer</td>
              <td v-if="data && data.routineDetails[0]">
                {{ data.routineDetails[0].details[0].duration.start }} -
                {{ data.routineDetails[0].details[0].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[1]">
                {{ data.routineDetails[0].details[1].duration.start }} -
                {{ data.routineDetails[0].details[1].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[2]">
                {{ data.routineDetails[0].details[2].duration.start }} -
                {{ data.routineDetails[0].details[2].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[3]">
                {{ data.routineDetails[0].details[3].duration.start }} -
                {{ data.routineDetails[0].details[3].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[4]">
                {{ data.routineDetails[0].details[4].duration.start }} -
                {{ data.routineDetails[0].details[4].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[5]">
                {{ data.routineDetails[0].details[5].duration.start }} -
                {{ data.routineDetails[0].details[5].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[6].duration.start }} -
                {{ data.routineDetails[0].details[6].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[7].duration.start }} -
                {{ data.routineDetails[0].details[7].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[8].duration.start }} -
                {{ data.routineDetails[0].details[8].duration.end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[9]?
                data.routineDetails[0].details[9].duration.start:'' }} -
                {{ data.routineDetails[0].details[9]?
                data.routineDetails[0].details[9].duration.end:'' }}
              </td>
            </tr>
            <tr>
              <td>Winter</td>
              <td v-if="data && data.routineDetails[0]">
                {{ data.routineDetails[0].details[0].duration.winter_start }} -
                {{ data.routineDetails[0].details[0].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[1]">
                {{ data.routineDetails[0].details[1].duration.winter_start }} -
                {{ data.routineDetails[0].details[1].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[2]">
                {{ data.routineDetails[0].details[2].duration.winter_start }} -
                {{ data.routineDetails[0].details[2].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[3]">
                {{ data.routineDetails[0].details[3].duration.winter_start }} -
                {{ data.routineDetails[0].details[3].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[4]">
                {{ data.routineDetails[0].details[4].duration.winter_start }} -
                {{ data.routineDetails[0].details[4].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[5]">
                {{ data.routineDetails[0].details[5].duration.winter_start }} -
                {{ data.routineDetails[0].details[5].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[6].duration.winter_start }} -
                {{ data.routineDetails[0].details[6].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[7].duration.winter_start }} -
                {{ data.routineDetails[0].details[7].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[8].duration.winter_start }} -
                {{ data.routineDetails[0].details[8].duration.winter_end }}
              </td>
              <td v-if="data && data.routineDetails[6]">
                {{ data.routineDetails[0].details[9]?
                data.routineDetails[0].details[9].duration.winter_start:'' }} -
                {{ data.routineDetails[0].details[9]?
                data.routineDetails[0].details[9].duration.winter_end:'' }}
              </td>
            </tr>
          </thead>
          <tbody>
            <slot v-for="(routineD, index) in data.routineDetails">
              <tr :key="'A'+index">
                <td style="width:10px;">{{ routineD.day }}</td>
                <td colspan="10" class="dataAll">
                  <table class="table table-bordered tablesorter table-hover tableLay mb-0">
                    <slot v-for="(routine,index1) in routineD.details">
                      <td :key="index1" class="perGap">
                        <slot v-if="routine.subject && routine.status == null || routine.status">
                          <div class="teacher mb-2">{{ routine.teacher }}</div>
                          <hr />
                          <b>{{ routine.subject }}</b>
                        </slot>
                        <slot v-else>
                          <span class="d-flex justify-content-center">OFF</span>
                        </slot>
                      </td>
                    </slot>
                  </table>
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="card card-body bg-ligh text-center">No Class Routine</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: { routineDetails: {} },
      errors: []
    };
  },
  created() {
    axios
      .get(
        "class-routine/" +
          this.$route.params.classID +
          "/" +
          this.$route.params.sectionID
      )
      .then(response => {
        this.data.routineDetails = response.data;
      });
    this.$root.loading = false;
  }
};
</script>
