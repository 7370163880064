<template>
  <div class="about">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <!-- Login Form -->
        <div class="login-form py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <div class="card shadow-sm">
                  <span class="shape"></span>
                  <div class="card-header text-center bg-transparent">
                    <i class="fas fa-user-circle"></i>
                    <h2>Student Login</h2>
                  </div>
                  <div class="card-body py-4">
                    <div
                      v-if="responseMsg"
                      class="alert alert-danger"
                      role="alert"
                    >
                      {{ text }}
                    </div>
                    <form v-on:submit.prevent="submit" id="form">
                      <div class="form-group">
                        <label for="name">New Password</label>
                        <input
                          type="password"
                          class="form-control shadow-none"
                          v-model="data.password"
                          required
                          placeholder="Password"
                        />
                        <small
                          v-if="errors && errors.password"
                          class="text-danger"
                          >{{ errors.password[0] }}</small
                        >
                      </div>
                      <div class="form-group">
                        <label for="name">Confirm Password</label>
                        <input type="hidden" v-model="data.studentid" />
                        <input
                          type="password"
                          class="form-control shadow-none"
                          v-model="data.confirm_password"
                          required
                          placeholder="Confirm Password"
                        />
                        <small
                          v-if="errors && errors.confirm_password"
                          class="text-danger"
                          >{{ errors.confirm_password[0] }}</small
                        >
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login Form -->
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./../pages/Banner.vue";
const breadcumb = [
  {
    title: "Applicant Login",
  },
];
export default {
  name: "Login",
  components: {
    Banner,
  },
  data() {
    return {
      data: {
        studentid: this.$route.params.studentid,
        password: null,
      },
      responseMsg: false,
      breadcrumbTitle: "",
      text: "",
      errors: {},
      breadcumb: breadcumb,
    };
  },
  created() {
    //   this is for student redirection to ip server
    // location.href = "http://119.10.174.11/student/login";
  },
  methods: {
    submit: function (e) {
      let reset_token = this.$route.params.reset_token;
      let studentid = this.$route.params.studentid;
      axios
        .post(
          "/student/reset-password/" + studentid + "/" + reset_token,
          this.data
        )
        .then((respons) => {
          if (respons.data) {
            this.$router.push({
              name: "student.login",
            });
          } else {
            this.responseMsg = true;
            this.text = "Sorry!! Your Reset Email Information is Wrong";
          }
        })
        .catch((error) => {
          if (error) {
            this.responseMsg = true;
            this.text = "Sorry!! Your Reset Information is Wrong";
          }
        });
    },
  },
  mounted() {
    //this.loggedOrNot();
    this.$root.loading = false;
  },
};
</script>
