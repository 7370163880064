<template>
  <div class="about">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <!-- Login Form -->
        <div class="login-form py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <div class="card shadow-sm">
                  <span class="shape"></span>
                  <div class="card-header text-center bg-transparent">
                    <i class="fas fa-user-circle"></i>
                    <h2>Student Login</h2>
                  </div>
                  <div class="card-body py-4">
                    <div v-if="responseMsg" class="alert alert-danger" role="alert">{{ text }}</div>

                      <form v-on:submit.prevent="submit" id="form">
                      <div class="form-group">
                        <label>Student ID</label>
                        <input
                          type="text"
                          class="form-control shadow-none"
                          v-model="data.studentid"
                          required
                          placeholder="Student ID"
                        />
                        <small
                          v-if="errors && errors.studentid"
                          class="text-danger"
                          >{{ errors.studentid[0] }}</small
                        >
                      </div>
                      <div class="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          class="form-control shadow-none"
                          v-model="data.password"
                          required
                          placeholder="Password"
                        />
                        <small
                          v-if="errors && errors.password"
                          class="text-danger"
                          >{{ errors.password[0] }}</small
                        >
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn">Submit</button>
                        <div class="d-flex justify-content-center links">
                          <a href="#" data-toggle="modal" data-target="#myModal" @click="getStudInfo(data.studentid)" style="color:blue">Forgot Password</a>
                        </div>
                      </div>
                    </form>

                    <div class="modal fade" id="myModal">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <!-- Modal Header -->
                          <div class="modal-header text-center">
                            <p class="modal-title"><span class="font-weight-bold">Account recovery</span> <br/> This helps show that this account really belongs to you</p>
                            <button type="button" class="close" data-dismiss="modal">×</button>
                          </div>

                          <!-- Modal body -->
                          <div class="modal-body">
                            <form v-on:submit.prevent="forgot" v-if="!isMobile">
                              <div class="form-group">
                                <label>Student ID</label>
                                <input
                                  type="text"
                                  class="form-control shadow-none"
                                  v-model="forgot_info.studentid"
                                  required
                                  placeholder="Student ID"
                                  @input="getStudInfo(forgot_info.studentid)"
                                />
                                <small v-if="errors.studentid" class="text-danger">{{ errors.studentid }}</small>
                              </div>

                              <div class="form-group" v-if="forgot_info.email">
                                 <input type="radio" name="forgot" v-model="forgot_info.type" value="email"/>
                                  Confirm the email address {{ forgot_info.email }} and we'll send you a link to reset your password.
                              </div>
                              <div class="form-group" v-if="forgot_info.mobile">
                                <input type="radio" name="forgot" v-model="forgot_info.type" value="mobile"/>
                                  To get a verification code on your Parents Mobile{{ forgot_info.mobile }}.
                              </div>

                              <div class="form-group text-center">
                                <button type="submit" class="btn btn-primary">Send</button>
                              </div>
                            </form>

                            <form v-on:submit.prevent="verifyCode" v-if="isMobile">

                                {{ (countDown == 0)? 'Timeout Please try again!' : countDown + ' Seconds' }}

                                  <div class="form-group">
                                      <label>Verification code</label>
                                      <input
                                          type="text"
                                          class="form-control shadow-none"
                                          v-model="forgot_info.code"
                                          required
                                          placeholder="verify Code"
                                      />
                                      <small v-if="errors.code" class="text-danger">{{ errors.code }}</small>
                                  </div>

                                  <div class="form-group text-center">
                                      <button type="submit" class="btn btn-primary">Verify</button>
                                      <button type="button" @click="forgot" v-if="isReSent" class="btn btn-info">Re-sent</button>
                                  </div>
                              </form>
                          </div>

                          <!-- Modal footer -->
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login Form -->
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
    import Banner from "./../pages/Banner.vue";
    const breadcumb = [
        {
            title: "Applicant Login",
        },
    ];
    export default {
        name: "Login",
        components: {Banner},
        data() {
            return {
                data: {studentid: null, password: null,},
                countDown : 180,
                forgot_info:{
                    studentid: '',
                    emaiil: '',
                    moble: '',
                    code: '',
                    type:''
                },
                responseMsg: false,
                breadcrumbTitle: "",
                text: "",
                errors: {},
                isMobile:false,
                isReSent:false,
                breadcumb: breadcumb,
            };
        },
        created() {},
        methods: {
            submit() {
                this.$root.loading = true;
                axios
                    .post("/student/login/submit", this.data)
                    .then((response) => {
                        if (response.data) {
                            this.$root.student.login = true;
                            this.$router.push({name: "student.dashboard",});
                            this.dominionList();
                            this.getRoleList();
                            this.getAllSystemVariable();
                            this.gteLoggedUser();
                        }
                        else {
                            this.responseMsg = true;
                            this.text = "Sorry!! Your Login Information is Wrong";
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            this.responseMsg = true;
                            this.text = "Your are allready Logged in a device";
                        }else{
                            if (error) {
                                this.responseMsg = true;
                                this.text = "Sorry!! Your Login Information is Wrong";
                            }
                        }

                    }).then(res => {
                    this.$root.loading = false;
                });
            },

            forgot() {
                axios
                    .post("/student/forgot-password", this.forgot_info)
                    .then((res) => {
                        if(res.data.type == 'email'){
                            this.$toaster.success(res.data.message);
                        }else if(res.data.type == 'mobile'){
                            this.isMobile = true;
                            this.isReSent = false;
                            this.countDown = 180;
                            this.countDownTimer();
                            this.$toaster.success(res.data.message);
                        }
                    })
                    .catch((error) => {
                        this.$toaster.error('Invalid student ID');
                    });
            },

            verifyCode() {
                if(this.forgot_info.code && (this.forgot_info.code.length == 4)){
                    axios.post('/student/verify-code', this.forgot_info)
                        .then(res => {
                            $('#myModal').modal('hide');
                            window.location.replace(res.data.path);
                        })
                        .catch(error => {
                            if(error.response.status == 404){
                                this.errors.code = 'Invalid verify code';
                                this.$toaster.error('Invalid verify code');
                            }else {
                                this.errors.code = error.response.data.message;
                                this.$toaster.error(error.response.data.message);
                            }

                        });
                }
                else {this.errors = {};}
            },

            flashCode(){
                axios
                    .post("/student/flash-code", this.forgot_info)
                    .then((res) => {this.isReSent = true;});
            },

            countDownTimer() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1;
                        this.countDownTimer()
                    }, 1000)
                }else{
                   this.flashCode()
                }
            },

            getStudInfo(studentid){
                if(studentid && (studentid.length >= 9)){
                    axios.get('student/forgot-stu-info/'+studentid)
                        .then(res => {
                            this.forgot_info = res.data;
                            this.errors = {};
                        })
                        .catch(error => {
                            if(error.response.status == 404){
                                this.forgot_info = {studentid: '', email: '', mobile: '',};
                                this.errors.studentid = 'Invalid student ID';
                                this.$toaster.error('Invalid student ID, Please Enter valid Student ID');
                            }
                        });
                }
                else {this.errors = {};}
            },

            dominionList() {
                axios
                    .get("dominionList")
                    .then(response => {
                        this.$root.allDominionList = response.data;
                    })
                    .catch(error => {
                        console.log(error.response);
                    });
            },
            getRoleList() {
                axios
                    .get("roleList")
                    .then(response => {
                        this.$root.roleList = response.data;
                    })
                    .catch(error => {
                        console.log(error.response);
                    });
            },
            getAllSystemVariable() {
                axios
                    .get("variablelist")
                    .then(response => {
                        this.$root.variable = response.data;
                    })
                    .catch(error => {
                        console.log(error.response);
                    });
            },
            gteLoggedUser() {
                axios
                    .get('/userinfo')
                    .then(response => {
                        this.$root.logged_user = response.data;
                        this.$root.user_permissions = response.data.permisson.permission_list;
                        this.$root.login = true

                    })
                    .catch(error => {
                        this.$root.login = false
                    })
            },
        },
        mounted() {
            //this.loggedOrNot();
            this.$root.loading = false;
        },
    };
</script>
