<template>
  <div>
    <p v-if="table_name && Object.keys(data).length > 0" class="my-0">
      {{ table_name }}
    </p>
    <!-- ================Excel , PDF, Print Button================ -->
    <div
      class="box-header col-12 pl-0 pr-0 no-padding epp-content"
      style="justify-content: space-between; display: flex"
    >
      <div>
        <select
          @change="$parent.search"
          v-model="$parent.search_data.pagination"
          class="form-control form-control-sm paginate"
        >
          <option>20</option>
          <option>50</option>
          <option>100</option>
          <option>200</option>
          <option>500</option>
          <option>All</option>
        </select>
      </div>
      <div>
        <!-- print -->
        <a
          href="javascript:void(0)"
          class="btn btn-print btn-sm"
          @click="print('printArea', $parent.base_route)"
          v-b-tooltip.hover.topright
          title="Print"
        >
          <i class="fa fa-print"></i>
        </a>
        <!-- /print -->
        <!-- PDF -->
        <a
          href="javascript:void(0)"
          class="btn btn-pdf btn-sm"
          @click="generatePdf"
          v-b-tooltip.hover.topright
          title="PDF"
        >
          <i class="fa fa-file-pdf fa-1x"></i>
        </a>
        <!-- /pdf -->
        <!-- Excel -->
        <download-excel
          v-if="$parent.table.datas"
          class="btn btn-excel btn-sm"
          :data="$parent.table.datas"
          :fields="$parent.excel_columns"
          :name="'Download.xls'"
        >
          <i
            class="fa fa-file-excel"
            v-b-tooltip.hover.topright
            title="Export as Excel"
          ></i>
        </download-excel>
        <!-- Excel -->
      </div>
    </div>
    <!-- ================Excel , PDF, Print Button================ -->
    <div
      class="b-table-sticky-header"
      style="max-height: 500px; min-height: 500px"
      id="printArea"
    >
      <h2
        v-if="onPrint.title"
        :style="onPrint.style"
        :class="onPrint.class"
        class="on-print"
        v-html="onPrint.title"
      ></h2>
      <table
        id="pdf-table"
        class="table b-table border table-hover height-100"
        :class="tableClass"
        border="1"
      >
        <thead class="thead-light" :class="theadClasses">
          <tr>
            <th class="align-text-top text-nowrap">SN</th>
            <slot name="columns">
              <th
                class="text-nowrap sort-th"
                v-for="(column, index) in columns"
                :key="column.field + index"
                @click="sort(column.field)"
                :class="columnsCenter ? 'text-center' : 'align-text-top'"
              >
                {{ column.title }}
                <span v-if="coloumSort == column.field">
                  <i
                    v-if="!column.subfield && order == 'desc'"
                    class="fas fa-arrow-up text-right"
                  ></i>
                  <i
                    v-if="!column.subfield && order == 'asc'"
                    class="fas fa-arrow-down text-right"
                  ></i>
                </span>
              </th>
              <th
                class="align-text-top text-nowrap"
                v-if="Object.keys(modals).length > 0"
              >
                Modal
              </th>
              <th
                class="align-text-top text-nowrap action"
                v-if="
                  Object.keys(routes).length > 0 &&
                  ($root.checkPermission(routes.view) ||
                    $root.checkPermission(routes.edit) ||
                    $root.checkPermission(routes.destroy))
                "
              >
                Action
              </th>
            </slot>
          </tr>
        </thead>
        <tbody :class="tbodyClasses" v-if="Object.keys(data).length > 0">
          <tr v-for="(item, index) in data" :key="index">
            <td v-if="$parent.meta" :class="columnsCenter ? 'text-center' : ''">
              {{ $parent.meta.from + index }}
            </td>
            <td v-else>{{ index + 1 }}</td>
            <slot :row="item">
              <td
                v-for="(column, index) in columns"
                :key="index"
                :v-if="hasValue(item, column.field)"
                :class="
                  column.textAlign
                    ? column.textAlign
                    : column.currency
                    ? 'text-right'
                    : ''
                "
              >
                <span
                  v-if="column.html"
                  v-html="
                    itemValue(item, column.field, column.subfield, column)
                  "
                ></span>
                <span
                  v-else-if="
                    column.maxLength &&
                    itemValue(item, column.field, column.subfield, column)
                      .length > column.maxLength
                  "
                  class="des"
                >
                  {{
                    itemValue(
                      item,
                      column.field,
                      column.subfield,
                      column
                    ).substring(0, column.maxLength) + "..."
                  }}
                </span>
                <span v-else-if="column.date">
                  {{
                    itemValue(item, column.field, column.subfield, column)
                      | formatDate
                  }}
                </span>
                <span v-else-if="column.datetime">
                  {{
                    itemValue(item, column.field, column.subfield, column)
                      | formatDateTime
                  }}
                </span>
                <img
                  v-else-if="
                    column.image &&
                    itemValue(item, column.field, column.subfield, column)
                  "
                  :src="
                    $root.baseurl +
                    itemValue(item, column.field, column.subfield, column)
                  "
                  :width="column.width ? column.width : '80px'"
                  :height="column.height"
                />

                <span v-else-if="column.time">
                  {{
                    itemValue(item, column.field, column.subfield, column)
                      | formatTime
                  }}
                </span>
                <span v-else-if="column.percentage">
                  {{
                    itemValue(item, column.field, column.subfield, column) + "%"
                  }}
                </span>
                <span v-else-if="column.currency">
                  {{
                    itemValue(item, column.field, column.subfield, column)
                      | currency
                  }}
                </span>
                <span v-else>
                  {{ itemValue(item, column.field, column.subfield, column) }}
                </span>
              </td>

              <td v-if="Object.keys(modals).length > 0">
                <slot v-for="(modal, index) in modals">
                  <Modal
                    :key="index"
                    :datas="modal"
                    :record="item"
                    v-if="activeModul(item, 'is_approved', 'Approved')"
                  ></Modal>
                  <span
                    :key="index"
                    v-if="!activeModul(item, 'is_approved', 'Approved')"
                    >{{ item.is_approved }}</span
                  >
                </slot>
              </td>
              <td
                v-if="
                  (Object.keys(routes).length > 0 &&
                    ($root.checkPermission(routes.view) ||
                      $root.checkPermission(routes.edit) ||
                      $root.checkPermission(routes.destroy))) ||
                  Object.keys(extra_routes).length > 0
                "
                class="text-nowrap action"
                valign="center"
              >
                <slot v-for="(extra_route, index) in extra_routes">
                  <router-link
                    v-if="
                      $root.checkPermission(extra_route.route_name) &&
                      extra_route.route_type == 'custom'
                    "
                    :key="index"
                    :to="{
                      name: extra_route.route_name,
                      params: extra_route.params,
                    }"
                    class="btn btn-primary btn-sm"
                    v-b-tooltip.hover.topleft
                    :title="extra_route.title"
                  >
                    <span v-html="extra_route.icon"></span>
                    {{ extra_route.title }}
                  </router-link>
                  <router-link
                    v-else-if="$root.checkPermission(extra_route.route_name)"
                    :to="{
                      name: extra_route.route_name,
                      params: item,
                    }"
                    class="btn btn-primary btn-sm"
                    v-b-tooltip.hover.topleft
                    :title="extra_route.title"
                  >
                    <span v-html="extra_route.icon"></span>
                    {{ extra_route.title }}
                  </router-link>
                </slot>
                <router-link
                  v-if="$root.checkPermission(routes.view)"
                  :to="{
                    name: routes.view,
                    params: { id: item.id },
                  }"
                  class="btn btn-primary btn-sm"
                  title="View"
                  v-b-tooltip.hover.bottomleft
                >
                  View
                  <span class="fas icon-table fa-eye"></span>
                </router-link>
                <router-link
                  v-if="$root.checkPermission(routes.edit)"
                  :to="{
                    name: routes.edit,
                    params: { id: item.id },
                  }"
                  class="btn btn-info btn-sm"
                  v-b-tooltip.hover.bottomleft
                  title="Edit"
                >
                  Edit
                  <span class="fas icon-table fa-edit"></span>
                </router-link>
                <button
                  v-if="$root.checkPermission(routes.destroy)"
                  v-on:click="destroy(item)"
                  class="btn btn-warning btn-sm"
                  v-b-tooltip.hover.bottomleft
                  title="Delete"
                >
                  Delete
                  <span class="fas icon-table fa-trash-alt"></span>
                </button>
                <br />

                <!--New Fee setup invoice-->
                <button
                  v-if="
                    item.hasOwnProperty('invoice_status') &&
                    item.invoice_status == 1 &&
                    $root.checkPermission(routes.feeSetupCreateInvoice)
                  "
                  @click="createAllInvoice(item.id)"
                  class="btn btn-success btn-sm"
                >
                  Create Invoice
                </button>

                <button
                  v-if="
                    item.hasOwnProperty('invoice_status') &&
                    item.invoice_status == 0 &&
                    $root.checkPermission(routes.feeSetupClearInvoice)
                  "
                  @click="clearAllInvoice(item.id)"
                  class="btn btn-danger btn-sm"
                >
                  Clear Invoice
                </button>

                <!--End New Fee setup invoice-->
              </td>
            </slot>
          </tr>
        <slot name="footer">

        </slot>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              :colspan="Object.keys(columns).length + 2"
              style="text-align: center; background: #fff"
            >
              <img
                :src="$root.baseurl + '/images/nodatafound.png'"
                style="height: 20em; width: 30em; object-fit: contain"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="$root.loading" class="text-center">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "./Modal/Modal.vue";
export default {
  name: "base-table",
  data() {
    return {
      order: "desc",
      coloumSort: "",
      sorting_spin: false,
    };
  },
  components: { Modal },
  props: {
    table_name: {
      type: String,
      default: null,
    },
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    routes: {
      type: Object,
      default: () => [],
      description: "Table Action",
    },
    extra_routes: {
      type: Array,
      default: () => [],
      description: "Extra Action",
    },
    modals: {
      type: Array,
      default: () => [],
      description: "Modal",
    },
    onPrint: {
      type: Object,
      default() {
        return {};
      },
      description: "Header",
    },
    allPage: {
      type: true,
      default() {
        return false;
      },
      description: "Pagination",
      },
    showFullList: {
        type: true,
        default() {
            return false;
        },
        description: "Show full list",
    },
    columnsCenter: {
      type: Boolean,
      default: false,
      description: "Table header align",
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column, subcolunn = null, obj = null) {
      var objcolumn = item[column] ? item[column] : "";
      if (column == "guard") {
        return this.$root.guards[objcolumn];
      }
      if (typeof objcolumn == "object" && subcolunn) {
        if (obj.condition) {
          let value = "";
          item[column].forEach((element) => {
            if (element[obj.condition.field] == obj.condition.value) {
              value = element[subcolunn];
              return;
            }
          });
          return value;
        } else {
          return item[column][subcolunn];
        }
      } else {
        return objcolumn;
      }
    },
    activeModul: function (obj, field_name, value) {
      if (obj[field_name] == value) {
        return false;
      }
      return true;
    },

    clearInvoice(obj) {
      if (confirm("Do you really want to Clear Invoice?")) {
        this.$parent.clearInvoice(obj);
      }
    },
    clearAllInvoice(obj) {
      if (confirm("Do you really want to Clear Invoice?")) {
        this.$parent.clearAllInvoice(obj);
      }
    },

    createInvoice(obj) {
      if (confirm("Do you really want to Create Invoice?")) {
        this.$parent.createInvoice(obj);
      }
    },
    createAllInvoice(obj) {
      if (confirm("Do you really want to Create Invoice?")) {
        this.$parent.createAllInvoice(obj);
      }
    },
    changeStatus(obj) {
      if (confirm("Do you really want to this action?")) {
        this.$parent.changeStatus(obj);
      }
    },
    changePassword(obj) {
      if (confirm("Do you really want to change password?")) {
        this.$parent.changePassword(obj);
      }
    },
    destroy(obj) {
      if (confirm("Do you really want to delete?")) {
        this.$parent.destroy(obj);
        this.notification("Deleted Successfully", "success");
      }
    },

    sort(field) {
      this.coloumSort = field;
      this.data.sort(this.sortBy(field));
    },
    sortBy(property) {
      if (this.order === "desc") {
        this.order = "asc";
      } else {
        this.order = "desc";
      }
      const order = this.order;
      return function (a, b) {
        const varA =
          typeof a[property] === "string"
            ? a[property].toUpperCase()
            : a[property];
        const varB =
          typeof b[property] === "string"
            ? b[property].toUpperCase()
            : b[property];

        let comparison = 0;
        if (varA > varB) comparison = 1;
        else if (varA < varB) comparison = -1;
        return order === "desc" ? comparison * -1 : comparison;
      };
    },
    generatePdf() {
      const doc = new jsPDF();
      $(".action").css("display", "none");
      autoTable(doc, { html: "#pdf-table" });
      doc.save("Download.pdf");
    },
  },
};
</script>
<style>
table .btn-sm {
  font-size: 0.635rem !important;
  line-height: 1 !important;
  display: flex;
  align-items: baseline;
}
.on-print {
  display: none;
  text-align: center;
}
@media print {
  .on-print {
    display: block;
    text-align: center !important;
  }
}
</style>
