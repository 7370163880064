<template>
  <div id="payment">
    <div class="row align-items-center mb-3">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto">
        Lesson Plan
      </h5>
    </div>
    <div class="row" v-if="table.datas">
      <table
        class="table table-striped table-fixed table-bordered"
        v-if="Object.keys(table.datas).length > 0"
      >
        <thead>
          <tr class="thead-dark">
            <th>SL</th>
            <th>Teacher</th>
            <th>Title</th>
            <th>Subject</th>
            <th>Uploaded Date</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in table.datas" :key="index">
            <!-- <pre> {{item}}</pre> -->
            <td>{{ index + 1 }}</td>
            <td>{{ item.teacher.name }}</td>
            <td>{{ item.title }}</td>

            <td>{{ item.subject.name }}</td>
            <td>{{ item.created_at | formatDate }}</td>
            <td>
              <table class="table tablesorter border table-hover" border="1">
                <tbody>
                  <tr
                    v-for="(item1, index1) in item.teacher_upload_file_details"
                    :key="index1"
                  >
                    <td>
                      <slot v-if="item1.original_name">
                        <a
                          :href="item1.FullUrl + item1.file"
                          target="_blank"
                          class="nav-link"
                          >Click Here Download/View</a
                        >
                      </slot>
                      <slot v-else>
                        <a
                          :href="item1.FullUrl + item1.file"
                          target="_blank"
                          class="nav-link"
                          >Click Here Download/View</a
                        >
                      </slot>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="panel-body text-center mt-3" style="margin: auto" v-else>
        <h4>No Data Available !!</h4>
      </div>
    </div>
    <div v-else class="card card-body bg-ligh text-center">No Course File</div>
    <div class="page-footer" v-if="meta.last_page > 1">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-5 p-0">
            <div role="status" aria-live="polite">
              Showing {{ meta.from }} to {{ meta.to }} of
              {{ meta.total }} entries
            </div>
          </div>
          <div class="col-sm-12 col-md-7 p-0">
            <nav aria-label="Page navigation example">
              <ul class="pagination pagination-sm justify-slider_content-end">
                <li class="page-item">
                  <a
                    class="page-link"
                    v-on:click="get_datas(1)"
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-if="meta.current_page > 2">
                  <a
                    class="page-link"
                    v-on:click="get_datas(meta.current_page - 2)"
                    >{{ meta.current_page - 2 }}</a
                  >
                </li>
                <li class="page-item" v-if="meta.current_page > 1">
                  <a
                    class="page-link"
                    v-on:click="get_datas(meta.current_page - 1)"
                    >{{ meta.current_page - 1 }}</a
                  >
                </li>
                <li class="page-item active">
                  <a
                    class="page-link"
                    v-on:click="get_datas(meta.current_page)"
                    >{{ meta.current_page }}</a
                  >
                </li>
                <li class="page-item" v-if="meta.current_page < meta.last_page">
                  <a
                    class="page-link"
                    v-on:click="get_datas(meta.current_page + 1)"
                    >{{ meta.current_page + 1 }}</a
                  >
                </li>
                <li
                  class="page-item"
                  v-if="meta.current_page + 1 < meta.last_page"
                >
                  <a
                    class="page-link"
                    v-on:click="get_datas(meta.current_page + 2)"
                    >{{ meta.current_page + 2 }}</a
                  >
                </li>
                <li class="page-item">
                  <a
                    class="page-link"
                    v-on:click="get_datas(meta.last_page)"
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      table: {
        datas: [],
      },
      errors: [],
      pagination: [],
      meta: [],
      links: [],
      is_hit: false,
    };
  },
  methods: {
    get_datas: function (pageindex, date = null) {
      let url =
        "/student/course_file/" +
        this.$route.params.classID +
        "/" +
        this.$route.params.sectionID;
      if (pageindex > 0) {
        url =
          "/student/course_file/" +
          this.$route.params.classID +
          "/" +
          this.$route.params.sectionID +
          "?page=" +
          pageindex;
      }
      axios
        .get(url, { params: { date: date } })
        .then((respons) => {
          this.is_hit = true;
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.$parent.debug_data = respons;
        })
        .catch((error) => {})
        .then((res) => {
          setTimeout(
            () => (this.$root.loading = false),
            this.$root.minloadtimefd
          );
        });
    },
  },
  created() {
    if (this.$route.params.date) {
      this.get_datas("", this.$route.params.date);
    } else {
      this.get_datas();
    }
    this.$root.loading = false;
  },
};
</script>
