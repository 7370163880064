<template>
  <div id="payment">
    <div class="row align-items-center mb-3">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto;">Attendance</h5>
    </div>
    <div class="row card">
      <div class="col-12">
        <select
          v-on:change="getAttandance($parent.data.id, $event.target.value)"
          class="form-control col-3"
        >
          <option value>Select month</option>
          <option value="01-01">January</option>
          <option value="01-02">February</option>
          <option value="01-03">March</option>
          <option value="01-04">April</option>
          <option value="01-05">May</option>
          <option value="01-06">June</option>
          <option value="01-07">July</option>
          <option value="01-08">August</option>
          <option value="01-09">September</option>
          <option value="01-10">October</option>
          <option value="01-11">November</option>
          <option value="01-12">December</option>
        </select>
      </div>
      <div class="col-12 card-body" v-if="Object.keys(datas) && Object.keys(datas).length>0">
        <table class="table table-bordered tablesorter table-hover tableLay">
          <tr>
            <th style="width:5%;">SL</th>
            <th style="width:40%;">Name</th>
            <th style="width:20%;">Date</th>
            <th style="width:15%;">Attendance</th>
          </tr>
          <tr v-for="(data, index) in datas" :key="index">
            <td>{{ (index++)+1 }}</td>
            <td>{{ data.name }}</td>
            <td>{{ data.attendance_date }}</td>
            <td>
              <span v-if="data.present" class="text-success font-weight-bold">P</span>
              <span v-else class="text-danger font-weight-bold">A</span>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="card card-body bg-ligh text-center">No Attendance</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datas: {},
      count: 0,
      errors: []
    };
  },
  methods: {
    getAttandance(id, month = null) {
      this.$root.loading = true;
      axios
        .get("getStudentAttendance/" + id, { params: { month: month } })
        .then(response => {
          this.datas = response.data;
        })
        .catch(errer => {})
        .then(always => {
          this.$root.loading = false;
        });
    }
  },
  created() {
    this.getAttandance(this.$parent.data.id);
    this.$root.loading = false;
  },
  updated() {
    if (Object.keys(this.datas).length < 1) {
      if (this.count <= 0) {
        this.getAttandance(this.$parent.data.id);
      }
      this.count++;
    }
  }
};
</script>
