<template>
  <div class="about">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <!-- Login Form -->
        <div class="login-form py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7 text-center">
                <router-link
                  :to="{ name: 'student.login' }"
                  class="btn-new purple"
                >
                  <i class="fas fa-user"></i> Student Login
                </router-link>
                <router-link
                  :to="{ name: 'applicant.login' }"
                  class="btn-new orange"
                >
                  <i class="fas fa-user"></i> Applicant Login
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login Form -->
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>
<style>
.btn-new {
  border-radius: 5px;
  padding: 15px 25px;
  font-size: 22px;
  text-decoration: none;
  margin: 20px;
  color: #fff;
  position: relative;
  display: inline-block;
}

.btn-new:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}

.purple {
  background-color: #9b59b6;
  box-shadow: 0px 5px 0px 0px #82409d;
}

.purple:hover {
  background-color: #b573d0;
}

.orange {
  background-color: #e67e22;
  box-shadow: 0px 5px 0px 0px #cd6509;
}

.orange:hover {
  background-color: #ff983c;
}
</style>
<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Login",
  },
];
export default {
  name: "Login",
  components: {
    Banner,
  },
  data() {
    return {
      responseMsg: false,
      breadcrumbTitle: "",
      text: "",
      errors: {},
      breadcumb: breadcumb,
    };
  },
  created() {},
  methods: {},
  mounted() {
    this.$root.loading = false;
  },
};
</script>
