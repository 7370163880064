<template>
  <div class="about">
    <div class="row student-profile">
      <div class="col-lg-12">
        <a
          class="btn btn-primary"
          v-on:click="admitCard()"
          style="color: #fff; cursor: pointer"
        >Download your admit card</a>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./../pages/Banner.vue";
const breadcumb = [
  {
    title: "Admit Card"
  }
];
export default {
  name: "Admit",
  components: {
    Banner
  },
  data() {
    return {
      breadcrumbTitle: "",
      text: "",
      errors: {},
      breadcumb: breadcumb,
      applicant: {
        info: null
      }
    };
  },
  methods: {
    admitCard: function(e) {
      this.$root.loading = true;
      axios({
        method: "get",
        url: "/applicant/admitcard"
      })
        .then(response => {
          const link = document.createElement("a");
          link.href = "/applicant/downloadAdmit";
          link.setAttribute("download", "admit-card.pdf");
          document.body.appendChild(link);
          link.click();
          this.$root.loading = false;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  mounted() {
    this.loggedIn();
    this.$root.loading = false;
  }
};
</script>
