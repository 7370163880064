<template>
  <div class="banner">
    <!--page banner-->
    <div
      class="page-banner position-relative pt-5"
      :style="{ backgroundImage: `url(${$root.baseurl + images.background})` }"
    >
      <div class="overlay"></div>
      <div class="container">
        <div class="row align-items-center py-5">
          <div class="col-md-6">
            <h4 class="page-title text-center text-lg-left text-white font-weight-bold text-uppercase">
              {{ msg }}
            </h4>
          </div>
          <div class="col-md-6 text-center text-lg-left">
            <ul class="breadcrumb bg-transparent justify-content-lg-end justify-content-center">
              <li class="breadcrumb-item">
                <router-link class="breadcrumb-item" to="/">
                  <a href="#">Home</a>
                </router-link>
              </li>
              <li
                class="breadcrumb-item"
                v-for="(route, index) in data"
                :key="index"
              >
                <span
                  v-if="
                    index == Object.keys(data).length - 1 || !route.route_name
                  "
                  >{{ route.title }}</span
                >
                <router-link
                  :to="{ name: route.route_name }"
                  class="breadcrumb-item"
                  v-else
                  >{{ route.title }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--//page banner-->
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    msg: String,
    data: {},
  },
  data() {
    return {
      images: {
        background: "/images/pages-banner.jpg",
      },
    };
  },
  mounted() {
    this.$root.loading = false;
  },
};
</script>
