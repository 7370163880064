<template>
  <div class="footer">
    <div class="fixed-button">
      <router-link
        v-if="$root.student.login == true"
        :to="{ name: 'student.dashboard' }"
        class="btn"
      >
        <i class="fas fa-user"></i> Profile
      </router-link>
      <router-link
        v-else-if="$root.applicant.login == true"
        :to="{ name: 'applicant.dashboard' }"
        class="btn"
      >
        <i class="fas fa-user"></i> Profile
      </router-link>
      <slot v-else>
        <router-link
          v-if="$root.student.login == false || $root.applicant.login == false"
          :to="{ name: 'home.login' }"
          class="btn"
        >
          <i class="fas fa-sign-in-alt"></i> Login
        </router-link>
      </slot>
    </div>
    <a href="#" class="backTop">
      <span></span>
    </a>
    <div class="row">
      <div class="col-xs-12 col-md-4 col-lg-3">
        <!-- Brand -->
        <img
          :src="$root.site.FullUrl + $root.site.image"
          alt="..."
          class="footer-brand img-fluid mb-2"
        />
        <!-- Text -->
        <p class="text-gray-700 mb-2">{{ $root.site.slogan }}</p>
        <!-- Social -->
        <ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
          <li
            class="list-inline-item list-social-item mr-3"
            v-for="(item, index) in $root.socialLink"
            :key="index"
          >
            <a
              target="_blank"
              :href="item.url"
              class="text-decoration-none text-light"
            >
              <span :class="item.icon"></span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-2">
        <!-- Heading -->
        <h6 class="font-weight-bold text-uppercase text-gray-700">
          External Link
        </h6>
        <!-- List -->
        <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
          <li class="mb-3" v-for="(item, index) in footer_bottom" :key="index">
            <!-- <a href="javascript:void(0)" class="text-light">{{item.title}}</a> -->
            <router-link
              v-if="item.type === 'content'"
              style="color: white"
              :to="{ name: 'content', params: { slug: item.slug } }"
              >{{ item.title }}</router-link
            >
            <router-link v-else style="color: white" :to="{ name: item.url }">{{
              item.title
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-2">
        <h6 class="font-weight-bold text-uppercase text-gray-700">Result</h6>
        <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
          <li class="mb-3" v-for="(item, index) in result" :key="index">
            <!-- <a href="javascript:void(0)" class="text-light">{{item.title}}</a> -->
            <router-link
              v-if="item.type === 'content'"
              style="color: white"
              :to="{ name: 'content', params: { slug: item.slug } }"
              >{{ item.title }}</router-link
            >
            <router-link v-else style="color: white" :to="{ name: item.url }">{{
              item.title
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-md-4 col-lg-4 offset-md-4 offset-lg-0">
        <!-- Heading -->
        <h6 class="font-weight-bold text-uppercase text-gray-700">Contact</h6>
        <!-- List -->
        <address class="text-muted mb-6 mb-md-8 mb-lg-0">
          <p class="text-light">{{ $root.site.address }}</p>
          <p class="text-light">Telephone: {{ $root.site.phone }}</p>
          <p class="text-light">E-mail: {{ $root.site.email }}</p>
        </address>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <p class="text-light">
          Developed by:
          <strong>
            <a target="_blank" href="http://www.nogorsolutions.com" class="text-light">Nogor Solutions Ltd.</a>
          </strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
    import  image from "../../../assets/images/logo.png";
export default {
  name: "Menu",
  data() {
    return {
      data: {},
      footer_bottom: {},
      result: {},
      images: {
        logo: image,
      },
    };
  },
  methods: {
    get_data() {
      axios
        .get("/all_layout_data/footer")
        .then((respons) => {
          this.footer_bottom = respons.data.footer_bottom;
          this.result = respons.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSCroll(event) {
      let header = document.querySelector(".backTop");
      if (window.scrollY > 400 && !header.className.includes("show")) {
        header.classList.add("show");
      } else if (window.scrollY < 400) {
        header.classList.remove("show");
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleSCroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleSCroll);
  },
  mounted() {
    this.get_data();
  },
};
</script>
