require('./frontend_bootstrap');
window.Vue = require('vue');
import Vue from 'vue';

require('./Helpers/Frontend/plugin');
require('./Helpers/Frontend/mixin');

// ===============router=============
import router from "./Router/frontend_router.js";

import notFoundPage from './views/Frontend/elements/404'
Vue.component('notFoundPage', notFoundPage);


Vue.component("app", require('./components/FrontendApp.vue').default);
// ===============app===============

const app = new Vue({
    el: "#app",
    router,
    data: {
        baseurl: laravel.baseurl,
        loading: true,
        login: false,
        guards: { web: 'Member', admin: 'Admin' },
        status: { Active: 'Active', Inactive: 'Inactive' },
        variable: {},
        logged_user: [],
        user_permissions: [],
        menu_datas: [],
        site: [],
        student: {login: false},
        student_login_info: {},
        applicant: {login: false},
        isMobile: false,
    },
    methods: {
        getAllSystemVariable() {
            axios
                .get("variablelist")
                .then(response => {
                    this.variable = response.data;
                });
        },
        getFrontSystemVariable() {
            axios
                .get("front/variable-list")
                .then(response => {
                    this.variable = response.data;
                });
        },
        gteLoggedUser() {
            axios
                .get('/userinfo')
                .then(response => {
                    this.logged_user = response.data;
                    this.login = true

                })
                .then(() => {
                    this.login = false
                })
        },

        checkPermission(routeName) {
            if (routeName.length < 0) {
                routeName = this.$route.name
            }
            let index = '';
            for (index in this.user_permissions) {
                if (this.user_permissions[index] == routeName) {
                    return true;
                }
            }
            return false
        },
        getMenuData() {
            axios
                .get('rootmenu/side_menu')
                .then(res => {
                    this.menu_datas = res.data
                })
        },
        goBack() {
            window.history.length > 1 ?
                this.$router.go(-1) :
                this.$router.push('/')
        },

        isLoggedStudent() {
            return axios.post("student/profile").then((response) => {
                if (Object.keys(response.data).length > 0) {
                    this.student.login = true;
                    this.student_login_info = response.data;
                } else {
                    this.student.login = false;
                }
            });
        },
        isLoggedApplicant() {
            return axios.get('/applicant/loggedOrNot').then((response) => {
                if (response.data) {
                    this.applicant.login = true;
                    return true;
                } else {
                    this.applicant.login = false;
                    return false;
                }
            });
        }
    },
    async created() {
        await this.isLoggedStudent();
        await this.isLoggedApplicant();
        this.getFrontSystemVariable();

        if(this.student.login || this.applicant.login){
            this.getAllSystemVariable();
            this.gteLoggedUser();
        }
    },
    mounted() {
        this.site_info()

    }

});

