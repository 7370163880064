<template>
    <div class="student__layout" v-if="this.$root.student.login">
        <Topbar/>
        <div class="about student__panel pt-lg-5 pt-0">
            <div class="welcome-text py-0 py-lg-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-3 px-0 px-lg-3 mt-lg-4">
                            <div class="sidebar card shadow-sm">
                                <div v-if="!isActiveMobile" class="py-3 px-4 bg-dark d-flex d-lg-none justify-content-between align-items-center">
                                    <span class="text-white">Cantonment English School & College</span>
                                    <a href="javascript:void(0)" class="btn btn-info" @click="isActiveMobile = true"><i class="fa fa-align-justify"></i></a>
                                </div>
                                <div class="card-header d-lg-none d-flex" v-if="!isActiveMobile">
                                    <div class="image__box">
                                        <img
                                            v-if="data.image"
                                            :src="$root.baseurl+ data.image"
                                            alt
                                        />
                                        <img v-else class="profile_img" :src="image_src" alt />
                                    </div>
                                    <div class="userinfo">
                                        <h3 v-if="data.name">{{data.name.substring(0,10)+'..'}}</h3>
                                        <h6 v-if="data.roll">Roll: {{ data.roll }}</h6>
                                        <h6 v-if="data.stdclass && data.stdclass.name">Class: {{data.stdclass.name ? data.stdclass.name :''}}</h6>
                                        <h6 v-if="data.studentid">ID: {{data.studentid}}</h6>
                                        <span v-if="data.class_monitor == 1" class="text-warning border border-warning p-1">Class Monitor <i class="fa fa-eye"></i></span>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" v-if="isActiveMobile" @click="isActiveMobile = false" class="d-block d-lg-none sideber-closer"><i class="fa fa-times"></i></a>
                                <div class="sidebar-overly" v-if="isActiveMobile"  @click="isActiveMobile = false"></div>
                                <ul class="navbar-nav sidemenu accordion" v-if="isActiveMobile" id="accordionExample">
                                    <div class="card-header inside-sidebar">
                                        <div class="image__box">
                                            <img
                                                v-if="data.image"
                                                :src="$root.baseurl+ data.image"
                                                alt
                                            />
                                            <img v-else class="profile_img" :src="image_src" alt />
                                        </div>
                                        <div class="userinfo">
                                            <h3 v-if="data.name">{{data.name.substring(0,10)+'..'}}</h3>
                                            <h6 v-if="data.roll">Roll: {{ data.roll }}</h6>
                                            <h6 v-if="data.stdclass && data.stdclass.name">Class: {{data.stdclass.name ? data.stdclass.name :''}}</h6>
                                            <h6 v-if="data.studentid">ID: {{data.studentid}}</h6>
                                            <span v-if="data.class_monitor == 1" class="text-warning border border-warning p-1">Class Monitor <i class="fa fa-eye"></i></span>
                                        </div>
                                    </div>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.dashboard',
                                                  params: { dparams: $route.params },}"
                                        >Dashboard
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.profile',
                                                  params: { dparams: $route.params },}"
                                        >Profile
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{
                      name: 'student.payment',
                      params: { dparams: $route.params },
                    }"
                                        >Payment History
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link class="nav-link" :to="{ name: 'student.pay' }"
                                        >Due history
                                        </router-link
                                        >
                                    </li>
                                    <!-- <li class="nav-item">
                                      <a href="javascript:void(0)" class="nav-link">Result</a>
                                    </li>-->
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.AcademicCalendar' }"
                                        >Academic Calendar
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.Attendance' }"
                                        >Attendance
                                        </router-link
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.result' }"
                                        >Results
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.libraryBookList' }"
                                        >Library Book List
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.Notification' }"
                                        >Notification
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{name: 'student.studentRoutine', params: {classID: data.std_class_id,sectionID: data.section_id,},}"
                                        >Class Routine
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.course_file', params: {classID: data.std_class_id, sectionID: data.section_id,},}"
                                        >Lesson Plan
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.onlineClass' }"
                                        >Online Class
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link class="nav-link" :to="{ name: 'student.quiz' }"
                                        >Quiz
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.quiz_archive' }"
                                        >Quiz Archive
                                        </router-link
                                        >
                                    </li>
                                    <!-- <li class="nav-item">
                                      <a href="javascript:void(0)" class="nav-link">Discipline Status</a>
                                    </li>
                                    <li class="nav-item">
                                      <a href="javascript:void(0)" class="nav-link">Download</a>
                                    </li>
                                    <li class="nav-item">
                                      <a href="javascript:void(0)" class="nav-link">Co-Curricular activities</a>
                                    </li>
                                    <li class="nav-item">
                                      <a href="javascript:void(0)" class="nav-link">Others</a>
                                    </li>-->
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'student.PasswordChange' }"
                                        >Password Change
                                        </router-link
                                        >
                                    </li>
                                    <li class="nav-item d-block d-lg-none">
                                        <a
                                            @click="logout()"
                                            href="javascript:void(0)"
                                            class="nav-link"
                                        >Logout</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-9 mt-4 mt-lg-4">
                            <div
                                class="row"
                                v-if="is_show && $route.name != 'student.quiz_detail'"
                            >
                                <div class="col-12" v-if="message">
                                    <div class="alert alert-warning">
                                        <a
                                            href="#"
                                            class="close"
                                            data-dismiss="alert"
                                            aria-label="close"
                                            title="close"
                                        >×</a
                                        >
                                        <strong>Success!</strong>
                                        {{ message }}
                                        <router-link
                                            style="color: red"
                                            data-dismiss="alert"
                                            aria-label="close"
                                            :to="{ name: 'student.PasswordChange' }"
                                        >Click Here
                                        </router-link
                                        >
                                    </div>
                                </div>

                                <div class="col-12" v-if="data && !paymentStatus">
                                    <div class="alert alert-warning" style="color: red;">
                                        <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                                        <strong>Attention!</strong>
                                        Payment service temporarily turn off for system update. please wait till completion.
                                    </div>
                                </div>

                                <div class="col-12" v-if="data && !data.image">
                                    <div class="alert alert-warning" style="color: red;">
                                        <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                                        <strong>Attention!</strong>
                                        Dear student update your profile information.
                                    </div>
                                </div>
                            </div>
                            <router-view></router-view>
                            <FlashMessage></FlashMessage>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alart-body" v-if="data && !data.image && isAlert">
            <div class="card alert-box">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 text-center text-white mt-5">
                            <h5>Dear student update your profile information!</h5>
                        </div>
                        <div class="col-12 text-center mt-4">
                            <button type="button" @click="isAlert = false" class="btn btn-primary px-5">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Banner from "./../pages/Banner.vue";
    import image from "../../../assets/img/avatar.png";
    import Topbar from "./dashboard/Topbar";

    const breadcumb = [
        {
            title: "Student",
        },
    ];
    export default {
        name: "DashboardLayout",
        components: {
            Topbar,
            Banner,
        },
        data() {
            return {
                isAlert: false,
                isActiveMobile: true,
                breadcrumbTitle: "",
                data: {},
                is_show: true,
                image_src: image,
                message: "",
                paymentStatus: true,
                breadcumb: breadcumb,
                student:{login: false}
            };
        },
        watch:{
          $route(){
              // this.isActiveMobile = false;
          }
        },
        methods: {
            full_logout: function () {
                axios.post("logout").then((res) => {
                    this.$root.student.login = false;
                    this.$root.roleList = [];
                    this.$root.allDominionList =[];
                    this.$root.variable = [];
                    this.$root.variables = {};
                    this.$root.logged_user =[];
                    this.$root.user_permissions = [];
                    this.$router.push("/");
                }).then((always) => {
                    this.$root.loading = false;
                });
            },
            logout: function () {
                this.$root.loading = true;
                axios
                    .get("student/cahse_clear")
                    .then((response) => {
                        if (response) {
                            this.full_logout();
                        }
                        //this.full_logout();
                    })
                    .catch((error) => {
                    })
                    .then((always) => {
                        this.$root.loading = false;
                    });
            },
        },
        async created() {
            await axios.post("student/profile").then((response) => {
                if (Object.keys(response.data).length > 0) {
                    this.$root.student.login = true;
                } else {
                    this.$root.student.login = false;
                    this.$router.push("/");
                }
                this.data = response.data;
                if (this.data.update_password) {
                    this.is_show = false;
                } else {
                    this.message = "Please Change your Password";
                }
            });

            axios.get("student/get/config").then((response) => {
                if(response.data){
                    this.paymentStatus = response.data.payment_on_off;
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        mounted(){
            setTimeout(()=>this.isAlert = true, 5000);
        }
    };
</script>

<style>
    .alert-box {
        width: 300px;
        height: 200px;
        z-index: 12345676;
        position: fixed;
        top: 20%;
        box-shadow: 0px 4px 29px #000000b5;
        background: #19335df5;
        border: 0 !important;
        border-radius: 28px;
    }
    .alart-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
