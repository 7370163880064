var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"payment"}},[_vm._m(0),_vm._v(" "),(Object.keys(_vm.exam).length > 0)?_c('div',{staticClass:"row"},_vm._l((_vm.exam),function(data){return _c('div',{key:data.id,staticClass:"col-md-4"},[(
          data.time <= _vm.current_time &&
          data.to_time >= _vm.current_time &&
          _vm.moment().format('YYYY-MM-DD') == data.date
        )?_vm._t("default",function(){return [(data.id != _vm.applied[data.id])?_vm._t("default",function(){return [(data.religion)?_vm._t("default",function(){return [(data.religion == this.$root.logged_user.religion)?_vm._t("default",function(){return [_c('div',{staticClass:"text-center box-body"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",modifiers:{"hover":true,"topleft":true}}],attrs:{"to":{
                    name: 'student.quiz_detail',
                    params: {
                      examId: data.id,
                      student_id: _vm.$root.logged_user.id,
                    },
                  },"title":data.subject.name}},[(data.subject)?_c('h5',{staticClass:"text-uppercase m-0"},[_vm._v("\n                    "+_vm._s(data.subject.name)+"\n                  ")]):_vm._e(),_vm._v(" "),_c('i',{staticClass:"fas fa-calendar-alt"}),_vm._v("\n                  "+_vm._s(data.full_date)+"\n                  "),_c('br'),_vm._v(" "),_c('i',{staticClass:"fas fa-clock"}),_vm._v("\n                  "+_vm._s(_vm.moment(data.date + " " + data.time).format("hh:mm A"))+"\n                  -\n                  "+_vm._s(_vm.moment(data.date + " " + data.to_time).format("hh:mm A"))+"\n                ")])],1)]}):_vm._e()]}):_vm._t("default",function(){return [_c('div',{staticClass:"text-center box-body"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",modifiers:{"hover":true,"topleft":true}}],attrs:{"to":{
                  name: 'student.quiz_detail',
                  params: {
                    examId: data.id,
                    student_id: _vm.$root.logged_user.id,
                  },
                },"title":data.subject.name}},[_c('div',{staticClass:"text-success pt-2"},[_vm._v("Go to question papper")]),_vm._v(" "),(data.subject)?_c('h5',{staticClass:"text-uppercase m-0 pt-2"},[_vm._v("\n                  "+_vm._s(data.subject.name)+"\n                ")]):_vm._e(),_vm._v(" "),_c('i',{staticClass:"fas fa-calendar-alt"}),_vm._v("\n                "+_vm._s(data.full_date)+"\n                "),_c('br'),_vm._v(" "),_c('i',{staticClass:"fas fa-clock"}),_vm._v("\n                "+_vm._s(_vm.moment(data.date + " " + data.time).format("hh:mm A"))+" -\n                "+_vm._s(_vm.moment(data.date + " " + data.to_time).format("hh:mm A"))+"\n              ")])],1)]})]}):_vm._t("default",function(){return [_c('div',{staticClass:"text-center box-body"},[_c('h5',{staticClass:"text-uppercase text-danger m-0"},[_vm._v("Exam Applied")]),_vm._v(" "),(data.auto_publish == 'yes')?_vm._t("default",function(){return [(data.can_view_reslt == 'yes')?_vm._t("default",function(){return [_c('router-link',{attrs:{"to":{
                    name: 'student.online_exam_result_detail',
                    params: { examId: data.id },
                  }}},[_vm._v("View Result")])]}):_vm._e()]}):_vm._e(),_vm._v(" "),(
                data.can_view_exam_paper == 'yes' &&
                data.final_submit != 'yes'
              )?_vm._t("default",function(){return [_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",modifiers:{"hover":true,"topleft":true}}],staticClass:"text-success pt-2",attrs:{"to":{
                  name: 'student.quiz_detail',
                  params: {
                    examId: data.id,
                    student_id: _vm.$root.logged_user.id,
                  },
                },"title":data.subject.name}},[_vm._v("Go to question papper")])]}):_vm._e(),_vm._v(" "),(data.subject)?_c('h5',{staticClass:"text-uppercase m-0 pt-2"},[_vm._v("\n              "+_vm._s(data.subject.name)+"\n            ")]):_vm._e(),_vm._v(" "),_c('i',{staticClass:"fas fa-calendar-alt"}),_vm._v("\n            "+_vm._s(data.full_date)+"\n            "),_c('br'),_vm._v(" "),_c('i',{staticClass:"fas fa-clock"}),_vm._v("\n            "+_vm._s(_vm.moment(data.date + " " + data.time).format("h:m A"))+" -\n            "+_vm._s(_vm.moment(data.date + " " + data.to_time).format("h:m A"))+"\n          ")],2)]})]}):_vm._t("default",function(){return [_c('div',{staticClass:"text-center box-body"},[(data.id == _vm.applied[data.id])?_vm._t("default",function(){return [_c('h5',{staticClass:"text-uppercase text-danger m-0"},[_vm._v("Exam Applied")])]}):_vm._e(),_vm._v(" "),(data.auto_publish == 'yes' && data.id == _vm.applied[data.id])?_vm._t("default",function(){return [(data.can_view_reslt == 'yes')?_vm._t("default",function(){return [_c('router-link',{attrs:{"to":{
                  name: 'student.online_exam_result_detail',
                  params: { examId: data.id },
                }}},[_vm._v("View Result")])]}):_vm._e()]}):_vm._e(),_vm._v(" "),(data.can_view_exam_paper == 'yes')?_vm._t("default",function(){return [_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",modifiers:{"hover":true,"topleft":true}}],staticClass:"text-success pt-2",attrs:{"to":{
                name: 'student.quiz_detail',
                params: {
                  examId: data.id,
                  student_id: _vm.$root.logged_user.id,
                },
              },"title":data.subject.name}},[_vm._v("Go to question papper")])]}):_vm._t("default",function(){return [_c('div',{staticStyle:{"display":"none"},attrs:{"id":data.id}},[_c('router-link',{staticClass:"text-success pt-2",attrs:{"to":{
                  name: 'student.quiz_detail',
                  params: {
                    examId: data.id,
                    student_id: _vm.$root.logged_user.id,
                  },
                }}},[_vm._v("Go to question papper")])],1)]}),_vm._v(" "),(data.subject)?_c('h5',{staticClass:"text-uppercase m-0 pt-2"},[_vm._v("\n            "+_vm._s(data.subject.name)+"\n          ")]):_vm._e(),_vm._v(" "),_c('i',{staticClass:"fas fa-calendar-alt"}),_vm._v("\n          "+_vm._s(data.full_date)+"\n          "),_c('br'),_vm._v(" "),_c('i',{staticClass:"fas fa-clock"}),_vm._v("\n          "+_vm._s(_vm.moment(data.date + " " + data.time).format("h:m A"))+" -\n          "+_vm._s(_vm.moment(data.date + " " + data.to_time).format("h:m A"))+"\n        ")],2)]})],2)}),0):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.is_hit)?_c('h5',{staticClass:"text-center"},[_vm._v("No Exam Fround Right Now")]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-center"},[_c('h5',{staticClass:"font-weight-bold text-uppercase mb-4",staticStyle:{"margin":"auto"}},[_vm._v("\n      Quiz\n    ")])])}]

export { render, staticRenderFns }