<template>
  <div class="row align-items-center" v-if="!$root.loading">
    <div class="col-sm-12">
      <h4 class="m-0 font-weight-bold text-center">Principal</h4>
      <hr />
    </div>
    <div class="col-sm-12 teachers">
      <div class="row justify-content-center">
        <div class="col-md-3">
          <router-link :to="{ name:'teacher.detail', params: { id: teacher.id }}">
            <div class="card-box p-2" style="min-height:240px;">
              <img v-if="teacher.image" :src="$http +teacher.image" class alt="..." />
              <img v-else :src="$http + '/images/no-image.png'" class alt="..." />
              <div class="card-content shadow-sm">
                <h5 class="title text-center">
                  <a href>{{teacher.name}}</a>
                </h5>
                <p class="sub-title text-center">Mobile No-{{teacher.mobile_no}}</p>
                <p class="sub-title text-center">{{teacher.designation.name}}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "newsticker",
  data() {
    return {
      teacher: {}
    };
  },
  methods: {
    get_data: function() {
      this.$root.loading = true;
      let url = "/home/teacherlist/principal";
      axios
        .get(url)
        .then(respons => {
          this.teacher = respons.data.teacher;
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
<style scoped>
.newsticker {
  background: #f59b29;
}
.marquee-body {
  max-width: 95%;
  overflow: hidden;
  margin: 0 auto;
}
.marquee {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  animation: scroll 20s linear infinite;
  padding: 3px;
  display: flex;
  align-items: center;
}
.marquee:hover {
  animation-play-state: paused;
}
.marquee li {
  padding: 1rem;
  color: #fff;
}
/* Make it move */
@keyframes scroll {
  0% {
    left: 800px;
  }
  100% {
    left: -800px;
  }
}
</style>
