<template>
    <div class="about">
        <div class="row student-profile">
            <div class="col-md-12 mb-3">
                <div class="row">
                    <div class="col-md-3 mb-3 col-6">
                        <div class="card card-body counter-box">
                            <router-link :to="{ name: 'student.quiz' }">
                                <h3 class="couter_icon1"><i class="fa fa-pen"></i></h3>
                                <h4>0 <br>
                                    <span> Quiz</span>
                                </h4>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3 col-6">
                        <div class="card card-body counter-box">
                            <router-link :to="{ name: 'student.quiz_archive' }">
                                <h3 class="couter_icon2"><i class="fa fa-book"></i></h3>
                                <h4>
                                    {{ total_quiz_archive }}<br>
                                    <span>Quiz Archive</span>
                                </h4>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3 col-6">
                        <div class="card card-body counter-box">
                            <router-link :to="{ name: 'student.course_file',
                                      params: { classID: $parent.data.std_class_id,
                                      sectionID: $parent.data.section_id,}}"
                            >
                                <h3 class="couter_icon3"><i class="fa fa-calendar"></i></h3>
                                <h4>
                                    {{ (lesson_plan.total_lesson > 0) ? lesson_plan.total_lesson : 0 }} <br>
                                    <span>Lesson Plan</span>
                                </h4>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3 col-6">
                        <div class="card card-body counter-box">
                            <router-link :to="{ name: 'student.onlineClass' }">
                                <h3 class="couter_icon4"><i class="fa fa-desktop"></i></h3>
                                <h4>8 <br> <span>Online Class</span></h4>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-6 mb-3">
                <div class="card daily-sales">
                    <div class="card-body">
                        <div class="table-header mb-3 d-flex justify-content-between">
                            <h3 class="">Lesson Plan</h3>
                            <router-link
                            v-if="$parent.data"
                                class="btn btn-success py-1 px-3"
                                :to="{name: 'student.course_file', params: { classID: $parent.data.std_class_id, sectionID: $parent.data.section_id}}"
                            >
                                View all
                            </router-link>
                        </div>
                        <table class="table table-bordered" style="margin: -6px">
                            <thead style="background: #17a2b8">
                            <tr>
                                <th>Teacher</th>
                                <th>Title & Subject</th>
                                <th>Uploaded Date</th>
                            </tr>
                            </thead>
                            <tbody v-if="lesson_plan.datas && Object.keys(lesson_plan.datas).length > 0">
                            <tr v-for="(data, index) in lesson_plan.datas" :key="index">
                                <td>{{ data.teacher.name }}</td>
                                <td>
                                    {{ data.title }}<br/>
                                    <b v-if="data.subject">{{ data.subject.name }}</b>
                                </td>
                                <td>{{ data.created_at | formatDate }}</td>
                            </tr>
                            </tbody>

                        </table>
                        <!-- <div v-if="lesson_loader" class="text-center mt-5" >
                            <span><i class="fa fa-spinner fa-spin"></i>Loading...</span>
                        </div> -->
                        <div  v-if="lesson_plan.datas && Object.keys(lesson_plan.datas).length == 0" class="text-center mt-5" >
                            <span>No data found!</span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-6 mb-3">
                <div class="card Monthly-sales">
                    <div class="card-body">
                        <div class="table-header mb-3 d-flex justify-content-between">
                            <h3 class="">Attandance Status</h3>
                            <select v-if="$parent.data"
                                v-on:change="getAttandance($parent.data.id, $event.target.value)"
                                class="form-control col-6"
                            >
                                <option value>Select month</option>
                                <option value="01-01">January</option>
                                <option value="01-02">February</option>
                                <option value="01-03">March</option>
                                <option value="01-04">April</option>
                                <option value="01-05">May</option>
                                <option value="01-06">June</option>
                                <option value="01-07">July</option>
                                <option value="01-08">August</option>
                                <option value="01-09">September</option>
                                <option value="01-10">October</option>
                                <option value="01-11">November</option>
                                <option value="01-12">December</option>
                            </select>
                        </div>
                        <div id="chart">
                            <apexchart
                                type="pie"
                                width="380"
                                :options="chartOptions"
                                :series="series"
                            ></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 col-xl-5 mb-3">
                <div class="card Monthly-sales">
                    <div class="card-body">
                        <div class="table-header mb-3 d-flex justify-content-between">
                            <h3 class="">Recent Notice</h3>
                        </div>
                        <div class="recent_notice">
                            <marquee
                                direction="up"
                                scrollamount="2"
                                onmouseout="start()"
                                onmouseover="stop()"
                                height="100%"
                                behavior="scroll"
                            >
                                <ul class="list-group">
                                    <li
                                        v-for="(general, generalNotice) in Notices['academic']"
                                        :key="generalNotice"
                                    >
                                        <div style="display: flex; margin-bottom: 5px">
                                            <div class="">
                                                <router-link
                                                    v-if="general.type === 'content'"
                                                    :to="{
                        name: 'notice.detail',
                        params: {
                          category: general.category,
                          slug: general.slug,
                        },
                      }"
                                                >
                                                    <h5 class="notice_cat">
                                                        <img
                                                            v-if="general.new == 'yes'"
                                                            style="height: 30px; width: 30px"
                                                            :src="$root.baseurl + '/img/blingking.gif'"
                                                            alt="image"
                                                        />
                                                        {{ general.title }}
                                                        <span
                                                            class="pull-right"
                                                            style="font-size: 10px; color: red"
                                                            v-if="general.updated != '0' && general.new == 'no'"
                                                        >Notice has been updated {{ general.updated }} days
                          ago</span
                                                        >
                                                    </h5>
                                                </router-link>
                                                <a
                                                    v-else
                                                    :href="general.FullUrl + general.file"
                                                    target="_blank"
                                                >
                                                    <h5 class="notice_cat" style="font-size: 14px">
                                                        <img
                                                            v-if="general.new == 'yes'"
                                                            style="height: 30px; width: 30px"
                                                            :src="$root.baseurl + '/img/blingking.gif'"
                                                            alt="image"
                                                        />
                                                        {{ general.title }}
                                                        <span
                                                            class="pull-right"
                                                            style="font-size: 10px; color: red"
                                                            v-if="general.updated != '0' && general.new == 'no'"
                                                        >Notice has been updated {{ general.updated }} days
                          ago</span
                                                        >
                                                    </h5>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </marquee>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-xl-7 mb-3">
                <div class="card daily-sales">
                    <div class="card-body">
                        <div class="table-header mb-3 d-flex justify-content-between">
                            <h3 class="">Today Class Routine</h3>
                            <router-link
                                class="btn btn-success py-1 px-3"
                                :to="{ name: 'student.onlineClass' }">
                                View all
                            </router-link>
                        </div>
                        <h5 class="text-info">{{ todayDate() }}</h5>
                        <div class="row" v-if="data.routineDetails">
                            <div class="col-md-4 border today-routine text-center" v-for="(routine,index) in data.routineDetails" :key="index">
                                <slot v-if="routine.subject && routine.status == null || routine.status">
                                    <small class="d-block"><b>{{ routine.subject}}</b></small>
                                    <small class="d-block">{{ routine.teacher}}</small>
                                    <small class="d-block">
                                        Summer[ <small>{{ routine.duration.start }}</small> -<small>{{ routine.duration.end }}</small>]<br>
                                        Winter[ <small>{{ routine.duration.winter_start }}</small> -<small>{{ routine.duration.winter_end }}</small>]<br>
                                    </small>
                                </slot>
                                <slot v-else>
                                    <span class="d-flex justify-content-center">OFF</span>
                                </slot>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        <!--Welcome-text-->
    </div>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";
    import Banner from "./../pages/Banner.vue";
    import image from "../../../assets/img/avatar.png";
    import moment from "moment"

    const breadcumb = [
        {
            title: "Applicant Login",
        },
    ];
    export default {
        name: "Dashboard",
        components: {Banner, apexchart: VueApexCharts},
        data() {
            return {
                lesson_loader:false,
                lesson_plan: {loader: false, datas: [], total_lesson: ''},
                total_quiz_archive: 0,
                total_quiz: 0,
                Notices: [],
                attendances: {},
                headerCountData: {},
                data: {routineDetails:{}},
                image_src: image,
                breadcumb: breadcumb,
                series: [],
                chartOptions: {labels: ["Absend", "Present"],},
            };
        },
        methods: {
            todayDate(){
                return moment().format('dddd - MMM - Y');
            },
            get_datas: function (pageindex, date = null) {
                this.lesson_loader = true;
                let url =
                    "/student/lesson_plan/" +
                    this.$parent.data.std_class_id +
                    "/" +
                    this.$parent.data.section_id;
                if (pageindex > 0) {
                    url =
                        "/student/lesson_plan/" +
                        this.$parent.data.std_class_id +
                        "/" +
                        this.$parent.data.section_id +
                        "?page=" +
                        pageindex;
                }
                axios
                    .get('/student/lesson_plan')
                    .then((respons) => {
                        this.is_hit = true;
                        if(respons.data.lesson_plan && Object.keys(respons.data.lesson_plan).length>0){
                            this.lesson_plan.datas = respons.data.lesson_plan;
                        }
                        if(respons.data.total_lesson ){
                            this.lesson_plan.total_lesson = respons.data.total_lesson;
                        }
                        this.lesson_loader = false;
                    })
                    .catch((error) => {console.log(error); this.lesson_loader = false;})
                    .then((res) => {
                        this.lesson_loader = false;
                    });
            },
            get_notice() {
                this.$root.loading = true;
                axios
                    .get("/home/type/noticecat")
                    .then((respons) => {
                        this.Notices = respons.data.noticecat;
                        this.$root.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            getAttandance(id, month = null) {
                this.$root.loading = true;
                axios
                    .get("dashboardAttendance/" + id, {params: {month: month}})
                    .then(response => {
                        this.attendances = response.data;
                        this.series = [response.data.absend.length, response.data.present.length]
                    })
                    .catch(error => {
                    })
                    .then(always => {
                        this.$root.loading = false;
                    });
            },
            getClassRoutine() {
                axios
                    .get(
                        "/dashboard-class-routine/" + moment().format("ddd"))
                    .then(response => {
                        this.data.routineDetails = response.data;
                    });
                this.$root.loading = false;
            },

            getQuizArchive(){
                axios.get("student/quiz-archive").then((response) => {
                    this.total_quiz_archive = response.data.total_quiz_archive;
                });
            },
            online_exam: function () {
                axios.get("student/online-exam").then((response) => {
                    this.total_quiz = response.data.total_quiz;

                });
            },

            // getCountData() {
            //     axios.get('/get-header-countdata')
            //         .then(res => {
            //             this.headerCountData = res.data;
            //         })
            //         .catch(error => {
            //             console.log(error.response)
            //         })
            // }
        },
        mounted() {
            this.online_exam();
            this.$root.loading = false;
        }
        ,
        created() {
            setTimeout(() => {
                this.get_datas();
            }, 1500);
            this.getQuizArchive();
            this.get_notice();
            this.getAttandance('get-attendance');
            this.getClassRoutine();

            // this.getCountData();

            // axios.post("student/profile").then(response => {
            //   console.log(response);
            //   this.data = response.data;
            // });
        }
        ,
        updated() {
            if (Object.keys(this.attendances).length < 1) {
                if (this.count <= 0) {
                    this.getAttandance('get-attendance');
                }
                this.count++;
            }

        }
        ,
    }
    ;
</script>
<style scoped>
    .student-profile .table th {
        font-size: 14px;
        padding: 5px 10px;
        color: #fff;
    }

    .row.student-profile .card {
        height: 100%;
    }

    .recent_notice ul li a {
        line-height: 15px;
    }
    .today-routine small {
        font-size: 12px;
    }
</style>
