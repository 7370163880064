<template>
  <div class="loader">
    <div class="d-flex" style="height: 300px !important">
      <div class="d-flex w-100 justify-content-center align-self-center">
        <span class="fas fa-spinner fa-spin fa-5x text-dark"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loader {
  background: rgba(255, 255, 255, 0);
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: self-start;
}
</style>

