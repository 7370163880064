<template>
  <div>
    <!-- <b-button id="show-btn" @click="showModal">Open Modal</b-button> -->
    <button class="btn btn-table text-primary" id="toggle-btn" @click="toggleModal">{{datas.link_name}}</button>

    <b-modal size="lg" ref="my-modal" hide-footer :title="datas.modal_title">
        <AdmissionAprove :record="record"></AdmissionAprove>
    </b-modal>
  </div>
</template>

<script>
import AdmissionAprove from "../../views/Backend/Admission/AdmissionAprove.vue"
  export default {
    components:{
AdmissionAprove
    },
    props:{
      datas: {
      type: Object,
      default: () =>[],
      description: "Modal Setting data"
    },
      record: {
      type: Object,
      default: () =>[],
      description: "Current DB data "
    },
    },
    methods: {
      showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn')
      }
    }
  }
</script>
