<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <slot v-if="news.image">
            <div class="col-xs-12 col-lg-5">
              <img :src="news.FullUrl+news.image" class="img-fluid" alt="..." />
            </div>
            <div class="col-xs-12 col-lg-7" data-aos="fade-right">
              <!--Heading-->
              <h5 class="font-weight-bold text-uppercase">{{news.title}}</h5>
              <!--Text-->
              <p class="text-justify" v-html="news.description"></p>
            </div>
          </slot>
          <slot v-else>
            <div class="col-xs-12 col-lg-12" data-aos="fade-right">
              <h5 class="font-weight-bold text-uppercase">{{news.title}}</h5>
              <p class="text-justify" v-html="news.description"></p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "News"
  }
];
export default {
  name: "About",
  components: {
    Banner
  },
  data() {
    return {
      news: {},
      breadcrumbTitle: "",
      breadcumb: breadcumb
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/news_details/" + this.$route.params.slug)
        .then(respons => {
          this.$root.loading = false;
          this.news = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = [
            { title: "News", route_name: "news" },
            { title: this.breadcrumbTitle }
          ];
        })
        .catch(error => {
          console.log(error);
        })
        .then(response => {
          this.$root.loading = false;
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
