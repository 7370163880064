<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <h4 class="m-0 font-weight-bold">All Photo</h4>
            <hr />
          </div>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-lg-3" v-for="(image, index) in images" :key="index">
                <img v-lazy="image.src" class="img-fluid" @click="openGallery(index)" />
              </div>
              <LightBox
                :images="images"
                ref="lightbox"
                :show-caption="true"
                :show-light-box="false"
              ></LightBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LightBox from "vue-image-lightbox";
import Banner from "./pages/Banner.vue";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");
const breadcumb = [
  {
    title: "Photo Gallery"
  }
];
const routesname = {
  view: "photo_gallery"
};
export default {
  name: "MainApp",
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Photo Gallery",
      fields_name: { 0: "Select One", description: "Description" },
      search_data: {
        field_name: 0,
        value: ""
      },
      table: {
        title: "Simple Table",
        routes: routesname,
        datas: []
      },
      pagination: [],
      meta: [],
      links: [],
      images: []
    };
  },
  methods: {
    get_datas: function(pageindex) {
      this.$root.loading = true;
      let url = "/home/photo_details/" + this.$route.params.photo_gallery_id;
      if (pageindex > 0) {
        url =
          "/home/photo_details/" +
          this.$route.params.photo_gallery_id +
          "?page=" +
          pageindex;
      }
      axios
        .get(url)
        .then(respons => {
          this.images = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.$parent.debug_data = respons;
          this.$root.loading = false;
        })
        .catch(error => {
          alert(error);
          console.log(error);
        });
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    }
  },
  mounted() {
    this.get_datas();
  },
  components: {
    LightBox,
    Banner
  }
};
</script>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
