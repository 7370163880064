<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="content-body py-4">
      <div class="container">
        <div class="row" v-if="content">
          <slot v-if="content.image">
            <div class="col-xs-12 col-lg-5">
              <img
                :src="content.FullUrl + content.image"
                class="img-fluid"
                alt="..."
              />
            </div>
            <div class="col-xs-12 col-lg-7" data-aos="fade-right">
              <!--Heading-->
              <h5 class="font-weight-bold text-uppercase">
                {{ content.title }}
              </h5>
              <!--Text-->
              <p class="text-justify" v-html="content.description"></p>
            </div>
          </slot>
          <slot v-else>
            <div class="col-xs-12 col-lg-12" data-aos="fade-right">
              <h5 class="font-weight-bold text-uppercase">
                {{ content.title }}
              </h5>
              <p class="text-justify" v-html="content.description"></p>
            </div>
          </slot>
          <div
            class="col-xs-12 col-lg-12"
            data-aos="fade-right"
            v-if="Object.keys(content.content_file).length > 0"
          >
            <table class="table table-striped table-fixed table-bordered">
              <thead>
                <tr class="thead-dark">
                  <th>SL</th>
                  <th>Title</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(content_file, index) in content.content_file"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ content_file.title }}</td>
                  <td>
                    <a
                      :href="content_file.FullUrl + content_file.file"
                      target="_blank"
                      class="nav-link"
                    >
                      <span class="fab fa fa-download"></span> Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
export default {
  name: "About",
  components: {
    Banner,
  },
  data() {
    return {
      content: {
        content_file: [],
      },
      breadcrumbTitle: "",
      breadcumb: [],
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/content/" + this.$route.params.slug)
        .then((respons) => {
          this.content = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = respons.data.breadcumb;
          this.$root.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$root.loading = false;
        });
    },
  },
  mounted() {
    this.get_data();
  },
  created() {},
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true,
    },
  },
};
</script>
