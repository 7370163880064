
<template>
  <div id="Invoice">
    <!-- Invoice -->
    <div class="row">
      <div class="col-12 mb-3 text-center">
          <button class="btn btn-success btn-sm" flat @click="print('printArea')">
              <i class="fas fa-print"></i> Print
          </button>
      </div>
    </div>

    <div class="invoice" id="printArea">
        <table width="100%">
            <tr>
                <td style="text-align: left; padding: 0px; margin: 0px;">Invoice No: {{ result.transactionID }}</td>
                <td style="text-align: right; padding: 0px; margin: 0px;">Date: {{ result.account.date | formatDate("MMMM Do YYYY") }}</td>
            </tr>
            <tr>
                <td colspan="2" style="text-align: center; margin: 0px; padding: 0px;">
                    <img :src="$root.site.FullUrl + $root.site.image" width="75" alt="cesc_logo.png"/>
                    <h4 style="margin: 0px; padding: 0px;">{{ $root.site.title }}</h4>
                    <h5 style="margin: 0px; padding: 0px;">{{ $root.site.address }}</h5>
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <div style="margin: 5px; width: 100%;">
                        <div style="width: 210px;
    border: 2px solid #000000;
    border-radius: 5px;
    margin: auto;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;">
                            Student Payment Invoice
                        </div>
                    </div>
                </td>
            </tr>

            <tr>
                <td colspan="2">To:</td>
            </tr>
            <tr>
                <td colspan="2">
                    <table width="100%">
                        <tr>
                            <td colspan="3" style="text-align: left;">{{ result.student_info.name }}</td>
                        </tr>
                        <tr>
                            <th style="text-align: left;">Student ID</th>
                            <th width="2%">:</th>
                            <td style="text-align: left;">{{ result.student_info.studentid }}</td>
                        </tr>

                        <tr>
                            <th style="text-align: left;">Class</th>
                            <th width="2%">:</th>
                            <td style="text-align: left;">{{ result.student_info.stdclass.name }}</td>
                        </tr>

                        <tr>
                            <th style="text-align: left;">Section</th>
                            <th width="2%">:</th>
                            <td style="text-align: left;">{{ result.student_info.section.name }}</td>
                        </tr>

                        <tr>
                            <th style="text-align: left;">Roll</th>
                            <th width="2%">:</th>
                            <td style="text-align: left;">{{ result.student_info.roll }}</td>
                        </tr>
                    </table>
                </td>
            </tr>



            <tr>
                <td colspan="2">
                    <table width="100%" border="1">
                        <thead>
                            <tr>
                                <th style="text-align: center;">Sl. No</th>
                                <th style="text-align: center;">Month</th>
                                <th style="text-align: center;">Description</th>
                                <th style="text-align: center;">Status</th>
                                <th style="text-align: center;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in result.account.account_detail" :key="index">
                                <td style="text-align: center;">{{ index + 1 }}</td>
                                <td style="text-align: center;">{{ ($root.variable.monthsList)?$root.variable.monthsList[item.collected_month] : '' }} ({{ makeYear(item) }})</td>
                                <td style="text-align: left;">{{ ($root.variable.account_head_list)?$root.variable.account_head_list[item.account_head_id] : '' }}</td>
                                <td style="text-align: center;">{{ result.account.payment_status == 'Processing'? 'Unpaid' : result.account.payment_status }}</td>
                                <td style="text-align: right;">{{ ((result.account.payment_status == 'Paid')? item.due_amount : item.due_amount) | currency }}/=</td>
                            </tr>
                            <tr>
                                <td colspan="4" style="text-align: center;">Total = ({{ result.total | inWords }})</td>
                                <td style="text-align: right;">{{ result.total | currency }}/=</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>

            <tr>
                <td colspan="2" style="text-align: left;">
                    <p style="padding: 0px; margin: 0px; margin-top: 50px;">Thank You</p>
                    <p style="padding: 0px; margin: 0px;">CESC</p>
                </td>
            </tr>
        </table>
    </div>

    <slot
      v-if="
        site_data.payment_on_off &
        (result.account.payment_status == 'Processing')
      "
    >
      <div class="col-12">
        <div class="payment-option">
          <div class="form-check">
            <input
              class="form-check-input"
              v-model="paymentgateway"
              type="radio"
              name="paymentgateway"
              id="tbl"
              value="tbl"
            />
            <label class="form-check-label" for="tbl">Trust Bank Ltd</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              v-model="paymentgateway"
              type="radio"
              name="paymentgateway"
              id="dbbl"
              value="dbbl"
            />
            <label class="form-check-label" for="dbbl"
              >Dutch Bangla Bank Ltd.</label
            >
          </div>
        </div>
      </div>
      <slot v-if="paymentgateway == 'tbl'">
        <form
          id="tblWebPayment"
          class="text-center mt-3"
          method="post"
          target="__blank"
          :action="$root.variable.tblweb.api_url"
        >
          <input type="hidden" name="OrderID" :value="result.transactionID" />
          <input type="hidden" name="Amount" :value="result.total" />
          <input
            type="hidden"
            name="FullName"
            :value="result.student_info.studentid"
          />
          <input
            type="hidden"
            name="Email"
            :value="result.student_info.email"
          />
          <input
            type="hidden"
            name="MerchantID"
            :value="$root.variable.tblweb.MerchantID"
          />
          <input
            type="hidden"
            name="PaymentSuccessUrl"
            :value="$root.variable.tblweb.success_url"
          />

          <button v-if="ps != 'Paid'" class="btn btn-success" type="submit">
            Pay Now
          </button>

        </form>
      </slot>
      <slot v-if="paymentgateway == 'dbbl'">
        <div class="col-12">
          <div class="payment-option custom-box">
            <div
              class="form-check form-check-inline"
              v-for="(card_type, index) in $root.variable.dbbl_options"
              :key="index"
            >
              <label class="form-check-label" style="font-size: 10px">
                <input
                  class="form-check-input"
                  v-model="dbbl.cardtype"
                  type="radio"
                  name="dbbl_card_type"
                  id="dbbl_card_type"
                  :value="index"
                />

                <img
                  :src="$root.baseurl + '/images/' + card_type.image"
                  style="width: 40px; height: 25px"
                />
                <br />
                {{ card_type.name }}
              </label>
            </div>
            <br />
            <button
              v-if="dbbl.cardtype"
              class="btn btn-success mt-3"
              type="Button"
              @click="
                paywith_dbbl(
                  result.student_info.studentid,
                  result.total,
                  result.account.id
                )
              "
            >
              Pay Now
            </button>
          </div>
        </div>
      </slot>
      <!-- /Invoice -->
    </slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ps: "Processing",
      paymentgateway: null,
      result: {
        transactionID: null,
        student_info: {stdclass: {}, section: {},},
        account: {account_detail: [],}, amount: 0,
      },
      dbbl: {amount: null, cardtype: null, txnrefnum: null,},

      site_data: {payment_on_off: false,},
    };
  },
  created() {
    this.$root.loading = true;
    let voucher_no = this.$route.params.voucher_no;
    axios.get("student/invoiceDetails/" + voucher_no)
      .then((res) => {
        this.result = res.data;
        this.updatePaymentStatus(res.data.account.account_detail);
      }).then(() => this.$root.loading = false);
  },
  methods: {
    paywith_dbbl: function (studentid, amount, account_id) {
      this.$root.loading = true;
      this.dbbl.amount = amount;
      this.dbbl.txnrefnum = studentid;
      this.dbbl.account_id = account_id;

      axios
        .post("student/dbblcheckout", this.dbbl)
        .then((res) => {
          if (res.data.is_true) {
            window.location.href = res.data.url;
          } else {
            this.$toaster.error("Sorry! Something Wrong");
          }
        }).then(() => this.$root.loading = false);
    },
    updatePaymentStatus: function (accountDetail) {
      let status = "Processing";
      accountDetail.every((ele) => {
        if (ele.status == "Paid") {
          status = ele.status;
        }
        return true;
      });
      this.ps = status;
    },
    dataSaveToTblHistory: function () {
      var form = document.getElementById("tblWebPayment");
      var formData = new FormData(form);
      axios
        .post("dataSaveToTblHistory", formData)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            if (res.data.success == 1) {
              document.getElementById("tblWebPayment").submit();
            }
          }
        });
    },
    get_data() {
      axios
        .get("/all_layout_data/topHead")
        .then((res) => {
          this.site_data = res.data.site_data;
        });
    },
    makeYear(item){
          let year      = item.academic_year;
          let month     = item.collected_month;
          let classType = item.student_invoice? (item.student_invoice.stdclass? item.student_invoice.stdclass.type : 'School') : 'School';

          if(month <= 6 && classType == 'College'){
              return Number(year) + 1;
          }else {
              return year;
          }
      },
  },
  mounted() {
    this.get_data();
  },
};
</script>
<style>
.payment-option {
  width: 700px;
  margin: auto;
  padding: 30px;
}
.custom-box > div {
  width: 25%;
  margin-right: 0;
  margin-bottom: 20px;
}
</style>
