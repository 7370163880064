<template>
  <div class="top-head">
    <div class="social-menu bg-theme">
      <div class="container">
        <ul class="nav justify-content-end">
          <li class="nav-item">
            <a class="nav-link">
              <span class="fas fa-phone fa-rotate-90"></span>
              {{$root.site.phone}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="fas fa-envelope"></span>
              {{$root.site.email}}
            </a>
          </li>
          <li class="nav-item" v-for="(item, index) in $root.socialLink" :key="index">
            <a
              class="nav-link"
              target="_blank"
              :href=" item.url "
              data-toggle="tooltip"
              data-placement="bottom"
              title="Facebook"
            >
              <span :class=" item.icon "></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHead"
};
</script>
