<template>
  <div id="payment">
    <div class="row align-items-center mb-3">
      <div class="d-flex justify-content-center mb-3">
    <div class="p-2">



      <form v-on:submit.prevent="search" class="form-row px-3">
              <div class="form-group mb-0 p-0">
                <input
                  type="text"
                  v-model="search_data.value"
                  class="form-control form-control-sm rounded-0"
                  placeholder="Type your text"
                />
              </div>
              <div class="form-group mb-0">
                <button type="submit" class="btn btn-sm btn-primary rounded-0">
                  <span class="fa fa-search form-control-feedback"></span> Search
                </button>
              </div>
            </form>



    </div>
    <div class="p-2">Online Class: {{subject.name}}</div>
    <div class="p-2">
      <router-link class="nav-link btn btn-primary" :to="{name:'student.onlineClass'}">Back to Online Class</router-link>
    </div>
  </div>
    </div>
    <div class="row" v-if="datas.length>0">
          <div class="col-md-3" v-for="(data, index) in datas" :key="index"> 
            
              <div class="img-m-20">
                
                   <div v-if="data.type == 'file'">
                     <a  :href="'#videoModal' + data.id" data-toggle="modal">
                     <img class="img-150" :src="$root.baseurl+data.image" v-bind:alt="data.image" >
                   </a>
                     <div class="modal fade" :id="'videoModal' + data.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">

                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div class="modal-body">
                             <video controls>
  <source :src="$root.baseurl+data.file" type="video/mp4">
Your browser does not support the video tag.
</video> 
                        </div>
                        </div>
                    </div>
                  </div>
                   </div>
                   <div v-else>
                     <a  :href="'#teamModal' + data.id" data-toggle="modal">
                   
                   <img class="img-150" :src="'https://img.youtube.com/vi/' + data.url + '/0.jpg'" v-bind:alt="data.url" >
                   </a>
                   <div class="modal fade" :id="'teamModal' + data.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">

                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div class="modal-body">
                            <b-embed type="iframe" aspect="16by9" :src="'https://www.youtube.com/embed/' + data.url + '?rel=0'"></b-embed>
                        </div>
                        </div>
                    </div>
                  </div>
                   </div>
                   
                </div>
                <p class="f-10">{{data.title}}</p>
                <a v-if="data.type == 'file'" :href="$root.baseurl+data.file" target="_blank" download class="blank">
                     <i class="fas fa-download"></i>
                   </a>
          </div>
          <div class="col-md-12 page-footer" v-if="links.last_page>1">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-5 p-0">
            <div
              role="status"
              aria-live="polite"
            >Showing {{links.from}} to {{links.to}} of {{links.total}} entries</div>
          </div>
          <div class="col-sm-12 col-md-7 p-0">
            <nav aria-label="Page navigation example">
              <ul class="pagination pagination-sm justify-slider_content-end">
                <li class="page-item">
                  <a class="page-link" v-on:click="get_datas(1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-if="links.current_page>2">
                  <a
                    class="page-link"
                    v-on:click="get_datas(links.current_page-2)"
                  >{{links.current_page-2}}</a>
                </li>
                <li class="page-item" v-if="links.current_page>1">
                  <a
                    class="page-link"
                    v-on:click="get_datas(links.current_page-1)"
                  >{{links.current_page-1}}</a>
                </li>
                <li class="page-item active">
                  <a
                    class="page-link"
                    v-on:click="get_datas(links.current_page)"
                  >{{links.current_page}}</a>
                </li>
                <li class="page-item" v-if="links.current_page<links.last_page">
                  <a
                    class="page-link"
                    v-on:click="get_datas(links.current_page+1)"
                  >{{links.current_page+1}}</a>
                </li>
                <li class="page-item" v-if="links.current_page+1<links.last_page">
                  <a
                    class="page-link"
                    v-on:click="get_datas(links.current_page+2)"
                  >{{links.current_page+2}}</a>
                </li>
                <li class="page-item">
                  <a
                    class="page-link"
                    v-on:click="get_datas(links.last_page)"
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-else class="card card-body bg-ligh text-center">No Online Class Found</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datas: [],
      search_data: {
        value: "",
      },
      count: 0,
      links: [],
      subject: [],
      errors: []
    };
  },
  methods: {
    get_datas: function(pageindex) {
      let subject_id = this.$route.params.subject_id;
      let url = "student/online-class/"+subject_id;
      if (pageindex > 0) {
        url = "student/online-class/"+subject_id+"?page=" + pageindex;
      };
      axios
        .get(url, { params: this.search_data })
        .then(response => {
          this.datas = response.data.video.data;
          this.links = response.data.video;
          this.subject = response.data.subject_name;
        })
        .catch(error => {})
        .then(always => {
          this.$root.loading = false;
        });
    },
    search() {
      this.get_datas();
    }
  },
  created() {
      this.get_datas();
  },
};
</script>
<style>
  a{
    color:#0b2456;
  }
.img-150{
  width:100%;
  height: 138px;
object-fit: cover;
object-position: center;
}
.img-m-20{
  margin-bottom:20px;
  height: 138px;
background:
#ccc;
text-align: center;
}
.blank{
  display: block;
}

.f-10{
  line-height: 18px;
text-align: center;
}

.blank img {
    height: 138px;
    object-fit: cover;
    object-position: center;
}

.fas.fa-download {
    position: absolute;
    top: 99px;
    right: 15px;
    padding: 10px;
    background: #167d74;
    color: #fff;
}

video {
  width:100%;
}

.modal-xl {
    max-width: 45%;
}

</style>