<template>
  <div class="card">
    <div class="card-block">
      <div class="row">
        <div class="col-md-3 ml-auto text-right">
          <div class="printer__download">
            <a href="javascript:void(0);"
              ><i class="feather icon icon-download"></i
            ></a>
            <a href="javascript:void(0);"
              ><i class="feather icon icon-printer"></i
            ></a>
            <a href="javascript:void(0);"
              ><i class="feather icon icon-file-text"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-md-11 m-auto">
        <div class="card student__profile mt-3">
          <div class="card-header py-2">
            <div class="row">
              <div class="col-md-5">
                <h2 class="text-success">{{ profileData.name }}</h2>
                <strong
                  ><i>Student Id: {{ profileData.studentid }}</i></strong
                ><br />
                <strong
                  v-if="profileData.guide_teacher"
                  class="border p-1 h6 text-muted"
                  >Guide Teacher: {{ profileData.guide_teacher.name }}</strong
                ><br />
                <strong
                  v-if="profileData.guide_teacher"
                  class="border p-1 h6 text-muted"
                  >Designation:
                  {{ profileData.guide_teacher.designation }}</strong
                ><br />
                <strong
                  v-if="profileData.guide_teacher"
                  class="border p-1 h6 text-muted"
                  >Email: {{ profileData.guide_teacher.email }}</strong
                ><br />
                <strong
                  v-if="profileData.guide_teacher"
                  class="border p-1 h6 text-muted"
                  >Mobile: {{ profileData.guide_teacher.mobile_no }}</strong
                >
              </div>
              <div class="col-md-5 text-right school-info">
                <h4>Cantonment English <br />School & College</h4>
                <small>{{ $root.site.address }}</small
                ><br />
                <small>Tel:{{ $root.site.phone }}</small>
              </div>
              <div class="col-md-2">
                <img
                  :src="$root.site.FullUrl + $root.site.image"
                  alt="..."
                  class="collage"
                />
              </div>
            </div>
          </div>

          <form
            action=""
            id="studentProfile"
            enctype="multipart/form-data"
            @submit.prevent="submitHandler"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <input type="hidden" name="id" :value="profileData.id" />
                  <div class="profile__image">
                    <input
                      type="file"
                      name="profile_image"
                      id="profile-image"
                      accept="image/*"
                      @change="imageChangeHandler($event)"
                      class="d-none"
                    />
                    <label for="profile-image">
                      <img
                        v-if="profileData.image"
                        :src="$root.baseurl + profileData.image"
                      />
                      <img v-else class="img-fluid" :src="image_src" alt />
                      <div
                        class="image-brows-btn"
                        v-if="isEditMode && uploadImage"
                      >
                        <i class="fa fa-camera"></i>
                        Change profile image
                      </div>
                    </label>
                    <div>
                      <span>Profile Edit</span>
                      <span
                        class="btn btn-info"
                        :class="
                          isEditMode
                            ? 'profile-edit-disable '
                            : 'profile-edit-enable'
                        "
                        @click="isEditMode = !isEditMode"
                      >
                        <i
                          class="fa"
                          :class="isEditMode ? 'fa-times' : 'fa-check'"
                        ></i>
                        {{ isEditMode ? "Disable" : "Enable" }}
                      </span>
                      <p v-if="isEditMode">
                        <mark>
                          <strong>Note: </strong>Your recent photograph in CESC
                          uniform with tie, clearly visible face, properly
                          arranged hair & white background are Mandatory for
                          CESC and Image must Jpg or Jpeg or Png Format.
                        </mark>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <h3
                    v-if="profileData.academic_year"
                    class="text-success border-bottom border-top text-center"
                  >
                    Admitted Date : {{ profileData.admitted_date }}
                  </h3>
                  <div class="row">
                    <div class="col-md-6">
                      <small class="text__label">Group:</small>
                      <h5 class="title" v-if="profileData.group">
                        {{ profileData.group }}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Category:</small>
                      <h5 class="title" v-if="profileData.category">
                        {{ profileData.category }}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Class:</small>
                      <h5 v-if="profileData.stdclass" class="title">
                        {{ profileData.stdclass.name }}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Section:</small>
                      <h5 v-if="profileData.section" class="title">
                        {{ profileData.section.name }}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Academic Year:</small>
                      <h5 v-if="profileData.academic_year" class="title">
                        {{ profileData.academic_year }}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Gender:</small>
                      <h5
                        v-if="profileData.gender && !isEditMode"
                        class="title"
                      >
                        {{ profileData.gender }}
                      </h5>
                      <select
                        v-if="isEditMode"
                        name="gender"
                        required
                        id=""
                        class="edit-box"
                        v-model="profileData.gender"
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Religion:</small>
                      <h5
                        v-if="profileData.religion && !isEditMode"
                        class="title"
                      >
                        {{ profileData.religion }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="religion"
                        required
                        v-model="profileData.religion"
                        v-if="isEditMode"
                      />
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Blood Group:</small>
                      <h5 v-if="profileData.blood && !isEditMode" class="title">
                        {{ profileData.blood }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="blood"
                        v-model="profileData.blood"
                        v-if="isEditMode"
                      />
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Birthday:</small>
                      <h5
                        v-if="profileData.date_of_birth && !isEditMode"
                        class="title"
                      >
                        {{ profileData.date_of_birth }}
                      </h5>
                      <input
                        type="date"
                        class="edit-box"
                        name="date_of_birth"
                        required
                        v-model="profileData.date_of_birth"
                        v-if="isEditMode"
                      />
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">House:</small>
                      <h5 v-if="profileData.house" class="title">
                        {{ profileData.house.name }}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Present address:</small>
                      <h5
                        v-if="profileData.present_address && !isEditMode"
                        class="title"
                      >
                        {{ profileData.present_address }}
                      </h5>
                      <textarea
                        cols="30"
                        required
                        name="present_address"
                        rows="8"
                        class="edit-box"
                        v-model="profileData.present_address"
                        v-if="isEditMode"
                      ></textarea>
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Permanent address:</small>
                      <h5
                        v-if="profileData.permanent_address && !isEditMode"
                        class="title"
                      >
                        {{ profileData.permanent_address }}
                      </h5>
                      <textarea
                        cols="30"
                        required
                        name="permanent_address"
                        rows="8"
                        class="edit-box"
                        v-model="profileData.permanent_address"
                        v-if="isEditMode"
                      ></textarea>
                    </div>

                    <div
                      class="col-md-6"
                      :class="{
                        'has-error': validation.hasError('profileData.mobile'),
                        'has-success': profileData.mobile,
                      }"
                    >
                      <small class="text__label control-label">Phone:</small>
                      <h5
                        v-if="profileData.mobile && !isEditMode"
                        class="title"
                      >
                        {{ profileData.mobile }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="mobile"
                        v-model="profileData.mobile"
                        v-if="isEditMode"
                      />
                      <span class="help-block" v-if="isEditMode">
                        {{ validation.firstError("profileData.mobile") }}
                      </span>
                    </div>

                    <div class="col-md-6">
                      <small class="text__label">Email:</small>
                      <h5 v-if="profileData.email && !isEditMode" class="title">
                        {{ profileData.email }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="email"
                        v-model="profileData.email"
                        v-if="isEditMode"
                      />
                    </div>

                    <div class="col-md-6">
                      <small class="text__label">Nationality:</small>
                      <h5
                        v-if="profileData.nationality && !isEditMode"
                        class="title"
                      >
                        {{ profileData.nationality }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="nationality"
                        v-model="profileData.nationality"
                        v-if="isEditMode"
                      />
                    </div>

                    <div class="col-md-6">
                      <small class="text__label">Previous School:</small>
                      <h5
                        v-if="profileData.previous_school && !isEditMode"
                        class="title"
                      >
                        {{ profileData.previous_school }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="previous_school"
                        v-model="profileData.previous_school"
                        v-if="isEditMode"
                      />
                    </div>

                    <div class="col-md-6">
                      <small class="text__label"
                        >Specific Health Problem?:</small
                      >
                      <h5
                        v-if="profileData.helth_problem && !isEditMode"
                        class="title"
                      >
                        {{ profileData.helth_problem }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="helth_problem"
                        v-model="profileData.helth_problem"
                        v-if="isEditMode"
                      />
                    </div>

                    <div class="col-md-6">
                      <small class="text__label">Identification Mark:</small>
                      <h5
                        v-if="profileData.identification_mark && !isEditMode"
                        class="title"
                      >
                        {{ profileData.identification_mark }}
                      </h5>
                      <input
                        type="text"
                        class="edit-box"
                        name="identification_mark"
                        v-model="profileData.identification_mark"
                        v-if="isEditMode"
                      />
                    </div>

                    <br /><br />
                    <div class="col-md-12">
                      <h4>Transport info</h4>
                      <hr />
                    </div>
                    <div class="col-md-6">
                      <small class="text__label">Bus name:</small>
                      <h5 class="title">{{ data.transport.title }}</h5>
                    </div>

                    <div class="col-md-6">
                      <small class="text__label">Route:</small>
                      <h5 class="title">{{ data.transport.location }}</h5>
                    </div>

                    <div class="col-md-6">
                      <small class="text__label">Bus Model:</small>
                      <h5 class="title">{{ data.transport.model }}</h5>
                    </div>

                    <div class="col-md-6">
                      <small class="text__label">Time to leave the bus:</small>
                      <h5 class="title">{{ data.transport.bus_time }}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h4 class="py-0 border-bottom">Parents</h4>
                </div>
              </div>

              <slot
                v-if="
                  profileData.guardians &&
                  Object.keys(profileData.guardians).length > 0
                "
              >
                <div
                  class="row"
                  v-for="(guardian, index) in profileData.guardians"
                  :key="index"
                >
                  <div class="col-md-4 text-center">
                    <h3 v-if="guardian.relation">My {{ guardian.relation }}</h3>

                    <slot v-if="guardian.relation === 'Mother'">
                      <div style="width: 200px">
                        <b-form-file
                          v-if="isEditMode"
                          id="mother-image"
                          accept="image/*"
                          @change="
                            imageGuardianChangeHandler($event, 'mother', index)
                          "
                          v-model="mother_image"
                          :state="Boolean(mother_image)"
                          size="sm"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                        <label>
                          <img
                            width="200"
                            class="img-fluid"
                            :src="
                              guardian.image ? guardian.image : mother_image_src
                            "
                            alt=""
                          />
                        </label>
                      </div>
                    </slot>

                    <slot v-if="guardian.relation === 'Father'">
                      <div style="width: 200px">
                        <b-form-file
                          v-if="isEditMode"
                          id="father-image"
                          accept="image/*"
                          @change="
                            imageGuardianChangeHandler($event, 'father', index)
                          "
                          v-model="father_image"
                          :state="Boolean(father_image)"
                          size="sm"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                        <label>
                          <img
                            width="200"
                            class="img-fluid"
                            :src="
                              guardian.image ? guardian.image : father_image_src
                            "
                            alt=""
                          />
                        </label>
                      </div>
                    </slot>

                    <p
                      v-if="guardian.relation === 'Mother'"
                      class="text-capitalize mt-3 text-warning"
                    >
                      Authorized to pick up <br />
                      emergency contact
                    </p>
                  </div>

                  <div class="col-md-8">
                    <div class="row">
                      <input
                        type="hidden"
                        :name="'guardian[' + index + '][guardian_id]'"
                        :value="guardian.id"
                      />
                      <input
                        type="hidden"
                        :name="'guardian[' + index + '][student_id]'"
                        :value="profileData.id"
                      />
                      <input
                        type="hidden"
                        :name="'guardian[' + index + '][relation]'"
                        :value="guardian.relation"
                      />
                      <div class="col-md-6">
                        <small class="text__label">Name:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.name }}
                        </h5>
                        <input
                          type="text"
                          required
                          :name="'guardian[' + index + '][name]'"
                          class="edit-box"
                          v-model="guardian.name"
                          v-if="isEditMode"
                        />
                      </div>
                      <div class="col-md-6">
                        <small class="text__label">Blood Group:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.blood_group }}
                        </h5>
                        <input
                          type="text"
                          required
                          :name="'guardian[' + index + '][blood_group]'"
                          class="edit-box"
                          v-model="guardian.blood_group"
                          v-if="isEditMode"
                        />
                      </div>
                      <div class="col-md-6">
                        <small class="text__label">Occupation:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.occupation }}
                        </h5>
                        <input
                          type="text"
                          required
                          class="edit-box"
                          :name="'guardian[' + index + '][occupation]'"
                          v-model="guardian.occupation"
                          v-if="isEditMode"
                        />
                      </div>

                        <div class="col-md-6">
                            <small class="text__label">Unit:</small>
                            <h5 v-if="!isEditMode" class="title">
                                {{ guardian.unit }}
                            </h5>
                            <input
                                type="text"
                                required
                                class="edit-box"
                                :name="'guardian[' + index + '][unit]'"
                                v-model="guardian.unit"
                                v-if="isEditMode"
                            />
                        </div>

                        <div class="col-md-6">
                            <small class="text__label">BA/CS Number:</small>
                            <h5 v-if="!isEditMode" class="title">
                                {{ guardian.ba_or_cs_number }}
                            </h5>
                            <input
                                type="text"
                                required
                                class="edit-box"
                                :name="'guardian[' + index + '][ba_or_cs_number]'"
                                v-model="guardian.ba_or_cs_number"
                                v-if="isEditMode"
                            />
                        </div>

                      <div class="col-md-6">
                        <small class="text__label">Designation:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.designation }}
                        </h5>
                        <input
                          type="text"
                          required
                          class="edit-box"
                          :name="'guardian[' + index + '][designation]'"
                          v-model="guardian.designation"
                          v-if="isEditMode"
                        />
                      </div>
                      <div class="col-md-6" v-if="guardian.designation != null">
                        <small class="text__label">Organization:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.organization }}
                        </h5>
                        <input
                          type="text"
                          required
                          class="edit-box"
                          :name="'guardian[' + index + '][organization]'"
                          v-model="guardian.organization"
                          v-if="isEditMode"
                        />
                      </div>
                      <div class="col-md-6">
                        <small class="text__label">Phone:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.mobile }}
                        </h5>
                        <input
                          type="text"
                          required
                          class="edit-box"
                          :name="'guardian[' + index + '][mobile]'"
                          v-model="guardian.mobile"
                          v-if="isEditMode"
                        />
                      </div>
                      <div class="col-md-6">
                        <small class="text__label">Email:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.email }}
                        </h5>
                        <input
                          type="text"
                          class="edit-box"
                          :name="'guardian[' + index + '][email]'"
                          v-model="guardian.email"
                          v-if="isEditMode"
                        />
                      </div>
                      <div class="col-md-6">
                        <small class="text__label">Address:</small>
                        <h5 v-if="!isEditMode" class="title">
                          {{ guardian.location }}
                        </h5>
                        <input
                          type="text"
                          class="edit-box"
                          :name="'guardian[' + index + '][location]'"
                          v-model="guardian.location"
                          v-if="isEditMode"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </slot>
            </div>

            <div class="card-footer">
              <div
                v-for="(error, i) in errors"
                :key="i"
                class="alert alert-warning"
                style="color: red"
              >
                <a
                  href="#"
                  class="close"
                  data-dismiss="alert"
                  aria-label="close"
                  title="close"
                  >×</a
                >
                <strong>Required!</strong>
                {{ error[0] }}
              </div>
              <button type="submit" v-if="isEditMode" class="btn btn-info">
                Changes Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image from "../../../assets/img/avatar.png";

export default {
  name: "StudentProfile",
  data() {
    return {
      isEditMode: false,
      mother_image_src: "/images/mother.png",
      father_image_src: "/images/father.png",
      image_src: image,
      uploadImage: true,
      data: {
        transport: {
          title: "",
          location: "",
          model: "",
          bus_time: "",
        },
      },
      mother_image: null,
      father_image: null,
      profileImage: null,
      profileData: {},
      errors: {},
    };
  },

  methods: {
    getTransportInfo() {
      this.$root.loading = true;
      axios
        .get("student/transportInfo")
        .then((response) => {
          this.data.transport = response.data;
          // this.$root.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    imageChangeHandler(e) {
      const file = e.target.files[0];
      if (file) {
        this.uploadImage = false;
        this.profileImage = file;
        this.profileData.image = null;
        this.image_src = URL.createObjectURL(file);
      }
    },

    imageGuardianChangeHandler(e, type, index) {
      const file = e.target.files[0];
      if (file) {
        this.profileData.guardians[index].image = null;
        this[type + "_image_src"] = URL.createObjectURL(file);
      }
    },

    submitHandler() {
      if (this.isEditMode && this.validationData()) {
        this.$validate().then((res) => {
          const error = this.validation.countErrors();
          if (error > 0) {
            this.$toaster.error(
              "You need to fill " + error + " more empty mandatory fields"
            );
            return false;
          }
          this.$root.loading = true;
          let form = document.getElementById("studentProfile");
          let formData = new FormData(form);
          if (this.profileImage) {
            formData.append("image", this.profileImage);
          }
          if (this.mother_image) {
            formData.append("mother_image", this.mother_image);
          }
          if (this.father_image) {
            formData.append("father_image", this.father_image);
          }

          formData.append("studentid", this.$parent.data.studentid);
          formData.append("std_class_id", this.$parent.data.std_class_id);
          formData.append("section_id", this.$parent.data.section_id);
          formData.append("name", this.$parent.data.name);

          axios
            .post("student/update-profile", formData)
            .then((res) => {
              if (res.data.status == 200) {
                this.isEditMode = false;
                this.$toaster.success(res.data.message);
              }
              this.$root.loading = false;
            })
            .catch((error) => {
              this.$root.loading = false;
              if (error.response.status) {
                this.errors = error.response.data.errors;
              }
            });
        });
      } else {
        this.$toaster.error("Some field is required!");
        return false;
      }
    },

    validationData() {
      let status = true;
      if (!this.profileImage && !this.profileData.image) {
        this.$toaster.error("Profile Image is required!");
        status = false;
      }

      this.profileData.guardians.every((guardian) => {
        if (guardian.relation == "Father") {
          if (!this.father_image && !guardian.image) {
            this.$toaster.error("Father Image is required!");
            status = false;
          }
        } else if (guardian.relation == "Mother") {
          if (!this.mother_image && !guardian.image) {
            this.$toaster.error("Mother Image is required!");
            status = false;
          }
        }

        return true;
      });

      return status;
    },
  },

  mounted() {
    this.getTransportInfo();
    setTimeout(() => {
      this.profileData = this.$parent.data;
      this.$root.loading = false;
    }, 1000);
  },
  beforeCreate() {
    this.$root.loading = true;
  },
  validators: {
    "profileData.mobile": function (value = null) {
      return Validator.value(value)
        .required("Phone is required")
        .digit()
        .minLength(11)
        .maxLength(11);
    },
  },
};
</script>

<style scoped lang="scss">
.profile__image {
  position: relative;
}

.profile__image_gua {
  background: #65dcdb;
  height: 10em;
  width: 14em;
}
.image-brows-btn {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e2dedad9;
  border: 1px dashed #4444;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.edit-box {
  display: block;
  height: 37px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #4444;
  padding: 11px 7px;
  width: 100%;
  margin-bottom: 4px;
}
textarea.edit-box {
  height: 54px !important;
}
.btn.btn-info.profile-edit-enable {
  background: #4bc5aebf;
  padding: 1px 5px;
  border: 2px solid #33bcad;
  color: #000;
  font-size: 13px;
  text-align: center;
  margin: 10px auto;
  cursor: pointer;
}
.btn.btn-info.profile-edit-disable {
  background: #ff9a9abf;
  padding: 1px 5px;
  border: 2px solid #f24a4a;
  color: #000;
  font-size: 13px;
  text-align: center;
  margin: 10px auto;
  cursor: pointer;
}
.help-block {
  color: red;
  font-size: 12px;
  margin-top: 0px;
}
.has-error input,
.has-error select,
.has-error textarea {
  border: 1px solid red;
}
.has-success input,
.has-success select,
.has-success textarea {
  border: 1px solid #18a903;
}
.control-label:after {
  content: " *";
  color: red;
}
</style>
