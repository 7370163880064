<template>
  <div id="payment">
    <div class="row align-items-center">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto">
        Due History
      </h5>
    </div>

    <div class="row" v-if="Object.keys(data).length > 0">
      <div class="col-12" v-if="Object.keys(data.data)">
        <table
          class="table table-striped table-fixed table-bordered"
          v-if="Object.keys(data.data).length > 0"
        >
          <thead>
            <tr>
              <th>Month</th>
              <th>Account Head</th>
              <th>Amount</th>
              <th>Waiver</th>
              <th>Adjust</th>
              <th>Paid Amount</th>
              <th>Due Amount</th>
            </tr>
          </thead>
          <tbody>
            <slot v-for="(obj, index) in data.data.details">
              <tr v-if="obj.due_amount > 0" :key="index">
                <td>{{ obj.month }} ({{ makeYear(obj) }})</td>
                <td>
                  {{ $root.variable.account_head_list[obj.account_head_id] }}
                </td>
                <td>
                  {{ obj.amount | currency }}
                </td>
                  <td>
                      {{ obj.weiber | currency }}
                  </td>
                  <td>
                      {{ obj.adjust | currency }}
                  </td>
                <td>
                  {{ obj.paid_amount | currency }}
                </td>
                <td>
                  {{ obj.due_amount | currency }}
                </td>
              </tr>
            </slot>
            <tr>
              <th colspan="6">Total</th>
              <th>
                {{ data.data.totaldue | currency }}
              </th>
            </tr>
          </tbody>
        </table>
        <div v-else class="card card-body bg-ligh text-center">
          No Data Found.
        </div>
      </div>
    </div>
    <div class="row" v-if="Object.keys(data).length > 0">
      <div class="col-12" v-if="Object.keys(data.data)">
        <button
          v-if="data.data.totaldue > 0"
          @click="submit"
          class="btn btn-sm btn-primary float-right pl-20"
        >
          Checkout
        </button>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {
        data: {
          totaldue: 0,
          details: [],
        },
      },
      errors: [],
      // returndata: []
    };
  },
  created() {
    axios
      .get("student/dueHistory")
      .then((response) => {
        this.data = response.data;
      })
      .catch((errer) => {})
      .then((always) => {
        this.$root.loading = false;
      });
  },
  methods: {
    submit: function () {
      this.$root.loading = true;
      let params = this.data;
      axios
        .post("student/addPayment", params)
        .then((reponse) => {
          let url = "/student/invoiceDetails/" + reponse.data.voucher_no;
          this.$router.push(url);
        })
        .catch((errer) => {})
        .then((always) => {
          this.$root.loading = false;
        });
    },

      makeYear(item){
          let classType = item.stdclass? item.stdclass.type : 'School';
          let year      = item.academic_year;
          let month     = item.collected_month;

          if(month <= 6 && classType == 'College'){
              return Number(year) + 1;
          }else {
              return year;
          }
      },
  },
};
</script>
