<template>
  <div class="newsticker" id="scroll-down" v-if="!$root.loading">
    <div class="marquee-body">
      <ul class="marquee">
        <li
          v-for="(item, index) in notices"
          :key="index"
          style="white-space: nowrap"
        >
          <router-link
            class="text-white"
            v-if="item.type === 'content'"
            :to="{
              name: 'notice.detail',
              params: { category: item.category, slug: item.slug },
            }"
            >{{ item.title }}</router-link
          >
          <a
            class="text-white"
            v-else
            :href="item.FullUrl + item.file"
            target="_blank"
            >{{ item.title }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "newsticker",
  data() {
    return {
      notices: {},
      NoticeImage: require("./../../../assets/images/photo-4.jpg"),
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/scroll")
        .then((respons) => {
          this.notices = respons.data.notices;
          this.$root.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
<style scoped>
.newsticker {
  background: #1b859d;
}
.marquee-body {
  max-width: 95%;
  overflow: hidden;
  margin: 0 auto;
}
.marquee {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  animation: scroll 20s linear infinite;
  padding: 3px;
  display: flex;
  align-items: center;
}
.marquee:hover {
  animation-play-state: paused;
}
.marquee li {
  padding: 1rem;
  color: #fff;
}
/* Make it move */
@keyframes scroll {
  0% {
    left: 800px;
  }
  100% {
    left: -800px;
  }
}
</style>
