<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <h5 class="font-weight-bold text-uppercase" style="margin: auto">
            {{ carrer.title }}
          </h5>
          <div class="col-lg-12"></div>
          <span class="date" style="margin: auto">
            Application Deadline :
            <strong>{{ carrer.deadline }}</strong>
          </span>
        </div>
        <div class="job-preview">
          <div class="alert alert-success" v-if="savingSuccessful">
            <a
              href="#"
              class="close"
              data-dismiss="alert"
              aria-label="close"
              title="close"
              >×</a
            >
            {{ text }}
          </div>
          <form
            ref="form"
            v-on:submit.prevent="submit"
            class="card-body"
            id="form"
          >
            <input type="hidden" v-model="data.carrer_id" name="carrer_id" />
            <div class="controls">
              <div class="form-group">
                <label for class="col-form-label-sm m-0">Name</label>
                <input
                  type="text"
                  name="name"
                  v-model="data.name"
                  id="name"
                  placeholder="Enter your  Name"
                  required="required"
                  class="form-control form-control-sm shadow-none"
                />
                <small v-if="errors && errors.name" class="text-danger">{{
                  errors.name[0]
                }}</small>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for class="col-form-label-sm m-0">Email</label>
                    <input
                      type="email"
                      name="email"
                      v-model="data.email"
                      id="email"
                      placeholder="Enter your Email"
                      required="required"
                      class="form-control form-control-sm shadow-none"
                    />
                    <small v-if="errors && errors.email" class="text-danger">{{
                      errors.email[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for class="col-form-label-sm m-0">Mobile</label>
                    <input
                      type="text"
                      name="mobile"
                      v-model="data.mobile"
                      id="mobile"
                      placeholder="Enter your  Mobile"
                      required="required"
                      class="form-control form-control-sm shadow-none"
                    />
                    <small v-if="errors && errors.mobile" class="text-danger">{{
                      errors.mobile[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for class="col-form-label-sm m-0">Address</label>
                <textarea
                  rows="4"
                  name="address"
                  v-model="data.address"
                  id="address"
                  placeholder="Enter your Address"
                  required="required"
                  class="form-control form-control-sm shadow-none"
                ></textarea>
                <small v-if="errors && errors.address" class="text-danger">{{
                  errors.address[0]
                }}</small>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for class="col-form-label-sm m-0"
                      >Expected Sallery</label
                    >
                    <input
                      type="text"
                      name="expected_sallery"
                      v-model="data.expected_sallery"
                      id="expected_sallery"
                      placeholder="Enter your  Expected Sallery"
                      class="form-control form-control-sm shadow-none"
                    />
                    <small
                      v-if="errors && errors.expected_sallery"
                      class="text-danger"
                      >{{ errors.expected_sallery[0] }}</small
                    >
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="form-group">
                    <label for class="col-form-label-sm m-0">File</label>
                    <br />
                    <input type="file" v-on:change="onFileChange" name="file" />
                    <small v-if="errors && errors.file" class="text-danger">{{
                      errors.file[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <button class="btn btn-sm btn-primary mt-3" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Career",
  },
];
export default {
  name: "ApplyOnline",
  components: {
    Banner,
  },
  data() {
    return {
      data: {
        name: "",
        email: "",
        mobile: "",
        address: "",
        expected_sallery: "",
        carrer_id: this.$route.params.id,
      },
      savingSuccessful: false,
      carrer: {},
      breadcrumbTitle: "",
      text: "",
      errors: {},
      focus_field: "",
      breadcumb: breadcumb,
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var form = document.getElementById("form");
      var formData = new FormData(form);
      if (this.file) {
        formData.append("file", this.file);
      } else {
        formData.append("file", "");
      }
      axios
        .post("/api/apply", formData, config)
        .then((respons) => {
          this.savingSuccessful = true;
          this.focus_field = "";
          this.data = "";
          e.target.reset();
          this.text = "Carrer Apply done";
          console.log("Carrer Apply done");
        })
        .catch((error) => {
          this.loaded = true;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors || {};
            this.focus_field = Object.keys(this.errors)[0];
          }
        });
    },
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/carrer_detail/" + this.$route.params.id)
        .then((respons) => {
          this.$root.loading = false;
          this.carrer = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = [
            { title: "Carrer", route_name: "carrer" },
            { title: this.breadcrumbTitle },
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.file = e.target.files[0];
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
