<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="contact py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="card shadow-sm h-100">
              <div class="card-header m-3">
                <h4 class="m-0 font-weight-bold">Contact Form</h4>
              </div>
              <form action="#" class="card-body">
                <div class="controls">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for class="col-form-label-sm m-0">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter your firstname"
                          required="required"
                          class="form-control form-control-sm shadow-none"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for class="col-form-label-sm m-0">Last Name</label>
                        <input
                          type="text"
                          name="surname"
                          id="surname"
                          placeholder="Enter your  lastname"
                          required="required"
                          class="form-control form-control-sm shadow-none"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for class="col-form-label-sm m-0">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your  email"
                      required="required"
                      class="form-control form-control-sm shadow-none"
                    />
                  </div>
                  <div class="form-group">
                    <label for class="col-form-label-sm m-0">Message</label>
                    <textarea
                      rows="4"
                      name="message"
                      id="message"
                      placeholder="Enter your message"
                      required="required"
                      class="form-control form-control-sm shadow-none"
                    ></textarea>
                  </div>
                  <button type="submit" class="btn bg-theme3 text-white lift">Send message</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-7">
            <div class="card shadow-sm card-body h-100">
              <h4 class="font-weight-bold">Address</h4>
              <p class>{{contact.address}}</p>
              <p class>Telephone: {{contact.phone}}</p>
              <p class>E-mail: {{contact.email}}</p>
              <div v-html="contact.google_location"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
export default {
  name: "Content Details",
  components: {
    Banner
  },
  data() {
    return {
      contact: {},
      breadcrumbTitle: "",
      breadcumb: []
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/contact")
        .then(respons => {
          this.breadcumb = [{ title: "Contact" }];
          this.contact = respons.data.result;
          this.breadcrumbTitle = "Contact";
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
    this.$root.loading = false;
  },
  created() {
    this.$root.loading = true;
  }
};
</script>
