<template>
  <div class="about">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="welcome-text py-4">
      <div class="container">
        <Principal />
        <div class="row align-items-center">
          <div class="col-sm-12">
            <h4 class="m-0 font-weight-bold text-center">All Teacher</h4>
            <hr />
          </div>
          <div class="col-sm-12 teachers">
            <div class="row justify-content-center">
              <div
                class="col-md-3"
                v-for="(item, index) in table.datas"
                :key="index"
              >
                <router-link
                  :to="{ name: 'teacher.detail', params: { id: item.id } }"
                >
                  <div class="card-box p-2" style="min-height: 240px">
                    <img
                      v-if="item.image"
                      :src="$root.baseurl + item.image"
                      class
                      alt="..."
                    />
                    <img
                      v-else
                      :src="$root.baseurl + '/images/no-image.png'"
                      class
                      alt="..."
                    />
                    <div class="card-content shadow-sm">
                      <h5 class="title text-center">
                        <a href>{{ item.name }}</a>
                      </h5>
                      <p class="sub-title text-center">
                        Mobile No-{{ item.mobile_no }}
                      </p>
                      <p class="sub-title text-center">
                        {{ item.designation }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="page-footer" v-if="meta.last_page > 1">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12 col-md-5 p-0">
                    <div role="status" aria-live="polite">
                      Showing {{ meta.from }} to {{ meta.to }} of
                      {{ meta.total }} entries
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-7 p-0">
                    <nav aria-label="Page navigation example">
                      <ul
                        class="pagination pagination-sm justify-slider_content-end"
                      >
                        <li class="page-item">
                          <a
                            class="page-link"
                            v-on:click="get_datas(1)"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li class="page-item" v-if="meta.current_page > 2">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page - 2)"
                            >{{ meta.current_page - 2 }}</a
                          >
                        </li>
                        <li class="page-item" v-if="meta.current_page > 1">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page - 1)"
                            >{{ meta.current_page - 1 }}</a
                          >
                        </li>
                        <li class="page-item active">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page)"
                            >{{ meta.current_page }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          v-if="meta.current_page < meta.last_page"
                        >
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page + 1)"
                            >{{ meta.current_page + 1 }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          v-if="meta.current_page + 1 < meta.last_page"
                        >
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page + 2)"
                            >{{ meta.current_page + 2 }}</a
                          >
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.last_page)"
                            aria-label="Next"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./pages/Banner.vue";
import Principal from "./home/Principal.vue";
const breadcumb = [
  {
    title: "Teacher",
  },
];
export default {
  name: "About",
  components: {
    Banner,
    Principal,
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Teacher",
      fields_name: { 0: "Select One", description: "Description" },
      search_data: {
        field_name: 0,
        value: "",
      },
      table: {
        title: "Simple Table",
        datas: [],
      },
      pagination: [],
      meta: [],
      links: [],
    };
  },
  methods: {
    get_datas: function (pageindex) {
      let url = "/home/teacherlist";
      if (pageindex > 0) {
        url = "/home/teacherlist?page=" + pageindex;
      }
      axios
        .get(url)
        .then((respons) => {
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.$parent.debug_data = respons;
        })
        .catch((error) => {
          console.log(error);
        })
        .then((always) => {
          this.$root.loading = false;
        });
    },
  },
  mounted() {
    this.get_datas();
  },
};
</script>
