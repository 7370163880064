<template>
  <div id="payment">
    <div class="row align-items-center">
      <h5 class="font-weight-bold text-uppercase mb-4" style="margin: auto">
        Quiz
      </h5>
    </div>

    <div class="row" v-if="Object.keys(exam).length > 0">
      <div class="col-md-4" v-for="data in exam" :key="data.id">
        <slot
          v-if="
            data.time <= current_time &&
            data.to_time >= current_time &&
            moment().format('YYYY-MM-DD') == data.date
          "
        >
          <slot
            v-if="
              (Object.keys(applied).length > 0) & (data.id != applied[data.id])
            "
          >
            <slot v-if="data.religion">
              <slot v-if="data.religion == $root.logged_user.religion">
                <div class="text-center box-body">
                  <router-link
                    :to="{
                      name: 'applicant.quiz_detail',
                      params: {
                        examId: data.id,
                        applicant_id: $root.logged_user.id,
                      },
                    }"
                    :title="data.subject.name"
                    v-b-tooltip.hover.topleft
                  >
                    <h5 class="text-uppercase m-0" v-if="data.subject">
                      {{ data.subject.name }}
                    </h5>
                    <i class="fas fa-calendar-alt"></i>
                    {{ data.full_date }}
                    <br />
                    <i class="fas fa-clock"></i>
                    {{ moment(data.date + " " + data.time).format("hh:mm A") }}
                    -
                    {{
                      moment(data.date + " " + data.to_time).format("hh:mm A")
                    }}
                  </router-link>
                </div>
              </slot>
            </slot>
            <slot v-else>
              <div class="text-center box-body">
                <router-link
                  :to="{
                    name: 'applicant.quiz_detail',
                    params: {
                      examId: data.id,
                      applicant_id: $root.logged_user.id,
                    },
                  }"
                  :title="data.subject.name"
                  v-b-tooltip.hover.topleft
                >
                  <div class="text-success pt-2">Go to question papper</div>
                  <h5 class="text-uppercase m-0 pt-2" v-if="data.subject">
                    {{ data.subject.name }}
                  </h5>
                  <i class="fas fa-calendar-alt"></i>
                  {{ data.full_date }}
                  <br />
                  <i class="fas fa-clock"></i>
                  {{ moment(data.date + " " + data.time).format("hh:mm A") }} -
                  {{ moment(data.date + " " + data.to_time).format("hh:mm A") }}
                </router-link>
              </div>
            </slot>
          </slot>
          <slot v-else>
            <div class="text-center box-body">
              <h5 class="text-uppercase text-danger m-0">Exam Applied</h5>
              <slot v-if="data.auto_publish == 'yes'">
                <slot v-if="data.can_view_reslt == 'yes'">
                  <router-link
                    :to="{
                      name: 'applicant.online_exam_result_detail',
                      params: { examId: data.id },
                    }"
                    >View Result</router-link
                  >
                </slot>
              </slot>
              <slot
                v-if="
                  data.can_view_exam_paper == 'yes' &&
                  data.final_submit != 'yes'
                "
              >
                <router-link
                  class="text-success pt-2"
                  :to="{
                    name: 'applicant.quiz_detail',
                    params: {
                      examId: data.id,
                      applicant_id: $root.logged_user.id,
                    },
                  }"
                  :title="data.subject.name"
                  v-b-tooltip.hover.topleft
                  >Go to question papper</router-link
                >
              </slot>
              <h5 class="text-uppercase m-0 pt-2" v-if="data.subject">
                {{ data.subject.name }}
              </h5>
              <i class="fas fa-calendar-alt"></i>
              {{ data.full_date }}
              <br />
              <i class="fas fa-clock"></i>
              {{ moment(data.date + " " + data.time).format("h:m A") }} -
              {{ moment(data.date + " " + data.to_time).format("h:m A") }}
            </div>
          </slot>
        </slot>
        <slot v-else>
          <div class="text-center box-body">
            <slot v-if="data.id == applied[data.id]">
              <h5 class="text-uppercase text-danger m-0">Exam Applied</h5>
            </slot>
            <slot
              v-if="data.auto_publish == 'yes' && data.id == applied[data.id]"
            >
              <slot v-if="data.can_view_reslt == 'yes'">
                <router-link
                  :to="{
                    name: 'applicant.online_exam_result_detail',
                    params: { examId: data.id },
                  }"
                  >View Result</router-link
                >
              </slot>
            </slot>
            <slot v-if="data.can_view_exam_paper == 'yes'">
              <router-link
                :to="{
                  name: 'applicant.quiz_detail',
                  params: {
                    examId: data.id,
                    applicant_id: $root.logged_user.id,
                  },
                }"
                :title="data.subject.name"
                v-b-tooltip.hover.topleft
                class="text-success pt-2"
                >Go to question papper</router-link
              >
            </slot>
            <slot v-else>
              <div :id="data.id" style="display: none">
                <router-link
                  class="text-success pt-2"
                  :to="{
                    name: 'applicant.quiz_detail',
                    params: {
                      examId: data.id,
                      applicant_id: $root.logged_user.id,
                    },
                  }"
                  >Go to question papper</router-link
                >
              </div>
            </slot>
            <h5 class="text-uppercase m-0 pt-2" v-if="data.subject">
              {{ data.subject.name }}
            </h5>
            <i class="fas fa-calendar-alt"></i>
            {{ data.full_date }}
            <br />
            <i class="fas fa-clock"></i>
            {{ moment(data.date + " " + data.time).format("h:m A") }} -
            {{ moment(data.date + " " + data.to_time).format("h:m A") }}
          </div>
        </slot>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <h5 class="text-center" v-if="is_hit">No Exam Fround Right Now</h5>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      exam: {},
      applied: {},
      applicant: {},
      all_data: {},
      current_time: moment().format("HH:mm"),
      errors: [],
      is_hit: false,
    };
  },
  computed: {},
  methods: {
    online_exam: function () {
      axios.get("applicant/online-exam").then((response) => {
        this.is_hit = true;
        this.exam = response.data.data;
        this.applied = response.data.applied;
        window.localStorage.setItem("exam_info", JSON.stringify(response.data));
      });
    },
  },
  created() {
    setInterval(() => {
      this.all_data = JSON.parse(window.localStorage.getItem("exam_info"));
      this.all_data.data.forEach((element, key) => {
        element.time = element.time;
        console.log(moment().format("HH:mm:ss"));
        if (
          element.time <= moment().format("HH:mm:ss") &&
          element.to_time >= moment().format("HH:mm:ss") &&
          moment().format("YYYY-MM-DD") == element.date
        ) {
          console.log(element.id);
          $("#" + element.id).show();
        }
      });
    }, 50000);
  },
  mounted() {
    this.online_exam();
    this.$root.loading = false;
  },
};
</script>
