<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="welcome-text py-4">
      <div class="container">
        <div class="d-flex justify-content-center">
          <div class="col-md-4">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                  text-align: center;
                "
              >
                <i class="checkmark">✓</i>
              </div>
              <p class="text-center">
                Your application has been successfully submitted. Please log in
                NOW to continue and proceed to the payment options for paying
                the form fee to complete the process.<br />Please log in NOW to
                continue and proceed to the payment
              </p>
              <p class="text-success text-center">Below your Credentials</p>
              <table class="table table-striped table-fixed table-bordered">
                <tr>
                  <th>Admission Id</th>
                  <td>{{ adminssion_details.applicantid }}</td>
                </tr>
                <tr>
                  <th>Password</th>
                  <td>{{ adminssion_details.mobile }}</td>
                </tr>
              </table>
              <router-link
                class="btn btn-success text-center d-flex justify-content-center"
                to="/applicant/login"
                >Login</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.card {
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
}
</style>
<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Success",
  },
];
export default {
  name: "About",
  components: {
    Banner,
  },
  data() {
    return {
      adminssion_details: "",
      breadcrumbTitle: "Success",
      breadcumb: breadcumb,
    };
  },
  methods: {
    get_datas: function () {
      this.$root.loading = true;
      let url = "/home/adminssion_details/" + this.$route.params.id;
      axios
        .get(url)
        .then((respons) => {
          this.adminssion_details = respons.data.result;
          this.$root.loading = false;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_datas();
  },
};
</script>
