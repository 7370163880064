<template>
  <div id="payment">
    <div class="row">
      <div class="col-12">
        <a
          class="btn btn-primary"
          v-if="data.onlineExam"
          v-on:click="
            ResultDownloadPDF(data.onlineExam.id, data.onlineResult.id)
          "
          style="color: #fff; cursor: pointer"
          >Download PDF</a
        >
      </div>

      <div class="col-12 mt-4" id="printArea">
        <table class="table tablesorter border table-hover" border="1">
          <tr>
            <td>Subject</td>
            <td>
              <slot v-if="data.onlineExam && data.onlineExam.subject">{{
                data.onlineExam.subject.name
              }}</slot>
            </td>
          </tr>
          <tr>
            <td>Exam</td>
            <td>
              <slot v-if="data.onlineExam">{{ data.onlineExam.name }}</slot>
            </td>
          </tr>
          <tr>
            <td>Exam Date Time</td>
            <td>
              <slot v-if="data.onlineExam"
                >{{ data.onlineExam.full_date }} To
                {{ data.onlineExam.exam_end_time }}</slot
              >
            </td>
          </tr>
          <tr>
            <td>Total Marks</td>
            <td>{{ data.onlineExam.total_mark }}</td>
          </tr>
          <tr>
            <td>Student Get Marks</td>
            <td>
              {{ data.onlineResult.total_get_marks }} (Right Ans:
              {{ data.onlineResult.rigt_ans }})
            </td>
          </tr>
          <tr v-if="data.onlineResult.length > 0">
            <td>Pass/Fail</td>
            <td>
              <slot
                v-if="
                  data.onlineResult.total_get_marks >= data.onlineExam.pass_mark
                "
              >
                <h5 class="text-success">
                  <i class="fas fa-check"></i> Passed
                </h5>
              </slot>
              <slot v-else>
                <h5 class="text-danger">
                  <i class="fas fa-times-circle"></i> Fail
                </h5>
              </slot>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <pre>{{ data }}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      data: {
        onlineExam: {},
        onlineResult: {},
      },
    };
  },
  methods: {
    ResultDownloadPDF: function (e, f) {
      this.$root.loading = true;
      axios({
        url: this.$root.baseurl + "/download-result/" + e + "/" + f,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Result.pdf");
        document.body.appendChild(link);
        link.click();
        this.$root.loading = false;
      });
    },
  },
  computed: {},
  mounted: function () {},
  created() {
    axios
      .get("student/quiz-result-details/" + this.$route.params.examId)
      .then((response) => {
        this.data.onlineExam = response.data.onlineExam;
        this.data.onlineResult = response.data.onlineResult;
      });
    this.$root.loading = false;
  },
};
</script>

