<template>
  <div class="navigation">
    <div class="main-menu">
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light p-0"
        v-if="$root.site"
      >
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/">
            <div class="logo">
              <div class="logo-img">
                <img
                  v-if="$root.site.image"
                  :src="$root.site.FullUrl + $root.site.image"
                />
              </div>
              <h1 class="pl-1 mb-0">
                Cantonment English
                <br />School & College
              </h1>
            </div>
          </router-link>
          <button
            class="navbar-toggler m-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse p-3"
            id="navbarSupportedContent"
            v-if="menus"
          >
            <ul class="navbar-nav mr-auto ml-lg-5">
              <li class="nav-item">
                <router-link class="nav-link" to="/">
                  Home
                  <span class="sr-only">(current)</span>
                </router-link>
              </li>
              <slot v-for="item in menus">
                <li
                  class="nav-item"
                  :key="item.id"
                  v-bind:class="
                    Object.keys(item.all_children_menus).length > 0
                      ? 'dropdown'
                      : ''
                  "
                >
                  <slot v-if="Object.keys(item.all_children_menus).length > 0">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >{{ item.title }}</a
                    >
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <slot v-for="sub_menu in item.all_children_menus">
                        <li class="nav-item" :key="sub_menu.id">
                          <router-link
                            v-if="sub_menu.type === 'content'"
                            :to="{
                              name: 'content',
                              params: { slug: sub_menu.slug },
                            }"
                            class="nav-link"
                            >{{ sub_menu.title }}</router-link
                          >
                          <router-link
                            v-else
                            :to="{ name: sub_menu.url }"
                            class="nav-link"
                            >{{ sub_menu.title }}</router-link
                          >
                        </li>
                      </slot>
                    </ul>
                  </slot>
                  <slot v-else>
                    <router-link
                      v-if="item.type === 'content'"
                      :to="{ name: 'content', params: { slug: item.slug } }"
                      class="nav-link"
                      >{{ item.title }}</router-link
                    >
                    <router-link
                      v-else
                      :to="{ name: item.url }"
                      class="nav-link"
                      >{{ item.url }}</router-link
                    >
                  </slot>
                </li>
              </slot>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<style>
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  height: 300px;
}
.navbar-nav .active {
  color: #fff !important;
  background-color: #1b859d;
}
.parent-active {
  color: #fff !important;
  background-color: #1b859d;
}
</style>
<script>
import axios from "axios";
export default {
  name: "Menu",
  data() {
    return {
      menus: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get("/all_layout_data/topMenu")
        .then((respons) => {
          this.menus = respons.data.header;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    setInterval(function () {
      $(".nav-item").children(".nav-link").removeClass("parent-active");
      $(".cesc")
        .parents()
        .parents(".dropdown-menu")
        .siblings("#navbarDropdown")
        .addClass("parent-active");
    }, 2000);
    this.get_data();
  },
  created() {
    //if (!(this.variables.teacherList)) { this.getTeacherList() }
  },
};
</script>
