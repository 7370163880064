<template>
    <div class="container">
        <div class="row" style="margin-top: 140px;">
            <div class="col-lg-2">
                <div class="sidebar card shadow-sm">
                    <div class="card-header">Quick link</div>
                    <div class="">
                        <ul
                            class="nav flex-column sidemenu accordion"
                            id="accordionExample"
                        >
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'applicant.dashboard' }"
                                    class="nav-link"
                                >Profile</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'applicant.update-profile' }"
                                    class="nav-link"
                                >Update Profile</router-link>
                            </li>

                            <li
                                class="nav-item"
                            >
                                <a
                                    :href="$root.baseurl + '/Admission/pdf/' + applicantinfo.id"
                                    style="cursor: pointer"
                                    class="nav-link"
                                    target="_blank"
                                >Download Profile</a>
                            </li>

                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'applicant.payment' }"
                                    class="nav-link"
                                >Payment</router-link>
                            </li>
                            <li class="nav-item" v-if="Object.keys(exam).length > 0">
                                <router-link
                                    :to="{ name: 'applicant.onlineExam' }"
                                    class="nav-link"
                                >Online Exam</router-link>
                            </li>
                            <li
                                class="nav-item"
                                v-if="parseInt(applicantinfo.is_payment) > 0"
                            >
                                <a
                                    :href="$root.baseurl + '/admit-card/' + applicantinfo.id"
                                    style="cursor: pointer"
                                    class="nav-link"
                                    target="_blank"
                                >Admit
                                </a>
                            </li>
                            <li>
                                <a
                                    style="cursor: pointer"
                                    class="nav-link"
                                    v-on:click="logout()"
                                >Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-10">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Applicant",
        data() {
            return {
                exam: {},
                applicantinfo: {
                    std_class_id: '',
                    is_payment: 0,
                    educations:[],
                    admission_info:{
                        class_has_admission:[],
                        is_exam: 0,
                        },
                    jsc_reg:'',
                    ssc_reg: ''
                },
            };
        },
        methods: {
            AdmitCard() {window.location = this.$root.baseurl + "/downloadAdmit";},

            logout() {
                this.logoutAppliacnt();
                this.$root.variable = {};
                this.$root.logged_user =[];
            },

            isExam: function () {
                axios.get("applicant/online-exam")
                    .then((response) => this.exam = response.data.data);
            },

            applicantInfoData: function () {
                return axios.get("/applicant/logged")
                    .then((response) => this.applicantinfo = response.data);
            },
        },

        async mounted() {
            await this.applicantInfoData();
            if(this.applicantinfo.admission_info.is_exam){setInterval(() => {this.isExam();}, 30000)};
            this.$root.loading = false;
        },

        created() {
            this.loggedIn();
        }
    };
</script>
