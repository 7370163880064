<template>
  <div id="payment">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="contact py-4">
      <div class="container">
        <div class="row align-items-center">
          <h5 class="font-weight-bold text-uppercase" style="margin: auto">
            Payment Success
          </h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              v-if="$route.query.Status == 1"
              class="alert alert-success"
              role="alert"
            >
              <h3>Your transaction Complete Successfully!</h3>
              <p>
                <strong>Name :</strong>
                {{ $route.query.Name }}
              </p>
              <p>
                <strong>Transaction ID :</strong>
                {{ $route.query.OrderID }}
              </p>
              <p>
                <strong>Ref. ID :</strong>
                {{ $route.query.RefID }}
              </p>
              <p>
                <strong>Amount. ID :</strong>
                {{ $route.query.Amount }}
              </p>
              <!-- <pre>{{$route.query}}</pre> -->
            </div>
            <div
              v-if="$route.query.Status == 0"
              class="alert alert-danger"
              role="alert"
            >
              <h3>Your transaction is declined!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from ".././pages/Banner.vue";
export default {
  components: {
    Banner,
  },
  data() {
    return {
      result: [],
      breadcrumbTitle: "Payment Success Message",
      breadcumb: [{ title: "Payment Success Message" }],
    };
  },
  mounted() {
    this.$root.loading = false;
  },
};
</script>
