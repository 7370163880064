<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <form v-on:submit.prevent="search" class="form-row">
              <div class="form-group mb-0 col p-0">
                <select
                  class="form-control form-control-sm rounded-0"
                  v-model="search_data.academic_year"
                >
                  <option :value="null">Accademic Year</option>
                  <option
                    v-for="(value, name, index) in yearlist"
                    :key="index"
                    v-bind:value="name"
                  >{{ value }}</option>
                </select>
              </div>
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.std_class_id"
                  :options="extradata.class_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null">Select Class</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.section_id"
                  :options="extradata.section_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null">Select Section</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col p-0">
                <select
                  class="form-control form-control-sm rounded-0"
                  v-model="search_data.field_name"
                >
                  <option
                    v-for="(value, name, index) in fields_name"
                    :key="index"
                    v-bind:value="name"
                  >{{ value }}</option>
                </select>
              </div>
              <div class="form-group mb-0 col p-0">
                <input
                  type="text"
                  v-model="search_data.value"
                  class="form-control form-control-sm rounded-0"
                  placeholder="Type your text"
                />
              </div>
              <div class="form-group mb-0 col-2">
                <button type="submit" class="btn btn-sm btn-primary rounded-0">
                  <span class="fa fa-search form-control-feedback"></span> Search
                </button>
              </div>
            </form>
          </div>
          <div class="form-group mb-0 col-12">
            <!-- <router-link
              :to="{ name:'Student.login'}"
              class="btn btn-sm btn-primary rounded-0 float-right"
            >Student Login</router-link>-->
          </div>
          <div class="form-group mb-0 col-12">
            <!-- <router-link
              :to="{ name:'Student.dashboard'}"
              class="btn btn-sm btn-primary rounded-0 float-right"
            >Student Dashboard</router-link>-->
          </div>
          <div class="col-sm-12">
            <br />
            <h4 class="m-0 font-weight-bold text-center">Student</h4>
            <hr />
          </div>
          <div class="col-sm-12 teachers" v-if="!$root.loading">
            <div class="row justify-content-center">
              <div class="col-md-3" v-for="(item, index) in table.datas" :key="index">
                <div class="card-box p-2" style="min-height:240px;">
                  <img v-if="item.image" :src="item.image" class alt="..." />
                  <img v-else :src="'/images/no-image.png'" class alt="..." />
                  <div class="card-content shadow-sm">
                    <h5 class="title text-center">
                      <a href>{{item.name}}</a>
                    </h5>
                    <p class="sub-title text-center">Student Id-{{item.studentid}}</p>
                    <p class="sub-title text-center">Roll-{{item.roll}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-footer" v-if="meta.last_page>1">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12 col-md-5 p-0">
                    <div
                      role="status"
                      aria-live="polite"
                    >Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7 p-0">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination pagination-sm justify-slider_content-end">
                        <li class="page-item">
                          <a class="page-link" v-on:click="get_datas(1)" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li class="page-item" v-if="meta.current_page>2">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page-2)"
                          >{{meta.current_page-2}}</a>
                        </li>
                        <li class="page-item" v-if="meta.current_page>1">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page-1)"
                          >{{meta.current_page-1}}</a>
                        </li>
                        <li class="page-item active">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page)"
                          >{{meta.current_page}}</a>
                        </li>
                        <li class="page-item" v-if="meta.current_page<meta.last_page">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page+1)"
                          >{{meta.current_page+1}}</a>
                        </li>
                        <li class="page-item" v-if="meta.current_page+1<meta.last_page">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page+2)"
                          >{{meta.current_page+2}}</a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.last_page)"
                            aria-label="Next"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Student"
  }
];
export default {
  name: "About",
  components: {
    Banner
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Student",
      yearlist: "",
      fields_name: {
        0: "Select One",
        name: "Student Name",
        father_name: "Father Name",
        mother_name: "Mother Name",
        studentid: "Student ID",
        roll: "ROll No",
        mobile: "Mobile No",
        email: "Email"
      },
      search_data: {
        field_name: 0,
        std_class_id: null,
        section_id: null,
        academic_year: null,
        value: ""
      },
      extradata: {
        class_list: [],
        section_list: []
      },
      table: {
        title: "Simple Table",
        datas: []
      },
      pagination: [],
      meta: [],
      links: []
    };
  },
  watch: {
    "search_data.std_class_id": function(val, oldval) {
      if (val) {
        this.getList("Section", "section_list", val, "std_class_id");
      }
    }
  },
  methods: {
    get_datas: function(pageindex) {
      this.$root.loading = true;
      let url = "/home/studentlist";
      if (pageindex > 0) {
        url = "/home/studentlist?page=" + pageindex;
      }
      axios
        .get(url, { params: this.search_data })
        .then(respons => {
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.$parent.debug_data = respons;
        })
        .catch(error => {
          console.log(error);
        })
        .then(always => {
          setTimeout(() => {
            this.$root.loading = false;
          }, 100);
        });
    },
    search() {
      this.get_datas();
    }
  },
  created() {
    this.YearInformation();
    this.getList("StdClass", "class_list");
  },
  mounted() {
    this.get_datas();
    this.getStdClassList();
  }
};
</script>
