<template>
    <div id="app">
        <header class="header fixed-top" v-if="checkType() != 'student'">
            <!--top-header-->
            <TopHead/>
            <Navigation/>
            <!--//top-header-->
        </header>
        <main>
            <Loading v-if="$root.loading"/>
            <router-view/>
        </main>
        <footer class="bg-footer py-5 pt-md-5 pb-md-2">
            <div class="container">
                <Footer/>
            </div>
        </footer>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TopHead from "./Layouts/TopHead";
    import Navigation from "./Layouts/Navigation.vue";
    import Footer from "./Layouts/Footer.vue";
    import Loading from "./Layouts/Loader";

    export default {
        name: "home",
        components: {
            Loading,
            TopHead,
            Navigation,
            Footer,
        },
        methods: {
            handleSCroll(event) {
                let header = document.querySelector(".header");

                /*if (window.scrollY > 100 && header.className && !header.className.includes("fixed-show")) {
                    header.classList.add("fixed-show");
                } else if (window.scrollY < 100) {
                    header.classList.remove("fixed-show");
                }*/
            },
            checkType() {
                let route = this.$route.name;
                let routeName = route.split('.');
                if (routeName && routeName[0] === 'student') {
                    return 'student';
                }
            }
        },
        created() {
            window.addEventListener("scroll", this.handleSCroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleSCroll);
        },
    };
</script>

<style scoped>
    main {
        min-height: 70vh;
        background: #f4f7fa;
    }
</style>

