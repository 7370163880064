Vue.mixin({
    computed: {
        years() {
            const year = new Date().getFullYear()
            return Array.from({ length: year - 2009 }, (value, index) => 2011 + index)
        }
    },

    methods: {
        // ================= notification =================
        notification(msg, type) {
            if (type == 'success') {
                this.flashMessage.success({
                    title: "Success !!",
                    icon: this.$root.baseurl + "/images/success.png",
                    message: msg
                });
            } else if (type == 'error') {
                this.flashMessage.error({
                    title: "Error !!",
                    icon: this.$root.baseurl + "/images/error.png",
                    message: msg
                });
            } else if (type == 'warning') {
                this.flashMessage.warning({
                    title: "Warning !!",
                    icon: this.$root.baseurl + "/images/warning.png",
                    message: msg
                });
            }
        },

        getStdClassList: function (id = null, field = null) {
            let url = '/all_getStdClassList'
            if (!(id == null & field == null)) {
                url = '/all_getStdClassList/' + id + '/' + field
            }
            axios
                .get(url)
                .then(res => {
                    this.extradata['std_class_list'] = res.data
                })
                .catch(errors => {
                    alert(errors.response)
                    console.log(errors.response)
                })
        },
        getList(modelname, objectkey, value, fieldname) {
            let url = '/all_get' + modelname + 'List'
            if (!(value == null & fieldname == null)) {
                url = '/all_get' + modelname + 'List/' + value + '/' + fieldname
            }
            axios
                .get(url)
                .then(res => {
                    this.extradata[objectkey] = res.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        getSubjectList: function (id = null, field = null) {
            let url = 'subjectlist'
            if (!(id == null & field == null)) {
                url = 'subjectlist/' + id + '/' + field
            }
            axios
                .get(url)
                .then(res => {
                    this.extradata['subject_list'] = res.data;
                })
                .catch(errors => {
                    console.log(errors.response);
                });
            console.log('global getSubjectList called')
        },
        getList2(model_name, object_key, value, field_name) {
            let url = '/get' + model_name + 'List'
            if (!(value == null & field_name == null)) {
                url = '/get' + model_name + 'List/' + value + '/' + field_name
            }
            axios
                .get(url)
                .then(res => {
                    this.extradata[object_key] = res.data;
                })
                .catch(errors => {
                    console.log(errors.response);
                });
        },
        getList3(model_name, object_key, value, field_name) {
            let url = '/get' + model_name + 'List'
            if (!(value == null & field_name == null)) {
                url = '/get' + model_name + 'List/' + value + '/' + field_name
            }
            axios
                .get(url)
                .then(res => {
                    this.extradata2[object_key] = res.data;
                })
                .catch(errors => {
                    console.log(errors.response);
                });
        },
        loggedApplicant: function () {
            axios
                .get('/applicant/logged')
                .then(response => {
                    this.applicant['info'] = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        loggedIn: function () {
            axios
                .get('/applicant/loggedOrNot')
                .then(response => {
                    if (!response.data) {
                        this.$router.push('/applicant/login')
                    }
                })
        },
        logoutAppliacnt: function () {
            axios
                .get('/applicant/logout')
                .then(response => {
                    if (response.data == true) {
                        window.location = this.$root.baseurl + "/applicant/login"
                        this.applicant.login = false;
                    } else {
                        this.$router.push('/applicant/dashboard')
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getSectionList: function (id = null, field = null) {
            let url = '/all_getSectionList'
            if (!(id == null & field == null)) {
                url = '/all_getSectionList/' + id + '/' + field
            }
            axios
                .get(url)
                .then(res => {
                    this.extradata['section_list'] = res.data
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        classHasGroup(stdclasid) {
            return axios
                .get('/all_hasGroup/' + stdclasid)
                .then(res => {
                    this.has_group = res.data.hasGroup
                })
                .catch(errors => {
                    console.log(errors.response)
                })
        },
        YearInformation() {
            axios
                .get('/all_layout_data/yearlist')
                .then(respons => {
                    this.yearlist = respons.data.yearlist
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getPostBody(description, character) {
            let details = this.stripTags(description);
            return details.length > character ? details.substring(0, character) + '...' : details;
        },
        stripTags(text = null) {
            if (text) {
                return text.replace(/(<([^>]+)>)/ig, '');
            } else {
                return '';
            }
        },
        showImage(obj) { const src = URL.createObjectURL(obj); this.image_src = src },
        site_info() {
            axios
                .get('/all_layout_data/topHead')
                .then(res => {
                    this.site = res.data.site_data;
                    this.socialLink = res.data.socialLink;
                });
        }
    }
})
