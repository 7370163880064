<template>
  <div id="payment">
    <div class="row align-items-center mb-3">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto;">Notification</h5>
    </div>
    <div class="row card" v-if="Object.keys(datas).length>0">
      <div class="col-12 card-body" v-if="Object.keys(datas)">
        <table class="table table-bordered tablesorter table-hover tableLay">
          <tr>
            <th style="width:5%;">SL</th>
            <th style="width:20%;">Title</th>
            <th style="width:75%;">Message</th>
          </tr>
          <tr v-for="(data, index) in datas" :key="index">
            <td>{{ (index++)+1 }}</td>
            <td>{{data.title}}</td>
            <td><p class="text-justify" v-html="data.body"></p></td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else class="card card-body bg-ligh text-center">No Notification Yet</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datas: {},
      count: 0,
      errors: []
    };
  },
  methods: {
    getNotification(id) {
      this.$root.loading = true;
      axios
        .get("get-all-notification/" + id)
        .then(response => {
          this.datas = response.data;
        })
        .catch(errer => {})
        .then(always => {
          this.$root.loading = false;
        });
    }
  },
  created() {
    this.getNotification(this.$parent.data.id);
    this.$root.loading = false;
  },
  updated() {
    if (Object.keys(this.datas).length < 1) {
      if (this.count <= 0) {
        this.getNotification(this.$parent.data.id);
      }
      this.count++;
    }
  }
};
</script>
<style>
  a{
    color:blue;
  }
</style>
