<template>
  <div id="payment">
    <div class="row align-items-center">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto;">Payment History</h5>
    </div>
    <div class="row">
      <div class="col-12">
        <router-link
          :to="{name:'student.pay'}"
          class="btn btn-sm btn-primary mb-2 float-right"
        >Due History</router-link>
      </div>
    </div>
    <div class="row" v-if="!$root.loading">
      <div class="col-12" v-if="Object.keys(result)">
        <table
          class="table table-striped table-fixed table-bordered"
          v-if="Object.keys(result).length>0"
        >
          <thead>
            <tr>
              <th>SL</th>
              <th>Voucher No</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Print Invoice</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, index) in result" :key="index">
              <td>{{index+1}}</td>
              <td>{{obj.voucher_no}}</td>
              <td>{{obj.date}}</td>
              <td>{{obj.amount}}</td>
              <td>{{obj.payment_status}}</td>
              <td>
                <router-link
                  :to="{ name:'student.invoiceDetails', params: {voucher_no:obj.voucher_no, transactionID:obj.transactionID }}"
                  class="btn btn-primary"
                  title="Invoice"
                  v-b-tooltip.hover.topleft>
                  View Invoice <span class="fas fa-file-invoice"></span>
                </router-link>
                <!-- <router-link
                v-if="$root.checkPermission(routes.view)"
                :to="{ name:routes.view, params: { id: item.id }}"
                class="btn btn-table text-primary"
                title="View"
                v-b-tooltip.hover.topleft
              >
                <span class="fas icon-table fa-eye"></span>
                </router-link>-->
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="card card-body bg-ligh text-center">No Data Found.</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      result: []
    };
  },
  created() {
    axios
      .get("student/invoice")
      .then(response => {
        this.result = response.data;
      })
      .catch(errer => {})
      .then(always => {
        this.$root.loading = false;
      });
  }
};
</script>
