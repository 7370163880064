<template>
  <div class="row">
    <div class="col-md-9">
      <div class="main-slider" v-if="!$root.loading">
    <div class="slider position-relative">
      <div id="uv-slide" class="carousel slide carousel-fade" data-ride="carousel">
        <div class="carousel-inner" v-if="hometopslider">
          <div
            class="carousel-item"
            v-for="(item, index) in hometopslider.slider_content"
            :key="index"
            :class="{ 'active': index === 0 }"
          >
            <img :src="item.FullUrl+item.image" class="d-block w-100" alt="..." />
            <div class="carousel-custom-caption caption-bottom">
              <h5 v-html="item.title"></h5>
              <p>{{item.description}}</p>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#uv-slide" role="button" data-slide="prev">
          <span class="fas fa-chevron-left" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#uv-slide" role="button" data-slide="next">
          <span class="fas fa-chevron-right" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <a href="javascript:void(0)" @click="scrollFunc" class="banner-anime-button">
        <span class="fas fa-arrow-down"></span>
      </a>
    </div>
  </div>
    </div>

    <div class="col-md-3">
      <NoticeCat />
    </div>

  </div> 
</template>
<script>
import NoticeCat from "../NoticeCat";
export default {
  components: {
    NoticeCat
  },
  name: "Slider",
  data() {
    return {
      hometopslider: {}
    };
  },
  methods: {
    scrollFunc() {
      var objDiv = document.getElementById("uv-slide");
      window.scrollTo(0, objDiv.scrollHeight);
    },
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/hometopslider")
        .then(respons => {
          this.hometopslider = respons.data.hometopslider;
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
