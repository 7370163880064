<template>
  <div id="payment">
    <div class="row align-items-center mb-3">
      <h5 class="font-weight-bold text-uppercase" style="margin: auto;">Academic Calender</h5>
    </div>
    <div class="row card">
      <div class="col-12 card-body" v-if="datas && Object.keys(datas).length>0">
        <table class="table table-bordered tablesorter table-hover tableLay">
          <tr>
            <th style="width:5%;">SL</th>
            <th style="width:40%;">Name</th>
            <th style="width:10%;">From Date</th>
            <th style="width:10%;">To Date</th>
          </tr>
          <tr v-for="(data, index) in datas" :key="index">
            <td>{{ (index++)+1 }}</td>
            <td>{{ data.name }}</td>
            <td>{{ data.start_date }}</td>
            <td>{{ data.end_date }}</td>
          </tr>
        </table>
      </div>
      <div v-else class="card card-body bg-ligh text-center">No Academic Calender</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datas: [],
      errors: []
    };
  },
  methods: {
    getCalander() {
      axios.get("get-academic-calendar").then(response => {
        this.datas = response.data;
      });
    }
  },
  created() {
    this.getCalander();
    this.$root.loading = false;
  }
};
</script>
