<template>
  <div class="news" v-if="!$root.loading">
    <div class="news py-5">
      <div class="container">
        <h4 class="title text-center">News</h4>
        <div class="row py-4">
          <div
            class="col-12 col-md-4 mb-3"
            data-aos="fade-up"
            v-for="(item, index) in news"
            :key="index"
          >
            <div class="card shadow-lg mb-6 mb-md-0 lift lift-lg h-100">
              <img
                v-if="item.image"
                :src="item.FullUrl + item.image"
                alt="..."
                class="card-img-top"
              />
              <img
                v-else
                :src="item.FullUrl + '/images/no-image.png'"
                alt="..."
                class="card-img-top"
              />

              <div class="card-body position-relative">
                <h5>{{ item.title }}</h5>
                <small class="text-muted" v-if="item.type === 'content'" v-html="getPostBody(item.description, 150)"></small>
              </div>
                <div class="card-footer bg-transparent border-0">
                    <slot v-if="item.type === 'content'">
                        <router-link
                            v-if="item.type === 'content'"
                            :to="{ name: 'news.detail', params: { slug: item.slug } }"
                            class="nav-link"
                        >
                            Read More
                            <i class="fas fa-arrow-right ml-3"></i>
                        </router-link>
                    </slot>
                    <slot v-else>
                        <a :href="item.FullUrl + item.file"
                            target="_blank"
                            class="font-weight-bold text-decoration-none">
                                Read More
                            <i class="fas fa-arrow-right ml-3"></i>
                        </a>
                    </slot>
                </div>
            </div>
          </div>
          <div class="col-12 text-center pt-5">
            <router-link
              :to="{ name: 'news' }"
              class="btn bg-theme3 text-white lift"
              >Explore All</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "News",
  data() {
    return {
      news: {},
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/news")
        .then((respons) => {
          this.news = respons.data.news;
          this.$root.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
