<template>
  <div class="teacher" style="margin-top:130px" v-if="!$root.loading">
    <!-- <Breadcrumb :msg="breadcrumbTitle" :data="breadcumb"></Breadcrumb> -->
    <div class="card shadow-sm border-top-custom mt-5">
      <div class="card-header py-3">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <form v-on:submit.prevent="search" class="form-row px-3">
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.class_id"
                  :options="extradata.class_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>Select Class</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.section_id"
                  :options="extradata.section_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>Select Section</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.student_id"
                  :options="extradata.student_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>Select Student</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col-1 p-0 text-center">OR</div>
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.field_name"
                  :options="fields_name"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>Select One</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col p-0">
                <input
                  type="text"
                  v-model="search_data.value"
                  class="form-control form-control-sm rounded-0"
                  placeholder="Type your text"
                />
              </div>
              <div class="form-group mb-0 col">
                <button type="submit" class="btn btn-sm btn-primary rounded-0">
                  <span class="fa fa-search form-control-feedback"></span> Search
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-3 ml-auto pr-0 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
              <!-- <button
                class="btn btn-success btn-sm"
                flat
                @click="excleExport('printArea','Result Sheet')"
              >
                <i class="far fa-file-excel"></i> Export
              </button>
              <button
                class="btn btn-primary btn-sm"
                flat
                @click="print('printArea','Result Sheet')"
              >
                <i class="fas fa-print"></i> Print
              </button>-->
              <!-- <router-link v-if="$root.checkPermission(base_route+'.create')" :to="{name:base_route+'.create'}" class="btn btn-success btn-sm" >
                <i class="fas fa-plus"></i>
                Add {{breadcrumbTitle}}
              </router-link>-->
            </div>
          </div>
        </div>
      </div>
      <div v-cloak class="card-body">
        <div class="tableList py-2 minHeight" v-if="Object.keys(data).length>0">
          <div class="row">
            <div title="Student Information" class="col-6">
              <div class="table-responsive">
                <table class="table table-borderless" v-if="extradata.student">
                  <tbody>
                    <tr v-if="extradata.student.studentid">
                      <th>Student ID</th>
                      <td>:</td>
                      <td>{{extradata.student.studentid}}</td>
                    </tr>
                    <tr v-if="extradata.student.name">
                      <th>Name</th>
                      <td>:</td>
                      <td>{{extradata.student.name}}</td>
                    </tr>
                    <tr v-if="extradata.class.name">
                      <th>Class</th>
                      <td>:</td>
                      <td>{{extradata.class.name}}</td>
                    </tr>
                    <tr v-if="extradata.section.name">
                      <th>Section</th>
                      <td>:</td>
                      <td>{{extradata.section.name}}</td>
                    </tr>
                    <tr v-if="extradata.student.roll">
                      <th>Roll</th>
                      <td>:</td>
                      <td>{{extradata.student.roll}}</td>
                    </tr>
                    <tr>
                      <th>Attendance</th>
                      <td>:</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <th>Assesment</th>
                      <td>:</td>
                      <td>--</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div title="Grade Information" class="col-4 offset-2">
              <div class="table-responsive">
                <table class="table tablesorter border table-hover">
                  <thead>
                    <th>Grade Letter</th>
                    <th>Class Interval</th>
                    <th>Grade Point</th>
                  </thead>
                  <tbody class="text-center">
                    <slot v-for="(grade,index) in extradata.grade_setup">
                      <tr :key="'F'+index">
                        <td>{{grade.grade_letter}}</td>
                        <td>{{grade.from_mark}}-{{grade.to_mark}}</td>
                        <td>{{grade.grade_point}}</td>
                      </tr>
                    </slot>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div :title="table.title">
            <div class="table-responsive">
              <table
                class="table tablesorter table-bordered table-hover text-sm"
                rules="all"
                paddingspace="0"
                cellpadding="0"
                border="0"
              >
                <thead class="bg-light text-secondary text-center">
                  <tr>
                    <th rowspan="2" class="align-text-top">SN</th>
                    <th rowspan="2" class="align-text-top">Subject</th>
                    <th
                      v-for="(exam,index) in extradata.exams"
                      :key="'A'+index"
                      class="align-text-top"
                    >{{exam.name}}</th>
                  </tr>
                  <tr>
                    <td class="p-0" v-for="(exam,index) in extradata.exams" :key="'B'+index">
                      <table
                        class="table m-0"
                        rules="none"
                        paddingspace="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tr>
                          <th width="12%" class="iqbal-min-width text-center text-nowrap">Full Mark</th>
                          <th width="13%" class="iqbal-min-width text-center text-nowrap">Sub Mark</th>
                          <th width="13%" class="iqbal-min-width text-center text-nowrap">Obj Mark</th>
                          <th width="13%" class="iqbal-min-width text-center text-nowrap">Prac Mark</th>
                          <th width="12%" class="iqbal-min-width text-center text-nowrap">CT Mark</th>
                          <th width="12%" class="iqbal-min-width text-center text-nowrap">Total</th>
                          <th width="12%" class="iqbal-min-width text-center text-nowrap">CGP</th>
                          <th width="13%" class="iqbal-min-width text-center text-nowrap">Grade</th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </thead>
                <tbody class v-if="data">
                  <slot v-for="(studentSubject,index) in data">
                    <tr :key="'C'+index">
                      <td>{{index+1}}</td>
                      <td class="text-nowrap">{{studentSubject.subject.name}}</td>
                      <td class="p-0" v-for="(exam,index) in studentSubject.exams" :key="'D'+index">
                        <table
                          class="table m-0"
                          rules="none"
                          paddingspace="0"
                          cellpadding="0"
                          border="0"
                        >
                          <tr v-if="exam.exam_mark">
                            <td
                              width="12%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.full_mark}}</td>
                            <td
                              width="13%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.total_sub_mark}}/{{exam.exam_mark.sub_mark}}</td>
                            <td
                              width="13%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.total_obj_mark}}/{{exam.exam_mark.obj_mark}}</td>
                            <td
                              width="13%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.total_prac_mark}}/{{exam.exam_mark.prac_mark}}</td>
                            <td
                              width="12%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.total_ct_mark}}/{{exam.exam_mark.ct_mark}}</td>
                            <td
                              width="12%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.total_obtained_marks}}</td>
                            <td
                              width="12%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.obtained_grade.grade_point}}</td>
                            <td
                              width="13%"
                              class="iqbal-min-width text-center"
                            >{{exam.exam_mark.obtained_grade.grade_letter}}</td>
                          </tr>
                          <tr v-else>
                            <td width="12%" class="iqbal-min-width text-center">--</td>
                            <td width="13%" class="iqbal-min-width text-center">--</td>
                            <td width="13%" class="iqbal-min-width text-center">--</td>
                            <td width="13%" class="iqbal-min-width text-center">--</td>
                            <td width="12%" class="iqbal-min-width text-center">--</td>
                            <td width="12%" class="iqbal-min-width text-center">--</td>
                            <td width="12%" class="iqbal-min-width text-center">--</td>
                            <td width="13%" class="iqbal-min-width text-center">--</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </slot>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tableList py-2 minHeight" v-else>
          <div class="row">
            <div class="col-12 text-center">No Data Found.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from "../../components/BaseTable.vue";
import Breadcrumb from "../../components/Breadcrumb";

const base_route = "Report";
const sub_route = "ResultSheet";
const title = "Result Sheet";

const breadcumb = {
  0: { title: "Report" },
  1: { title: title, route_name: base_route }
};

export default {
  components: {
    BaseTable,
    Breadcrumb
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: title,
      base_route: base_route,
      fields_name: { studentid: "Student ID" },
      search_data: {
        class_id: null,
        section_id: null,
        student_id: null,
        field_name: null,
        value: null
      },
      extradata: [],
      data: [],
      table: {
        title: "Result Sheet"
      }
    };
  },
  watch: {
    "search_data.class_id": function(val, oldval) {
      if (val) {
        this.getList2("Section", "section_list", val, "std_class_id");
        this.search_data.section_id = null;
        this.search_data.field_name = null;
        this.search_data.value = null;
      }
    },
    "search_data.section_id": function(val, oldval) {
      if (val) {
        this.getList2("Student", "student_list", val, "section_id");
        this.search_data.student_id = null;
      }
    }
  },
  methods: {
    get_datas: function(page_index) {
      this.$root.loading = true;
      let url = "/" + base_route + "/" + sub_route;
      if (page_index > 0) {
        url = "/" + base_route + "/" + sub_route + "/?page=" + page_index;
      }
      axios
        .get(url, { params: this.search_data })
        .then(respons => {
          this.data = respons.data.data;
          this.extradata = respons.data.extradata;
        })
        .catch(error => {
          console.log(error.respons);
        })
        .then(response => {
          this.$root.loading = false;
        });
    },
    getExamMark(exam_mark) {
      console.log(exam_mark);
    },
    destroy(obj) {
      axios
        .delete(base_route + "/" + obj.id)
        .then(respons => {
          this.get_datas();
        })
        .catch(error => {
          console.log(error);
        });
    },
    search() {
      this.get_datas();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount: function() {},
  mounted() {
    this.get_datas();
  },

  beforeUpdate: function() {},
  updated() {},
  beforeDestroy: function() {},
  destroyed: function() {}
};
</script>
<style>
.iqbal-min-width {
  min-width: 105px !important;
}
</style>

