<template>
  <div id="payment">
    <slot v-if="first_loader">
      <div class="d-flex" style="height: 190px !important">
        <div class="d-flex w-100 justify-content-center align-self-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </slot>
    <slot v-else>
      <div id="show_form" v-show="show_form">
        {{ timecheck(time) }}
        <div class="alert alert-danger" v-if="message.length > 0">
          <a
            href="#"
            data-dismiss="alert"
            aria-label="close"
            title="close"
            class="close"
            >×</a
          >
          {{ message }}
        </div>
        <div class="row">
          <button
            type="button"
            class="btn btn-primary ml-3 ml-0"
            data-toggle="modal"
            data-target="#myModal"
          >
            Answer's Brief
          </button>
          <!-- The Modal -->
          <div class="modal" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">
                    Answers Brief
                    <!-- <pre>  {{ exammm }}</pre> -->
                  </h4>
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body special-modal">
                  <div
                    class="tab-content"
                    v-if="Object.keys(exammm).length > 0"
                  >
                    <slot v-for="(qus, serial) in exammm">
                      <div
                        class="w-100"
                        :key="++serial"
                        :class="{ active: serial == 1 }"
                      >
                        <ol :start="serial">
                          <li>
                            <label>
                              <div v-html="qus.question"></div>
                            </label>
                            <div class="col-12">
                              <input type="hidden" :value="serial" />
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="1"
                                  unchecked-value
                                  disabled
                                ></b-form-checkbox>
                                {{ qus.option1 }}
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="2"
                                  unchecked-value
                                  disabled
                                ></b-form-checkbox>
                                {{ qus.option2 }}
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="3"
                                  unchecked-value
                                  disabled
                                ></b-form-checkbox>
                                {{ qus.option3 }}
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="4"
                                  unchecked-value
                                  disabled
                                ></b-form-checkbox>
                                {{ qus.option4 }}
                              </label>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </slot>
                  </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form v-on:submit.prevent="submit" class="w-100">
          <table style="width: 100%" class="tableclass">
            <tr colspan="3">
              <td colspan="3" class="text-center">
                <h3><b>Cantonment English School & College</b></h3>
                <h4 v-if="data.onlineExam.name">
                  <b>{{ data.onlineExam.name }}</b>
                </h4>
                <h5 v-if="data.onlineExam.subject">
                  <b>{{ data.onlineExam.subject.name }}</b>
                </h5>
                <input type="hidden" v-model="data.start_time" />
                <input type="hidden" v-model="data.onlineExam.id" />
                <input type="hidden" v-model="data.onlineExam.name" />
                <input type="hidden" v-model="data.onlineExam.std_class_id" />
                <input type="hidden" v-model="data.onlineExam.subject_id" />
                <input type="hidden" v-model="data.onlineExam.full_date" />
                <input type="hidden" v-model="data.onlineExam.total_mark" />
                <input type="hidden" v-model="data.onlineExam.question_mark" />
                <input type="hidden" v-model="data.onlineExam.auto_publish" />
              </td>
            </tr>
            <tr>
              <td class="text-left" style="width: 100%">
                <table style="width: 100%">
                  <tr>
                    <td>
                      <div class="row">
                        <div
                          class="col-lg-4 text_center_sm"
                          style="font-size: 14px"
                          v-if="data.onlineExam.class"
                        >
                          <b>Class</b> : {{ data.onlineExam.class.name }}
                        </div>
                        <div
                          class="col-lg-4 text-center"
                          style="font-size: 14px"
                        >
                          <b>Total</b> : {{ data.onlineExam.total_mark }} Marks
                        </div>
                        <div
                          class="col-lg-4 text_right_sm"
                          style="font-size: 14px"
                        >
                          <b>Question Left to answer</b> : {{ left_answer }} out
                          of {{ data.onlineExam.total_question }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="real_time != '00::00:00'">
                      <div
                        class="d-flex justify-content-center align-self-center"
                      >
                        <div class="timer_fixed" v-if="time != 'Invalid date'">
                          <i class="fas fa-clock"></i> : {{ time }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr v-if="left_answer > 0">
              <td class="text-center">
                <hr class="mt-2 mb-1" />
              </td>
            </tr>
            <tr>
              <td v-if="left_answer > 0">
                <div
                  class="tab-content"
                  v-if="Object.keys(data.questions).length > 0"
                >
                  <slot v-for="(qus, serial) in data.questions">
                    <div
                      :key="++serial"
                      class="tab-pane fade show"
                      :class="{ active: serial == 1 }"
                      :id="'nav-first' + serial"
                      role="tabpanel"
                      aria-labelledby="nav-first-tab"
                      href="#tab-1"
                    >
                      <ol :start="serial">
                        <li style="list-style-type: none; min-height: 200px">
                          <slot v-if="loader">
                            <div
                              class="d-flex"
                              style="height: 190px !important"
                            >
                              <div
                                class="d-flex w-100 justify-content-center align-self-center"
                              >
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                          </slot>
                          <slot v-else>
                            <label
                              style="
                                font-weight: bold;
                                width: 100%;
                                margin-bottom: 0px;
                              "
                            >
                              <span
                                style="
                                  font-weight: bold;
                                  float: left;
                                  width: 27px;
                                "
                                >{{ serial }}. &nbsp; </span
                              ><span
                                v-html="qus.question"
                                class="mquestion width_question"
                                style="font-weight: bold; float: left"
                              ></span>
                            </label>
                            <div class="col-12">
                              <input type="hidden" :value="serial" />
                            </div>
                            <div class="form-check-inline ml-4">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="1"
                                  unchecked-value
                                ></b-form-checkbox>
                                {{ qus.option1 }}
                              </label>
                            </div>
                            <div class="form-check-inline ml-4">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="2"
                                  unchecked-value
                                ></b-form-checkbox>
                                {{ qus.option2 }}
                              </label>
                            </div>
                            <div class="form-check-inline ml-4">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="3"
                                  unchecked-value
                                ></b-form-checkbox>
                                {{ qus.option3 }}
                              </label>
                            </div>
                            <div class="form-check-inline ml-4">
                              <label class="form-check-label">
                                <b-form-checkbox
                                  class="d-inline"
                                  v-model.number="qus.answers"
                                  value="4"
                                  unchecked-value
                                ></b-form-checkbox>
                                {{ qus.option4 }}
                              </label>
                            </div>
                          </slot>
                        </li>
                      </ol>
                      <span
                        class="btn btn-success float-left btn-md pointer ml-5"
                        @click="
                          changeTab(
                            serial,
                            serial + 1,
                            Object.keys(data.questions).length
                          )
                        "
                        style="cursor: pointer"
                        >Answer</span
                      >
                      <span
                        class="btn btn-warning float-right btn-md pointer"
                        @click="
                          skipTab(
                            serial,
                            serial + 1,
                            Object.keys(data.questions).length
                          )
                        "
                        style="cursor: pointer"
                        >Skip <i class="fas fa-arrow-right"></i
                      ></span>
                    </div>
                  </slot>
                </div>
                <div class="form-group col-12">
                  <button
                    type="button"
                    id="auto_submit"
                    @click="SubmitFunction"
                  ></button>
                </div>
              </td>
              <td v-else>
                <div class="col-12 mt-3 p-0">
                  <table
                    style="width: 100%; padding-left: 15px"
                    border="1"
                    class="table table-striped table-fixed table-bordered"
                  >
                    <tr>
                      <th>Name</th>
                      <th>{{ data.admission.name }}</th>
                    </tr>
                    <tr>
                      <th>Applicant Id</th>
                      <th>{{ data.admission.applicantid }}</th>
                    </tr>
                    <tr>
                      <th>Total Question</th>
                      <th>{{ data.onlineExam.total_question }}</th>
                    </tr>
                    <tr>
                      <th>Answered</th>
                      <th>{{ data.onlineExam.total_question }}</th>
                    </tr>
                  </table>
                  <div class="col-12" style="text-align: center">
                    <button
                      type="button"
                      class="btn btn-success mt-3"
                      @click="SubmitFunction"
                    >
                      <b>Submit to Finish Exam</b>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <nav>
            <div
              class="nav nav-tabs"
              id="nav-tab"
              role="tablist"
              v-if="Object.keys(data.questions).length > 0"
            >
              <a
                v-for="(questionId, index) in data.questions"
                :key="++index"
                class="nav-item nav-link"
                :class="{ active: index == 1 }"
                id="nav-first-tab"
                data-toggle="tab"
                :href="'#nav-first' + index"
                role="tab"
                aria-controls="nav-first"
                aria-selected="true"
              ></a>
            </div>
          </nav>
        </form>
      </div>
    </slot>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      admissionid: "",
      alreadysubmitted: "You already submitted all the questions answer.",
      exammm: {},
      filterdata: {
        questions: {},
        onlineExam: {},
      },
      data: {
        questions: [],
        onlineExam: {
          id: "",
          name: "",
          std_class_id: "",
          subject_id: "",
          full_date: "",
          total_mark: "",
          question_mark: "",
          auto_publish: "",
          subject: [],
          section: [],
          subject: [],
          admission: [],
        },
        admission_answer_ids: {
          final_submit: null,
        },

        start_time: moment().format("YYYY-MM-DD h:mm:ss A"),

        final_submit: null,
      },
      left_answer: 1,
      total_answer: 0,
      first_loader: true,
      loader: false,
      real_time: "00::00:00",
      errors: [],
      time_diff: "",
      auto_submit_time: "",
      get_now: moment().format(),
      show_form: true,
      message: "",
    };
  },
  methods: {
    SubmitFunction() {
      if (this.data.onlineExam.to_time != moment().format("H:ss")) {
        let total_question = Object.keys(this.data.questions).length;
        this.data.questions.forEach((ques) => {
          if (ques.answers) {
            this.total_answer = this.total_answer + 1;
          }
        });
        if (total_question > this.total_answer) {
          let not_answer = total_question - this.total_answer;
          this.message =
            not_answer +
            " Questions still not answered. Do you want to submit anyway?";
        }
        this.filterdata.final_submit = "yes";
        this.filterdata.start_time = this.data.start_time;
        this.filterdata.onlineExam.id = this.data.onlineExam.id;
        this.filterdata.onlineExam.subject_id = this.data.onlineExam.subject_id;
        this.filterdata.onlineExam.full_date = this.data.onlineExam.full_date;
        this.filterdata.onlineExam.total_mark = this.data.onlineExam.total_mark;
        axios
          .post("applicant/quiz-submit", this.filterdata)
          .then((respons) => {
            if (respons.data.error) {
              this.$toaster.error(respons.data.error);
            }
            if (respons.data.success) {
              this.$toaster.success(respons.data.success);
              window.location = this.$root.baseurl + "/applicant/online-exam";
              //this.$router.push("/admission/quiz");
            }
          })
          .catch((error) => {
            this.loaded = true;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
              this.focus_field = Object.keys(this.errors)[0];
            }
          });
        let eexam = JSON.parse(
          window.localStorage.getItem(
            this.$route.params.examId + this.$route.params.applicant_id
          )
        );
        this.data.questions.forEach(function (itemdata, index) {
          if (itemdata["answers"]) {
            eexam.find((obj) => {
              if (obj.id == itemdata["id"]) {
                obj.answers = itemdata["answers"];
              }
            });
          }
        });
        let eexamm = window.localStorage.getItem(
          this.$route.params.examId + this.$route.params.applicant_id
        );

        eexamm = window.localStorage.setItem(
          this.$route.params.examId + this.$route.params.applicant_id,
          JSON.stringify(eexam)
        );
        this.exammm = JSON.parse(
          window.localStorage.getItem(
            this.$route.params.examId + this.$route.params.applicant_id
          )
        );
      }
    },
    changeTab(Index, Next, Lenght) {
      //   console.log(Index);
      //   console.log(Next);
      //   console.log(Lenght);
      this.loader = true;
      if (
        $("#nav-first" + Index + ' input[type="checkbox"]:checked').length == 0
      ) {
        alert("Click atleast 1 Checkbox to view next page");
        this.loader = false;
        return false;
      } else {
        this.filterdata.final_submit = this.data.final_submit;
        this.filterdata.start_time = this.data.start_time;

        this.filterdata.questions.answers = this.data.questions[
          Index - 1
        ].answers;
        this.filterdata.questions.id = this.data.questions[Index - 1].id;
        this.filterdata.questions.online_exam_id = this.data.questions[
          Index - 1
        ].online_exam_id;
        this.filterdata.start_time = this.data.start_time;
        this.filterdata.onlineExam.id = this.data.onlineExam.id;
        this.filterdata.onlineExam.subject_id = this.data.onlineExam.subject_id;
        this.filterdata.onlineExam.full_date = this.data.onlineExam.full_date;
        this.filterdata.onlineExam.total_mark = this.data.onlineExam.total_mark;
        axios
          .post("applicant/quiz-submit", this.filterdata)
          .then((respons) => {
            this.loader = false;
            //   if (respons.data.error) {
            //     this.$toaster.error(respons.data.error);
            //   }
          })
          .catch((error) => {
            this.loader = false;
            //   this.loaded = true;
            //   if (error.response.status === 422) {
            //     this.errors = error.response.data.errors || {};
            //     this.focus_field = Object.keys(this.errors)[0];
            //   }
          });
        let total_question = Object.keys(this.data.questions).length;
        let not_checked_question = $(".tableclass input:checkbox:checked")
          .length;
        this.left_answer = total_question - not_checked_question;
        let eexam = JSON.parse(
          window.localStorage.getItem(
            this.$route.params.examId + this.$route.params.applicant_id
          )
        );
        this.data.questions.forEach(function (itemdata, index) {
          if (itemdata["answers"]) {
            eexam.find((obj) => {
              if (obj.id == itemdata["id"]) {
                obj.answers = itemdata["answers"];
              }
            });
          }
        });
        let eexamm = window.localStorage.getItem(
          this.$route.params.examId + this.$route.params.applicant_id
        );
        eexamm = window.localStorage.setItem(
          this.$route.params.examId + this.$route.params.applicant_id,
          JSON.stringify(eexam)
        );
        this.exammm = JSON.parse(
          window.localStorage.getItem(
            this.$route.params.examId + this.$route.params.applicant_id
          )
        );
      }
      if (Lenght < Next) {
        Next = 1;
      }
      let i = Next;

      for (i; i <= Lenght; i++) {
        if (
          $("#nav-first" + i + ' input[type="checkbox"]:checked').length == 0
        ) {
          Next = i;
          break;
        } else {
          Next = Index;
        }
      }
      if (Next == Index && this.left_answer > 0) {
        for (let j = 1; j <= Lenght; j++) {
          if (
            $("#nav-first" + j + ' input[type="checkbox"]:checked').length == 0
          ) {
            Next = j;
            break;
          }
          console.log("next: " + j);
        }
      }
      if (this.left_answer == 0) {
        this.message =
          "You Already answered all the question. Press Submit to finish your exam";
        return false;
        this.message = "You already submitted all the questions asnwer";
      }

      $('#nav-tab a[href="#nav-first' + Next + '"]').tab("show");
    },

    skipTab(Index, Next, Lenght) {
      if (
        $("#nav-first" + Index + ' input[type="checkbox"]:checked').length != 0
      ) {
        if (
          !confirm(
            "You already submitted this question answer. It will be saved and this question will never be shown. If you want to skip this question uncheck your answer of this question. Or if you want to contine click ok"
          )
        ) {
          return false;
        } else {
          this.filterdata.final_submit = this.data.final_submit;
          this.filterdata.start_time = this.data.start_time;

          this.filterdata.questions.answers = this.data.questions[
            Index - 1
          ].answers;
          this.filterdata.questions.id = this.data.questions[Index - 1].id;
          this.filterdata.questions.online_exam_id = this.data.questions[
            Index - 1
          ].online_exam_id;
          this.filterdata.start_time = this.data.start_time;
          this.filterdata.onlineExam.id = this.data.onlineExam.id;
          this.filterdata.onlineExam.subject_id = this.data.onlineExam.subject_id;
          this.filterdata.onlineExam.full_date = this.data.onlineExam.full_date;
          this.filterdata.onlineExam.total_mark = this.data.onlineExam.total_mark;
          this.loader = true;
          axios
            .post("applicant/quiz-submit", this.filterdata)
            .then((respons) => {
              //   if (respons.data.error) {
              //     this.$toaster.error(respons.data.error);
              //   }
              //   if (respons.data.success) {
              //     this.$toaster.success(respons.data.success);
              //     this.$router.push("/admission/quiz");
              //   }
              this.loader = false;
            })
            .catch((error) => {
              //   this.loaded = true;
              //   if (error.response.status === 422) {
              //     this.errors = error.response.data.errors || {};
              //     this.focus_field = Object.keys(this.errors)[0];
              //   }
              this.loader = false;
            });
          let total_question = Object.keys(this.data.questions).length;
          let not_checked_question = $(".tableclass input:checkbox:checked")
            .length;
          this.left_answer = total_question - not_checked_question;
          let eexam = JSON.parse(
            window.localStorage.getItem(
              this.$route.params.examId + this.$route.params.applicant_id
            )
          );
          this.data.questions.forEach(function (itemdata, index) {
            if (itemdata["answers"]) {
              eexam.find((obj) => {
                if (obj.id == itemdata["id"]) {
                  obj.answers = itemdata["answers"];
                }
              });
            }
          });
          let eexamm = window.localStorage.getItem(
            this.$route.params.examId + this.$route.params.applicant_id
          );
          eexamm = window.localStorage.setItem(
            this.$route.params.examId + this.$route.params.applicant_id,
            JSON.stringify(eexam)
          );
          this.exammm = JSON.parse(
            window.localStorage.getItem(
              this.$route.params.examId + this.$route.params.applicant_id
            )
          );
        }
      }
      if (
        $("#nav-first" + Next + ' input[type="checkbox"]:checked').length > 0
      ) {
        let i = Next + 1;
        for (i; i <= Lenght; i++) {
          if (
            $("#nav-first" + i + ' input[type="checkbox"]:checked').length == 0
          ) {
            Next = i;
            console.log("i else in break: " + Next);
            break;
          } else {
            Next = Index;
          }
        }
      }
      if (Next > Lenght && this.left_answer > 0) {
        for (let j = 1; j <= Lenght; j++) {
          if (
            $("#nav-first" + j + ' input[type="checkbox"]:checked').length == 0
          ) {
            Next = j;
            break;
          }
        }
      }
      if (this.left_answer == 0) {
        alert("You Already answered all the question");
        return false;
        this.alreadysubmitted =
          "You already submitted all the questions asnwer.";
      }
      $('#nav-tab a[href="#nav-first' + Next + '"]').tab("show");
      this.loader = false;
    },

    increment: function (inc) {
      return inc + 1;
    },
    timecheck(time) {
      if (time == "00:00:10") {
        this.message =
          "Your time is allmost over, so your answer is automatically submited";
      }
      if (time == "00:00:00") {
        this.show_form = false;
      }
    },
  },
  computed: {
    time: function () {
      if (this.real_time != "00::00:00") {
        return moment(this.real_time).format("HH:mm:ss");
      }
    },
  },
  mounted: function () {
    setInterval(() => {
      this.real_time = moment(this.real_time).subtract(1, "seconds");
      if (this.auto_submit_time == moment().format("HH:mm")) {
        this.show_form = false;
        $("#auto_submit").click();
      }
    }, 1000);
  },
  created() {
    this.first_loader = true;
    this.$root.loading = true;
    // axios.get("admission/profileid").then((response) => {
    //   this.admissionid = response.data;
    // });
    axios
      .get(
        "applicant/quiz-detail/" +
          this.$route.params.examId +
          "/" +
          this.$route.params.applicant_id
      )
      .then((response) => {
        this.first_loader = false;
        this.data.questions = response.data.question.sort(function (a, b) {
          return 0.5 - Math.random();
        });
        this.data.onlineExam = response.data.onlineExam;
        this.data.admission = response.data.admission;
        this.data.class = response.data.class;
        this.data.subject = response.data.subject;
        let eexam = window.localStorage.getItem(
          this.$route.params.examId + this.$route.params.applicant_id
        );
        if (!eexam) {
          eexam = window.localStorage.setItem(
            this.$route.params.examId + this.$route.params.applicant_id,
            JSON.stringify(response.data.question)
          );
          // this.$router.go();
        }
        this.exammm = JSON.parse(eexam);
        this.data.onlineExam = response.data.onlineExam;
        this.real_time =
          response.data.onlineExam.date + " " + response.data.since_start;
        this.auto_submit_time = response.data.onlineExam.to_time;
        this.data.admission_answer_ids = response.data.admission_answer_ids;
        var x = new moment(
          response.data.onlineExam.date + " " + response.data.onlineExam.to_time
        );
        var y = new moment(
          response.data.onlineExam.date + " " + response.data.onlineExam.time
        );
        this.time_diff = x.diff(y, "minutes");
        if (response.data.admission_answer_ids) {
          //   if (response.data.admission_answer_ids.final_submit == "yes") {
          //     this.$router.push("/admission/quiz");
          //   }
        }
        if (response.data.time_over == "yes") {
          localStorage.removeItem(
            this.$route.params.examId + this.$route.params.applicant_id
          );
          this.$router.push("/admission/quiz");
        }
        this.left_answer = Object.keys(response.data.question).length;
        if (this.left_answer < 1) {
          this.alreadysubmitted =
            "You already submitted all the questions asnwer.";
        }
      });
    this.$root.loading = false;
  },
};
</script>

<style scoped>
    ol li {
        padding: 5px;
        margin-left: 25px;
    }
    ol {
        margin-left: 0px !important;
        padding-left: 0px !important;
        padding-top: 10px !important;
    }
    #nav-tab {
        display: none;
    }
    #auto_submit {
        display: none;
    }
    ol li {
        margin-bottom: 20px;
    }
    .displaynone {
        display: none;
    }
    .modal-body.special-modal::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: transparent;
        top: 0;
        left: 0;
        z-index: 99999;
    }
</style>
