<template>
  <div class="student">
    <div class="card shadow-sm border-top-custom">
      <div class="card-body p-2">
        <div class="row">
          <div class="col-6">
            <form v-on:submit.prevent="submit" class="form-row px-3">
              <input type="hidden" v-model="data.id" />
              <div class="form-group col-6">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="studentid"
                  >StudentID</label
                >
                <input
                  v-model="data.studentid"
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="StudentId"
                />
                <small v-if="errors && errors.studentid" class="text-danger">{{
                  errors.studentid[0]
                }}</small>
              </div>
              <div class="form-group col-6">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="name"
                  >Roll</label
                >
                <input
                  v-model="data.roll"
                  type="name"
                  class="form-control form-control-sm"
                  placeholder="Roll"
                />
                <small v-if="errors && errors.roll" class="text-danger">{{
                  errors.roll[0]
                }}</small>
              </div>
              <div class="form-group col-6">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="mobile_no"
                  >Password For Login</label
                >
                <input
                  name="password"
                  v-model="data.password"
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                />
                <small v-if="errors && errors.password" class="text-danger">{{
                  errors.password[0]
                }}</small>
              </div>
              <div class="form-group col-6">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="House"
                  >House</label
                >
                <b-form-select
                  v-model="data.house_id"
                  :options="extradata.house_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>Please select One</option>
                  </template>
                </b-form-select>
                <small v-if="errors && errors.selected" class="text-danger">{{
                  errors.selected[0]
                }}</small>
              </div>
              <div class="form-group col-6">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="name"
                  >Section</label
                >
                <select
                  name
                  id
                  class="form-control form-control-sm rounded-0"
                  v-model="data.section_id"
                >
                  <option disabled value>Please select one</option>
                  <option
                    v-for="(value, name, index) in extradata.section_list"
                    :key="index"
                    v-bind:value="name"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>

              <div class="form-group col-6 col-offset-3">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="admission_result"
                  >Total Number</label
                >
                <input
                  v-model.number="totalNumber"
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Total Number"
                  readonly
                />
                <small
                  v-if="errors && errors.totalNumber"
                  class="text-danger"
                  >{{ errors.totalNumber[0] }}</small
                >
              </div>
              <div class="w-100"></div>
              <div class="form-group col-3">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="admission_result"
                  >Bangla</label
                >
                <input
                  v-model.number="data.bangla_number"
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Bangla"
                />
              </div>
              <div class="form-group col-3">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="admission_result"
                  >English</label
                >
                <input
                  v-model.number="data.english_number"
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="English"
                />
              </div>
              <div class="form-group col-3">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="admission_result"
                  >Math</label
                >
                <input
                  v-model.number="data.math_number"
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Math"
                />
              </div>
              <div class="form-group col-3">
                <label
                  class="col-form-label-sm font-weight-bold mb-0"
                  for="admission_result"
                  >Others</label
                >
                <input
                  v-model.number="data.others_number"
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Others"
                />
              </div>

              <div class="form-group col-12">
                <button
                  @click="hideModal"
                  class="btn btn-sm btn-primary mt-3"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="col-6">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="3">
                      <strong>Admission Information</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="record.name">
                    <th>Name</th>
                    <td>:</td>
                    <td>{{ record.name }}</td>
                  </tr>
                  <tr v-if="record.email">
                    <th>Email</th>
                    <td>:</td>
                    <td>{{ record.email }}</td>
                  </tr>
                  <tr v-if="record.mobile">
                    <th>Mobile No</th>
                    <td>:</td>
                    <td>{{ record.mobile }}</td>
                  </tr>
                  <tr v-if="record.date_of_birth">
                    <th>DOB</th>
                    <td>:</td>
                    <td>{{ record.date_of_birth | formatDate }}</td>
                  </tr>
                  <tr v-if="record.gender">
                    <th>Gender</th>
                    <td>:</td>
                    <td>{{ record.gender }}</td>
                  </tr>
                  <tr v-if="record.student_type">
                    <th>Student Type</th>
                    <td>:</td>
                    <td>{{ record.student_type }}</td>
                  </tr>
                  <tr v-if="record.std_class.name">
                    <th>Class</th>
                    <td>:</td>
                    <td>{{ record.std_class.name }}</td>
                  </tr>
                  <tr v-if="record.group">
                    <th>Group</th>
                    <td>:</td>
                    <td>{{ record.group }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/Breadcrumb";
const breadcumb = {
  0: {
    title: "Student",
    route_name: "student.index",
    params: {},
  },
  1: {
    title: "Add Student",
    route_name: "student.create",
    params: {},
  },
};
export default {
  components: {
    Breadcrumb,
  },
  props: {
    record: {
      type: Object,
      default: () => [],
      description: "Current DB data ",
    },
  },
  computed: {
    totalNumber: function () {
      var total = 0;
      this.data.total_number =
        Number(this.data.bangla_number) +
        Number(this.data.english_number) +
        Number(this.data.math_number) +
        Number(this.data.others_number);
      return this.data.total_number;
    },
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Student",
      txt: "Form",
      stdclass: [],
      section: [],
      extradata: {
        section_list: [],
        house_list: [],
      },
      data: {
        house_id: null,
        admission_id: this.record.id,
        std_class_id: "",
        section_id: "",
        studentid: "",
        gender: "",
        religion: "",
        blood: "",
        bangla_number: 0,
        math_number: 0,
        english_number: 0,
        others_number: 0,
        total_number: 0,
      },
      errors: {},
      focus_field: "",
    };
  },
  methods: {
    submit: function () {
        this.$root.loading = true;
      axios
        .post("admission_student_add", this.data)
        .then((respons) => {
          this.record.is_approved = "Approved";
        })
        .then(() => this.$root.loading = false);
    },
    get_data: function (id) {
      this.$root.loading = true;
      axios
        .get("student/" + id)
        .then((respons) => {
          this.data = respons.data;
        })
        .then(() => {
          this.$root.loading = false;
        });
    },
    get_class: function () {
      axios
        .get("/classlist/")
        .then((response) => {
          this.stdclass = response.data;
        });
    },
    get_section: function (class_id = "") {
      axios
        .get("/sectionlist/" + class_id)
        .then((respons) => {
          this.section = respons.data;
        });
    },
    generatestudentid: function () {
      axios
        .get("/generatestudentid/")
        .then((respons) => {
          this.data.studentid = String(respons.data);
        });
    },
    hideModal() {
      this.$parent.$parent.$parent.hideModal();
    },
  },
  mounted() {
    this.generatestudentid();
    this.get_class();
    Object.assign(this.data, this.record);
    this.getHouseList();
    this.getSectionList(this.record.std_class_id, "std_class_id");
  },
};
</script>

