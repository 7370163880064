<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <slot v-if="notice.image">
            <div class="col-xs-12 col-lg-5">
              <img :src="notice.FullUrl+notice.image" class="img-fluid" alt="..." />
            </div>
            <div class="col-xs-12 col-lg-7" data-aos="fade-right">
              <!--Heading-->
              <h5 class="font-weight-bold text-uppercase">{{notice.title}}</h5>
              <!--Text-->
              <p class="text-justify" v-html="notice.description"></p>
            </div>
          </slot>
          <slot v-else>
            <div class="col-xs-12 col-lg-12" data-aos="fade-right">
              <h5 class="font-weight-bold text-uppercase">{{notice.title}}</h5>
              <p class="text-justify" v-html="notice.description"></p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Notice"
  }
];
export default {
  name: "Notice",
  components: {
    Banner
  },
  data() {
    return {
      notice: {},
      breadcrumbTitle: "",
      breadcumb: breadcumb
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/notice_details/" + this.$route.params.slug)
        .then(respons => {
          this.$root.loading = false;
          this.notice = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = [
            { title: "Notice", route_name: "notice" },
            { title: this.breadcrumbTitle }
          ];
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
