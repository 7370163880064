<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <h4 class="m-0 font-weight-bold">{{ breadcrumbTitle }}</h4>
            <hr />
          </div>
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-striped table-fixed table-bordered">
                <thead>
                  <tr class="thead-dark">
                    <th>SL</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in table.datas" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.title }}</td>
                    <td>
                      <p v-html="item.description"></p>
                    </td>
                    <td style="text-align: center">
                      <router-link
                        v-if="item.type === 'content'"
                        :to="{
                          name: 'notice.detail',
                          params: { category: item.category, slug: item.slug },
                        }"
                        class="nav-link"
                      >
                        <span class="fas fa-eye"></span>
                      </router-link>
                      <a
                        v-else
                        :href="item.FullUrl + item.file"
                        target="_blank"
                        class="nav-link"
                      >
                        <span class="fab fa fa-download"></span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="page-footer" v-if="meta.last_page > 1">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12 col-md-5 p-0">
                    <div role="status" aria-live="polite">
                      Showing {{ meta.from }} to {{ meta.to }} of
                      {{ meta.total }} entries
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-7 p-0">
                    <nav aria-label="Page navigation example">
                      <ul
                        class="pagination pagination-sm justify-slider_content-end"
                      >
                        <li class="page-item">
                          <a
                            class="page-link"
                            v-on:click="get_datas(1)"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li class="page-item" v-if="meta.current_page > 2">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page - 2)"
                            >{{ meta.current_page - 2 }}</a
                          >
                        </li>
                        <li class="page-item" v-if="meta.current_page > 1">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page - 1)"
                            >{{ meta.current_page - 1 }}</a
                          >
                        </li>
                        <li class="page-item active">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page)"
                            >{{ meta.current_page }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          v-if="meta.current_page < meta.last_page"
                        >
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page + 1)"
                            >{{ meta.current_page + 1 }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          v-if="meta.current_page + 1 < meta.last_page"
                        >
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.current_page + 2)"
                            >{{ meta.current_page + 2 }}</a
                          >
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            v-on:click="get_datas(meta.last_page)"
                            aria-label="Next"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "",
  },
];
const routesname = {
  view: "notice",
};
export default {
  name: "About",
  components: {
    Banner,
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Notice",
      fields_name: { 0: "Select One", description: "Description" },
      search_data: {
        field_name: 0,
        value: "",
      },
      table: {
        title: "Simple Table",
        routes: routesname,
        datas: [],
      },
      pagination: [],
      meta: [],
      links: [],
    };
  },
  methods: {
    get_datas: function (pageindex) {
      this.$root.loading = true;
      let url = "/home/notices/" + this.$route.params.category;
      if (pageindex > 0) {
        url =
          "/home/notices/" + this.$route.params.category + "?page=" + pageindex;
      }
      axios
        .get(url)
        .then((respons) => {
          this.breadcumb = [{ title: this.$route.params.category }];
          this.breadcrumbTitle = this.$route.params.category;
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          this.$parent.debug_data = respons;
          this.$root.loading = false;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_datas();
  },
};
</script>
