<template>
  <div class="about">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <left-side></left-side>
          </div>
          <div class="col-md-9">
            Payment
            <p>Name : {{$route.query.name}}</p>
          </div>
        </div>
      </div>
      {{errors}}
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Applicant Dashboard"
  }
];
export default {
  name: "GetReq",
  components: {
    Banner
  },
  data() {
    return {
      breadcrumbTitle: "",
      text: "",
      errors: {},
      breadcumb: breadcumb,
      applicant: {
        info: null
      }
    };
  },
  methods: {},
  mounted() {}
};
</script>
