<template>
  <div class="teacher" style="margin-top:100px">
    <!-- <Breadcrumb :msg="breadcrumbTitle" :data="breadcumb"></Breadcrumb> -->
    <div class="card shadow-sm border-top-custom">
      <div class="card-header py-3">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <!-- <form v-on:submit.prevent="search" class="form-row px-3">
              <div class="form-group mb-0 col-2 p-0">
                <select class="form-control form-control-sm rounded-0" v-model="search_data.field_name" >
                  <option v-for="(value, name, index) in fields_name" :key="index" v-bind:value="name" >{{ value }}</option>
                </select>
              </div>
              <div class="form-group mb-0 col-4 p-0">
                <input type="text" v-model="search_data.value" class="form-control form-control-sm rounded-0" placeholder="Type your text" />
              </div>
              <div class="form-group mb-0 col-2">
                <button type="submit" class="btn btn-sm btn-primary rounded-0">
                  <span class="fa fa-search form-control-feedback"></span> Search
                </button>
              </div>
            </form>-->
          </div>
          <!-- <div class="col-md-3 ml-auto pr-0 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
              <router-link
                v-if="$root.checkPermission(base_route+'.create')"
                :to="{name:base_route+'.create'}"
                class="btn btn-success btn-sm"
              >
                <i class="fas fa-plus"></i>
                Add {{breadcrumbTitle}}
              </router-link>
            </div>
          </div>-->
        </div>
      </div>
      <div v-cloak class="card-body">
        <div class="tableList py-2 minHeight">
          <div :title="table.title">
            <div class="table-responsive">
              <table border="1" class="table tablesorter border table-hover">
                <thead class="bg-light text-secondary">
                  <tr>
                    <th class="align-text-top text-nowrap">SN</th>
                    <th class="align-text-top text-nowrap">Class</th>
                    <th class="align-text-top text-nowrap">Section</th>
                    <th class="align-text-top text-nowrap">Created</th>
                    <th class="align-text-top text-nowrap">Modified</th>
                    <th class="align-text-top text-nowrap">Action</th>
                    <!---->
                    <!---->
                  </tr>
                </thead>
                <tbody class>
                  <tr v-for="(value,index) in table.datas" :key="index">
                    <td>{{index+1}}</td>
                    <td v-if="true">
                      <span>{{value.std_class.name}}</span>
                    </td>
                    <td v-if="true">
                      <span>{{value.section.name}}</span>
                    </td>
                    <td v-if="true">
                      <span>{{value.updated_at|formatDate("MMM Do YYYY")}}</span>
                    </td>
                    <td v-if="true">
                      <span>{{value.created_at|formatDate("MMM Do YYYY")}}</span>
                    </td>
                    <td v-if="true">
                      <span>
                        <router-link class="btn btn-info" :to="'routineDetails/'+value._id">View</router-link>
                      </span>
                    </td>
                    <!---->
                    <!---->
                  </tr>
                </tbody>
              </table>
              <!-- <base-table
                :data="table.datas"
                :columns="table.columns"
                :routes="table.routes"
                thead-classes="bg-light text-secondary"
              ></base-table>-->
              <!-- <pre>{{table}}</pre> -->
            </div>
          </div>
        </div>
        <div class="page-footer" v-if="meta.last_page>1">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12 col-md-5 p-0">
                <div
                  role="status"
                  aria-live="polite"
                >Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div>
              </div>
              <div class="col-sm-12 col-md-7 p-0">
                <nav aria-label="Page navigation example">
                  <ul class="pagination pagination-sm justify-content-end">
                    <li class="page-item">
                      <a class="page-link" v-on:click="get_datas(1)" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item" v-if="meta.current_page>2">
                      <a
                        class="page-link"
                        v-on:click="get_datas(meta.current_page-2)"
                      >{{meta.current_page-2}}</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page>1">
                      <a
                        class="page-link"
                        v-on:click="get_datas(meta.current_page-1)"
                      >{{meta.current_page-1}}</a>
                    </li>
                    <li class="page-item active">
                      <a
                        class="page-link"
                        v-on:click="get_datas(meta.current_page)"
                      >{{meta.current_page}}</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page<meta.last_page">
                      <a
                        class="page-link"
                        v-on:click="get_datas(meta.current_page+1)"
                      >{{meta.current_page+1}}</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page+1<meta.last_page">
                      <a
                        class="page-link"
                        v-on:click="get_datas(meta.current_page+2)"
                      >{{meta.current_page+2}}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" v-on:click="get_datas(meta.last_page)" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from "../../../components/BaseTable.vue";
import Breadcrumb from "../../../components/Breadcrumb";
const tableColumns = [
  // { field: "_id", title: "Id" },
  // { field: "name", title: "Process Name" },
  { field: "std_class", title: "Class", subfield: "name" },
  { field: "section", title: "Section", subfield: "name" },
  { field: "created_at", title: "Create", datetime: true },
  { field: "updated_at", title: "Modified", datetime: true }
];
const base_route = "classroutine";
const routes_name = {
  view: base_route + ".show",
  edit: base_route + ".edit",
  destroy: base_route + ".destroy"
};
const breadcumb = {
  0: {
    title: "Class Routine",
    route_name: base_route + ".index"
  }
};
const tableData = [];
export default {
  components: {
    BaseTable,
    Breadcrumb
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Class Routine",
      base_route: base_route,
      dominions: [],
      countryName: ["Country", "Bangladesh", "India", "Pakistan"],
      fields_name: { 0: "Select One", name: "Name" },
      search_data: {
        field_name: 0,
        value: ""
      },
      table: {
        title: "Simple Table",
        columns: tableColumns,
        routes: routes_name,
        datas: []
      },
      pagination: [],
      meta: [],
      links: []
    };
  },
  methods: {
    get_datas: function(page_index) {
      let url = "/api/classroutine";
      if (page_index > 0) {
        url = "/api/classroutine";
      }
      axios
        .get(url, { params: this.search_data })
        .then(respons => {
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          // this.$parent.$parent.debug_data = respons;
          // console.log(respons);
        })
        .catch(error => {
          this.$parent.debug_data = error;
          console.log(error.respons);
        })
        .then(response => {
          this.$root.loading = false;
        });
    },
    destroy(obj) {
      axios
        .delete(base_route + "/" + obj._id)
        .then(respons => {
          this.get_datas();
        })
        .catch(error => {
          console.log(error);
        });
    },
    search() {
      this.get_datas();
    }
  },
  beforeCreate() {},
  created() {
    // this.$root.loading=false
    // setTimeout(() => this.$root.loading = false,100)
  },
  beforeMount: function() {},
  mounted() {
    this.get_datas();
    //this.dominionList();
    // setTimeout(() => this.$root.loading = false,400)
  },

  beforeUpdate: function() {
    // this.$parent.$parent.$parent.debug_data=this.data
  },
  updated() {
    //  console.log('updated process');
    //  console.log(this.$parent.$parent.$parent.debug_data);
  },
  beforeDestroy: function() {},
  destroyed: function() {}
};
</script>

