<template>
  <div class="welcome" v-if="!$root.loading">
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center" v-if="welcometext.image">
          <div
            class="col-12 col-md-6 order-md-2"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-duration="500"
          >
            <!--Image-->
            <img :src="welcometext.FullUrl+welcometext.image" class="img-fluid" alt />
          </div>
          <div
            class="col-12 col-md-6 order-md-1"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-duration="1000"
          >
            <!--Heading-->
            <h2>
              Welcome to
              <br />
              <span class="text-theme3">{{welcometext.title}}</span>
            </h2>
            <!--Text-->
            <p class="font-size-lg text-justify text-dark mb-6 mb-md-6">
              <!-- <span v-html='welcometext.description'></span> -->
              {{ getPostBody(welcometext.description,400) }}
            </p>
            <router-link
              :to="{ name:'content_detail', params: { slug: welcometext.slug }}"
              class="btn bg-theme3 text-white lift"
            >View All</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Welcome",
  data() {
    return {
      welcometext: {}
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/welcometext")
        .then(respons => {
          this.welcometext = respons.data.welcometext;
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
