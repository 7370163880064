<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-10">
            <div class="col-lg-12 pl-0">
              <b>Name:</b>
              {{teacher.name}}
            </div>
            <div class="col-lg-12 pl-0">
              <b>Designation:</b>
              {{teacher.designation.name}}
            </div>
            <div class="col-lg-12 pl-0">
              <b>Email:</b>
              {{teacher.email}}
            </div>
            <div class="col-lg-12 pl-0">
              <b>Mobile:</b>
              {{teacher.mobile_no}}
            </div>
            <div class="col-lg-12 pl-0">
              <b>Religion:</b>
              {{teacher.religion}}
            </div>
            <div class="col-lg-12 pl-0">
              <b>Blood Group:</b>
              {{teacher.blood_gorup}}
            </div>
          </div>
          <div class="col-lg-2">
            <img
              v-if="teacher.image"
              :src="$root.baseurl +teacher.image"
              class="img-fluid"
              alt="..."
            />
            <img
              v-else
              :src="$root.baseurl + '/images/no-image.png'"
              style="width: 180px;"
              alt="..."
            />
          </div>
          <div class="col-lg-12">
            <b>Father Name:</b>
            {{teacher.father_name}}
          </div>
          <div class="col-lg-12">
            <b>Mother Name:</b>
            {{teacher.mother_name}}
          </div>
          <div class="col-lg-12">
            <b>Present address:</b>
            {{teacher.present_address}}
          </div>
          <div class="col-lg-12">
            <b>Permanent Address:</b>
            {{teacher.permanent_address}}
          </div>
          <div class="col-lg-12">
            <b>National ID:</b>
            {{teacher.nationalid}}
          </div>
          <div class="col-lg-12">
            <b>Country:</b>
            {{teacher.country}}
          </div>
          <div class="col-lg-12">
            <b>MPO Information:</b>
            {{teacher.mpo_info}}
          </div>
          <div class="col-lg-12">
            <b>Special Features:</b>
            {{teacher.special_features}}
          </div>
          <div class="col-lg-12">
            <b>Education Information:</b>
            <table style="width: 100%;margin-top: 10px;" class="table table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th width="10">Sl</th>
                  <th>Exam</th>
                  <th>Institute</th>
                  <th>Year</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody class="qualification">
                <tr v-for="(item, index) in teacher.educations" :key="index">
                  <td width="10">{{ index+1 }}</td>
                  <td>{{item.exam_name}}</td>
                  <td>{{item.institute_name}}</td>
                  <td>{{item.passing_year}}</td>
                  <td>{{item.result}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-12">
            <b>Experience Information:</b>
            <table style="width: 100%;margin-top: 10px;" class="table table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th width="10">Sl</th>
                  <th>Institute Name</th>
                  <th>Designation</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody class="qualification">
                <tr v-for="(item, index) in teacher.teacher_employments" :key="index">
                  <td width="10">{{ index+1 }}</td>
                  <td>{{item.institute_name}}</td>
                  <td>{{item.designation}}</td>
                  <td>{{item.from_date}}</td>
                  <td>{{item.to_date}}</td>
                  <td>{{item.remarks}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Teacher Details"
  }
];
export default {
  name: "TeacherDetails",
  components: {
    Banner
  },
  data() {
    return {
      teacher: {},
      breadcrumbTitle: "",
      breadcumb: breadcumb
    };
  },
  methods: {
    get_data() {
      axios
        .get("/home/teacher_details/" + this.$route.params.id)
        .then(respons => {
          this.teacher = respons.data.result;
          this.breadcrumbTitle = respons.data.result.name;
          this.breadcumb = [
            { title: "Teacher Details", route_name: "teacherList" },
            { title: this.breadcrumbTitle }
          ];
        })
        .catch(error => {
          console.log(error);
        })
        .then(always => {
          this.$root.loading = false;
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
