<template>
    <header class="navbar pcoded-header navbar-expand-md navbar-light d-none d-lg-block">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mobile-header">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="mobile-header">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item b-brand">
                    <h3> <span class="b-bg">
                        <i class="feather icon icon-home"></i>
                        </span> Cantonment English School & College
                    </h3>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li>
                    <div class="dropdown drp-user">
                        <a href="javascript:" class="dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-cogs"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right profile-notification">
                            <div class="pro-head">
                                <img :src="$root.baseurl + image_src" class="img-radius" alt="User-Profile-Image">
                                <span v-if="$parent.data.studentid">{{$parent.data.name}}</span>
                            </div>
                            <ul class="pro-body">
                                <li>
                                    <a href="javascript:" class="dropdown-item">
                                        <i class="fa fa-cog"></i> Settings
                                    </a>
                                </li>
                                <li>
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'student.profile',
                                                  params: { dparams: $route.params },}">
                                        <i class="fa fa-user"></i> Profile
                                    </router-link>
                                </li>
                                <li >
                                    <a @click="logout()"
                                       href="javascript:void(0)"
                                       class="nav-link"
                                    ><i class="fa fa-lock"></i> Logout</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
    import image from "../../../../assets/img/avatar.png";

    export default {
        name: "Topbar",
        data() {
            return {
                image_src: image,
            }
        },
        methods: {
            full_logout: function () {
                axios.post("logout").then((res) => {
                    this.$root.student.login = false;
                    this.$root.roleList = [];
                    this.$root.allDominionList =[];
                    this.$root.variable = [];
                    this.$root.variables = {};
                    this.$root.logged_user =[];
                    this.$root.user_permissions = [];
                    this.$router.push("/");
                }).then((always) => {
                    this.$root.loading = false;
                });
            },
            logout: function () {
                this.$root.loading = true;
                axios
                    .get("student/cahse_clear")
                    .then((response) => {
                        if (response) {
                            this.full_logout();
                        }
                        //this.full_logout();
                    })
                    .catch((errer) => {
                    }).then((always) => {
                    this.$root.loading = false;
                });
            },
        },
    }
</script>

<style scoped>

</style>
