<template>
  <div class="about">
    <div class="row student-profile">
      <div class="row align-items-center mb-3" style="margin: auto">
        <h5 class="font-weight-bold text-uppercase">Profile</h5>
      </div>
      <div class="col-lg-12" v-if="$parent.applicantinfo">
        <table class="table table-bordered table-striped">
          <tr>
            <td class="title">Applicant ID</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.applicantid }}</td>
            <td rowspan="6" class="image">
              <img
                v-if="$parent.applicantinfo.image"
                width="200"
                :src="$root.baseurl + $parent.applicantinfo.image"
                alt
              />
              <img v-else width="200" :src="image_src" alt />
            </td>
          </tr>
          <tr>
            <td class="title">Category</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.category }}</td>
          </tr>
          <tr>
            <td class="title">Class</td>
            <td class="colon">:</td>
            <td v-if="$parent.applicantinfo.std_class">
              {{ $parent.applicantinfo.std_class.name }}
            </td>
          </tr>
          <tr v-if="$parent.applicantinfo.group">
            <td class="title">Group</td>
            <td class="colon">:</td>
            <td>
              {{ $parent.applicantinfo.group }}
            </td>
          </tr>

            <tr v-if="$parent.applicantinfo.jsc_reg">
                <td class="title">JSC Registration No</td>
                <td class="colon">:</td>
                <td>
                    {{ $parent.applicantinfo.jsc_reg }}
                </td>
            </tr>

            <tr v-if="$parent.applicantinfo.ssc_reg">
                <td class="title">SSC Registration No</td>
                <td class="colon">:</td>
                <td>
                    {{ $parent.applicantinfo.ssc_reg }}
                </td>
            </tr>

          <tr>
            <td class="title">Bangla Name</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.bangla_name }}</td>
          </tr>
          <tr>
            <td class="title">Name</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.name }}</td>
          </tr>
          <tr>
            <td class="title">Date of Birth</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.date_of_birth }}</td>
          </tr>
          <tr>
            <td class="title">Gender</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.gender }}</td>
          </tr>
          <tr>
            <td class="title">Nationality</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.nationality }}</td>
          </tr>
          <tr>
            <td class="title">Religion</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.religion }}</td>
          </tr>
          <tr>
            <td class="title">Mobile</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mobile }}</td>
          </tr>
          <tr>
            <td class="title">Email</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.email }}</td>
          </tr>
          <tr>
            <td class="title">Previous School</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.previous_school }}</td>
          </tr>
          <tr>
            <td class="title">Medium of Instruction of Last School</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.medium_previous_school }}</td>
          </tr>
          <tr>
            <td class="title">Class in which the student was last placed</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.class_previous_school }}</td>
          </tr>
          <tr>
            <th colspan="3">Education Information</th>
          </tr>
          <tr>
            <td colspan="3">
              <table
                class="table table-bordered"
                v-if="$parent.applicantinfo.educations.length > 0"
              >
                <tr>
                  <th width="20%">Exam</th>
                  <th width="20%">Roll NO</th>
                  <th width="20%">Year</th>
                  <th width="20%">Board</th>
                  <th width="20%">GPA</th>
                </tr>
                <slot v-for="(education, index) in $parent.applicantinfo.educations">
                  <tr :key="index">
                    <td>{{ education.exam }}</td>
                    <td>{{ education.roll_no }}</td>
                    <td>{{ education.year }}</td>
                    <td>{{ education.board }}</td>
                    <td>{{ education.gpa }}</td>
                  </tr>
                </slot>
              </table>
            </td>
          </tr>
        </table>
        <table class="table table-bordered table-striped">
          <tr>
            <td class="title">Father Name</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.father_name }}</td>
          </tr>
          <tr>
            <td class="title">Father Occupation</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.father_occupation }}</td>
          </tr>
          <tr>
            <td class="title">Father Organization</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.father_organization }}</td>
          </tr>
          <tr>
            <td class="title">Father Designation</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.father_designation }}</td>
          </tr>
          <tr>
            <td class="title">Father Mobile No</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.father_mobile_no }}</td>
          </tr>
          <tr>
            <td class="title">Father Email</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.father_email }}</td>
          </tr>
          <tr>
            <td class="title">Father Location</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.father_location }}</td>
          </tr>
          <tr>
            <td class="title">Mother Name</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mother_name }}</td>
          </tr>
          <tr>
            <td class="title">Mother Occupation</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mother_occupation }}</td>
          </tr>
          <tr>
            <td class="title">Mother Organization</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mother_organization }}</td>
          </tr>
          <tr>
            <td class="title">Mother Designation</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mother_designation }}</td>
          </tr>
          <tr>
            <td class="title">Mother Mobile No</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mother_mobile_no }}</td>
          </tr>
          <tr>
            <td class="title">Mother Email</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mother_email }}</td>
          </tr>
          <tr>
            <td class="title">Mother Location</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.mother_location }}</td>
          </tr>
          <tr>
            <td class="title">Present Phone</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.present_phone }}</td>
          </tr>
          <tr>
            <td class="title">Present Mobile</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.present_mobile }}</td>
          </tr>
          <tr>
            <td class="title">Present Address</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.present_address }}</td>
          </tr>
          <tr>
            <td class="title">Permanent Phone</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.permanent_phone }}</td>
          </tr>
          <tr>
            <td class="title">Permanent Mobile</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.permanent_mobile }}</td>
          </tr>
          <tr>
            <td class="title">Permanent Address</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.permanent_address }}</td>
          </tr>
          <tr>
            <td class="title">Guardian Name</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.guardian_name }}</td>
          </tr>
          <tr>
            <td class="title">Guardian Occupation</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.guardian_occupation }}</td>
          </tr>
          <tr>
            <td class="title">Guardian Organization</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.guardian_organization }}</td>
          </tr>
          <tr>
            <td class="title">Guardian Designation</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.guardian_designation }}</td>
          </tr>
          <tr>
            <td class="title">Guardian Mobile No</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.guardian_mobile_no }}</td>
          </tr>
          <tr>
            <td class="title">Guardian Email</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.guardian_email }}</td>
          </tr>
          <tr>
            <td class="title">Guardian Location</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.guardian_location }}</td>
          </tr>
          <tr>
            <td class="title">Nature of Illness</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.nature_of_illness }}</td>
          </tr>
          <tr>
            <td class="title">Chronic Disease</td>
            <td class="colon">:</td>
            <td>{{ $parent.applicantinfo.chronic_disease }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import image from "../../../assets/img/avatar.png";

export default {
  name: "Dashboard",
  data() {
    return {
      image_src: image
    };
  },
  mounted() {
    this.$root.loading = false;
  },
};
</script>
