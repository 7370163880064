<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12">
            <h4 class="m-0 font-weight-bold">All Careers</h4>
            <hr />
          </div>
          <slot v-if="Object.keys(table.datas).length > 0">
            <div
              class="job-preview"
              v-for="(item, index) in table.datas"
              :key="index"
            >
              <router-link
                :to="{ name: 'career.detail', params: { id: item.id } }"
              >
                <h1 style="font-size: 24px">{{ item.title }}</h1>
                <div class="apply">
                  <span class="date">
                    Application Deadline :
                    <strong>{{ item.deadline }}</strong>
                  </span>
                </div>
              </router-link>
            </div>
            <div class="col-sm-12">
              <div class="page-footer" v-if="meta.last_page > 1">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-sm-12 col-md-5 p-0">
                      <div role="status" aria-live="polite">
                        Showing {{ meta.from }} to {{ meta.to }} of
                        {{ meta.total }} entries
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-7 p-0">
                      <nav aria-label="Page navigation example">
                        <ul
                          class="pagination pagination-sm justify-slider_content-end"
                        >
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-on:click="get_datas(1)"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" v-if="meta.current_page > 2">
                            <a
                              class="page-link"
                              v-on:click="get_datas(meta.current_page - 2)"
                              >{{ meta.current_page - 2 }}</a
                            >
                          </li>
                          <li class="page-item" v-if="meta.current_page > 1">
                            <a
                              class="page-link"
                              v-on:click="get_datas(meta.current_page - 1)"
                              >{{ meta.current_page - 1 }}</a
                            >
                          </li>
                          <li class="page-item active">
                            <a
                              class="page-link"
                              v-on:click="get_datas(meta.current_page)"
                              >{{ meta.current_page }}</a
                            >
                          </li>
                          <li
                            class="page-item"
                            v-if="meta.current_page < meta.last_page"
                          >
                            <a
                              class="page-link"
                              v-on:click="get_datas(meta.current_page + 1)"
                              >{{ meta.current_page + 1 }}</a
                            >
                          </li>
                          <li
                            class="page-item"
                            v-if="meta.current_page + 1 < meta.last_page"
                          >
                            <a
                              class="page-link"
                              v-on:click="get_datas(meta.current_page + 2)"
                              >{{ meta.current_page + 2 }}</a
                            >
                          </li>
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-on:click="get_datas(meta.last_page)"
                              aria-label="Next"
                            >
                              <span aria-hidden="true">&raquo;</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slot>
          <div class="panel-body text-center mt-3" v-else>
            <h4>No Data Available !!</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Careers",
  },
];
const routesname = {
  view: "careers.show",
};
export default {
  name: "About",
  components: {
    Banner,
  },
  data() {
    return {
      breadcumb: breadcumb,
      breadcrumbTitle: "Careers",
      search_data: {
        field_name: 0,
        value: "",
      },
      table: {
        title: "Simple Table",
        routes: routesname,
        datas: [],
      },
      pagination: [],
      meta: [],
      links: [],
    };
  },
  methods: {
    get_datas: function (pageindex) {
      this.$root.loading = true;
      let url = "/home/careers";
      if (pageindex > 0) {
        url = "/home/careers?page=" + pageindex;
      }
      axios
        .get(url)
        .then((res) => {
          this.table.datas = res.data.data;
          this.meta = res.data.meta;
          this.links = res.data.links;
          this.$parent.debug_data = res;
          this.$root.loading = false;
        });
    },
  },
  mounted() {
    this.get_datas();
  },
};
</script>
