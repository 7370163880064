<template>
  <div v-if="!$root.loading">
    <div v-if="hometopNotice" style="margin-top: 120px; margin-right: 25px">
      <h3 class="text-center">Notice</h3>
      <hr style="border-top: 2px solid rgb(27, 133, 157); width: 100px" />
      <br />
      <div class="tab_category">
        <b-tabs align="center">
          <b-tab title="General" active>
            <div v-if="hometopNotice['general'] == 0">
              <h5 class="text-center">Notice not found</h5>
            </div>
            <ul v-else>
              <li
                v-for="(general, generalNotice) in hometopNotice['general']"
                :key="generalNotice"
               >
                <div style="display: flex; margin-bottom: 5px">
                  <div class="">
                    <router-link
                      v-if="general.type === 'content'"
                      :to="{
                        name: 'notice.detail',
                        params: {
                          category: general.category,
                          slug: general.slug,
                        },
                      }"
                    >
                      <h5 class="notice_cat">
                        <img
                          v-if="general.new == 'yes'"
                          style="height: 30px; width: 30px"
                          :src="$root.baseurl + '/img/blingking.gif'"
                          alt="image"
                        />
                        {{ getPostBody(general.title, 150) }}
                        <span
                          class="pull-right"
                          style="font-size: 10px; color: red"
                          v-if="general.updated != '0' && general.new == 'no'"
                          >Notice has been updated {{ general.updated }} days
                          ago</span
                        >
                      </h5>
                    </router-link>
                    <a
                      v-else
                      :href="general.FullUrl + general.file"
                      target="_blank"
                    >
                      <h5 class="notice_cat" style="font-size: 14px">
                        <img
                          v-if="general.new == 'yes'"
                          style="height: 30px; width: 30px"
                          :src="$root.baseurl + '/img/blingking.gif'"
                          alt="image"
                        />
                        {{ general.title }}
                        <span
                          class="pull-right"
                          style="font-size: 10px; color: red"
                          v-if="general.updated != '0' && general.new == 'no'"
                          >Notice has been updated {{ general.updated }} days
                          ago</span
                        >
                      </h5>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <router-link
              :to="{ name: 'notice', params: { category: 'General' } }"
              class="btn btn-primary mt-3 bottom-alignn"
              >View All</router-link
            >
          </b-tab>
          <b-tab title="Academic">
            <div v-if="hometopNotice['academic'] == 0">
              <h5 class="text-center">Notice not found</h5>
            </div>
            <ul v-else>
              <li
                v-for="(model, innerIndex) in hometopNotice['academic']"
                :key="innerIndex"
              >
                <div style="display: flex; margin-bottom: 5px">
                  <div class="">
                    <router-link
                      v-if="model.type === 'content'"
                      :to="{
                        name: 'notice.detail',
                        params: { category: model.category, slug: model.slug },
                      }"
                    >
                      <h5 class="notice_cat" style="font-size: 14px">
                        <img
                          v-if="model.new == 'yes'"
                          style="height: 30px; width: 30px"
                          :src="$root.baseurl + '/img/blingking.gif'"
                          alt="image"
                        />
                        {{ getPostBody(model.title, 150) }}
                        <span
                          class="pull-right"
                          style="font-size: 10px; color: red"
                          v-if="model.updated != '0' && model.new == 'no'"
                          >Notice has been updated {{ model.updated }} days
                          ago</span
                        >
                      </h5>
                    </router-link>
                    <a
                      v-else
                      :href="model.FullUrl + model.file"
                      target="_blank"
                    >
                      <h5 class="notice_cat" style="font-size: 14px">
                        <img
                          v-if="model.new == 'yes'"
                          style="height: 30px; width: 30px"
                          :src="$root.baseurl + '/img/blingking.gif'"
                          alt="image"
                        />
                        {{ model.title }}
                        <span
                          class="pull-right"
                          style="font-size: 10px; color: red"
                          v-if="model.updated != '0' && model.new == 'no'"
                          >Notice has been updated {{ model.updated }} days
                          ago</span
                        >
                      </h5>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <router-link
              :to="{ name: 'notice', params: { category: 'Academic' } }"
              class="btn btn-primary mt-3 bottom-alignn"
              >View All</router-link
            >
          </b-tab>
          <b-tab title="Admission">
            <div v-if="hometopNotice['admission'] == 0">
              <h5 class="text-center">Notice not found</h5>
            </div>
            <ul v-else>
              <li
                v-for="(admission, admissionNotice) in hometopNotice[
                  'admission'
                ]"
                :key="admissionNotice"
              >
                <div style="display: flex; margin-bottom: 5px">
                  <div class="">
                    <router-link
                      v-if="admission.type === 'content'"
                      :to="{
                        name: 'notice.detail',
                        params: {
                          category: admission.category,
                          slug: admission.slug,
                        },
                      }"
                    >
                      <h5 class="notice_cat" style="font-size: 14px">
                        <img
                          v-if="admission.new == 'yes'"
                          style="height: 30px; width: 30px"
                          :src="$root.baseurl + '/img/blingking.gif'"
                          alt="image"
                        />
                        {{ getPostBody(admission.title, 150) }}
                        <span
                          class="pull-right"
                          v-if="
                            admission.updated != '0' && admission.new == 'no'
                          "
                          >Notice has been updated {{ admission.updated }} days
                          ago</span
                        >
                      </h5>
                    </router-link>
                    <a
                      v-else
                      :href="admission.FullUrl + admission.file"
                      target="_blank"
                    >
                      <h5 class="notice_cat" style="font-size: 14px">
                        <img
                          v-if="admission.new == 'yes'"
                          style="height: 30px; width: 30px"
                          :src="$root.baseurl + '/img/blingking.gif'"
                          alt="image"
                        />
                        {{ admission.title }}
                        <span
                          class="pull-right"
                          style="font-size: 10px; color: red"
                          v-if="
                            admission.updated != '0' && admission.new == 'no'
                          "
                          >Notice has been updated {{ admission.updated }} days
                          ago</span
                        >
                      </h5>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <router-link
              :to="{ name: 'notice', params: { category: 'Admission' } }"
              class="btn btn-primary mt-3 bottom-alignn"
              >View All</router-link
            >
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "hometopNotice",
  data() {
    return {
      hometopNotice: {},
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/noticecat")
        .then((respons) => {
          this.hometopNotice = respons.data.noticecat;
          this.$root.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style>
.tab_category .nav-link {
  padding: 5px !important;
  font-size: 16px !important;
}
.tab_category .nav-tabs .nav-link {
  border: 1px solid #1b859d5c !important;
}
.nav-tabs .nav-link.active {
  background-color: #1b859d;
  border-color: #fff;
  color: #fff;
}
.tab-content {
  padding: 10px;
  border: 1px solid #1b859d47;
  padding-bottom: 15px;
  min-height: 362px;
}
.bottom-alignn {
  position: absolute;
  bottom: 20px;
  left: 32%;
}
.tab_category .btn-primary {
  background-color: #1c3b5e !important;
}
.notice_cat {
  font-size: 14px;
  color: #555252;
}
.notice_cat:hover {
  color: #1b859d;
}
.notice_cat > span {
  color: #1b859d !important;
}
</style>
