var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$root.loading)?_c('div',[(_vm.hometopNotice)?_c('div',{staticStyle:{"margin-top":"120px","margin-right":"25px"}},[_c('h3',{staticClass:"text-center"},[_vm._v("Notice")]),_vm._v(" "),_c('hr',{staticStyle:{"border-top":"2px solid rgb(27, 133, 157)","width":"100px"}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"tab_category"},[_c('b-tabs',{attrs:{"align":"center"}},[_c('b-tab',{attrs:{"title":"General","active":""}},[(_vm.hometopNotice['general'] == 0)?_c('div',[_c('h5',{staticClass:"text-center"},[_vm._v("Notice not found")])]):_c('ul',_vm._l((_vm.hometopNotice['general']),function(general,generalNotice){return _c('li',{key:generalNotice},[_c('div',{staticStyle:{"display":"flex","margin-bottom":"5px"}},[_c('div',{},[(general.type === 'content')?_c('router-link',{attrs:{"to":{
                      name: 'notice.detail',
                      params: {
                        category: general.category,
                        slug: general.slug,
                      },
                    }}},[_c('h5',{staticClass:"notice_cat"},[(general.new == 'yes')?_c('img',{staticStyle:{"height":"30px","width":"30px"},attrs:{"src":_vm.$root.baseurl + '/img/blingking.gif',"alt":"image"}}):_vm._e(),_vm._v("\n                      "+_vm._s(_vm.getPostBody(general.title, 150))+"\n                      "),(general.updated != '0' && general.new == 'no')?_c('span',{staticClass:"pull-right",staticStyle:{"font-size":"10px","color":"red"}},[_vm._v("Notice has been updated "+_vm._s(general.updated)+" days\n                        ago")]):_vm._e()])]):_c('a',{attrs:{"href":general.FullUrl + general.file,"target":"_blank"}},[_c('h5',{staticClass:"notice_cat",staticStyle:{"font-size":"14px"}},[(general.new == 'yes')?_c('img',{staticStyle:{"height":"30px","width":"30px"},attrs:{"src":_vm.$root.baseurl + '/img/blingking.gif',"alt":"image"}}):_vm._e(),_vm._v("\n                      "+_vm._s(general.title)+"\n                      "),(general.updated != '0' && general.new == 'no')?_c('span',{staticClass:"pull-right",staticStyle:{"font-size":"10px","color":"red"}},[_vm._v("Notice has been updated "+_vm._s(general.updated)+" days\n                        ago")]):_vm._e()])])],1)])])}),0),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary mt-3 bottom-alignn",attrs:{"to":{ name: 'notice', params: { category: 'General' } }}},[_vm._v("View All")])],1),_vm._v(" "),_c('b-tab',{attrs:{"title":"Academic"}},[(_vm.hometopNotice['academic'] == 0)?_c('div',[_c('h5',{staticClass:"text-center"},[_vm._v("Notice not found")])]):_c('ul',_vm._l((_vm.hometopNotice['academic']),function(model,innerIndex){return _c('li',{key:innerIndex},[_c('div',{staticStyle:{"display":"flex","margin-bottom":"5px"}},[_c('div',{},[(model.type === 'content')?_c('router-link',{attrs:{"to":{
                      name: 'notice.detail',
                      params: { category: model.category, slug: model.slug },
                    }}},[_c('h5',{staticClass:"notice_cat",staticStyle:{"font-size":"14px"}},[(model.new == 'yes')?_c('img',{staticStyle:{"height":"30px","width":"30px"},attrs:{"src":_vm.$root.baseurl + '/img/blingking.gif',"alt":"image"}}):_vm._e(),_vm._v("\n                      "+_vm._s(_vm.getPostBody(model.title, 150))+"\n                      "),(model.updated != '0' && model.new == 'no')?_c('span',{staticClass:"pull-right",staticStyle:{"font-size":"10px","color":"red"}},[_vm._v("Notice has been updated "+_vm._s(model.updated)+" days\n                        ago")]):_vm._e()])]):_c('a',{attrs:{"href":model.FullUrl + model.file,"target":"_blank"}},[_c('h5',{staticClass:"notice_cat",staticStyle:{"font-size":"14px"}},[(model.new == 'yes')?_c('img',{staticStyle:{"height":"30px","width":"30px"},attrs:{"src":_vm.$root.baseurl + '/img/blingking.gif',"alt":"image"}}):_vm._e(),_vm._v("\n                      "+_vm._s(model.title)+"\n                      "),(model.updated != '0' && model.new == 'no')?_c('span',{staticClass:"pull-right",staticStyle:{"font-size":"10px","color":"red"}},[_vm._v("Notice has been updated "+_vm._s(model.updated)+" days\n                        ago")]):_vm._e()])])],1)])])}),0),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary mt-3 bottom-alignn",attrs:{"to":{ name: 'notice', params: { category: 'Academic' } }}},[_vm._v("View All")])],1),_vm._v(" "),_c('b-tab',{attrs:{"title":"Admission"}},[(_vm.hometopNotice['admission'] == 0)?_c('div',[_c('h5',{staticClass:"text-center"},[_vm._v("Notice not found")])]):_c('ul',_vm._l((_vm.hometopNotice[
                'admission'
              ]),function(admission,admissionNotice){return _c('li',{key:admissionNotice},[_c('div',{staticStyle:{"display":"flex","margin-bottom":"5px"}},[_c('div',{},[(admission.type === 'content')?_c('router-link',{attrs:{"to":{
                      name: 'notice.detail',
                      params: {
                        category: admission.category,
                        slug: admission.slug,
                      },
                    }}},[_c('h5',{staticClass:"notice_cat",staticStyle:{"font-size":"14px"}},[(admission.new == 'yes')?_c('img',{staticStyle:{"height":"30px","width":"30px"},attrs:{"src":_vm.$root.baseurl + '/img/blingking.gif',"alt":"image"}}):_vm._e(),_vm._v("\n                      "+_vm._s(_vm.getPostBody(admission.title, 150))+"\n                      "),(
                          admission.updated != '0' && admission.new == 'no'
                        )?_c('span',{staticClass:"pull-right"},[_vm._v("Notice has been updated "+_vm._s(admission.updated)+" days\n                        ago")]):_vm._e()])]):_c('a',{attrs:{"href":admission.FullUrl + admission.file,"target":"_blank"}},[_c('h5',{staticClass:"notice_cat",staticStyle:{"font-size":"14px"}},[(admission.new == 'yes')?_c('img',{staticStyle:{"height":"30px","width":"30px"},attrs:{"src":_vm.$root.baseurl + '/img/blingking.gif',"alt":"image"}}):_vm._e(),_vm._v("\n                      "+_vm._s(admission.title)+"\n                      "),(
                          admission.updated != '0' && admission.new == 'no'
                        )?_c('span',{staticClass:"pull-right",staticStyle:{"font-size":"10px","color":"red"}},[_vm._v("Notice has been updated "+_vm._s(admission.updated)+" days\n                        ago")]):_vm._e()])])],1)])])}),0),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary mt-3 bottom-alignn",attrs:{"to":{ name: 'notice', params: { category: 'Admission' } }}},[_vm._v("View All")])],1)],1)],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }