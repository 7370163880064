<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <h5 class="font-weight-bold text-uppercase" style="margin: auto;">{{carrer.title}}</h5>
        </div>
        <div class="job-preview">
          <p v-html="carrer.description"></p>
          <div class="apply text-center">
            <router-link
              class="btn btn-success"
              :to="{ name:'carrer.applyOnline', params: { id: carrer.id }}"
            >Apply Online</router-link>
            <span class="date">
              Application Deadline :
              <strong>{{ carrer.deadline }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
const breadcumb = [
  {
    title: "Carrer"
  }
];
export default {
  name: "About",
  components: {
    Banner
  },
  data() {
    return {
      carrer: {},
      breadcrumbTitle: "",
      breadcumb: breadcumb
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/carrer_detail/" + this.$route.params.id)
        .then(respons => {
          this.$root.loading = false;
          this.carrer = respons.data.result;
          this.breadcrumbTitle = respons.data.result.title;
          this.breadcumb = [
            { title: "Carrer", route_name: "carrer" },
            { title: this.breadcrumbTitle }
          ];
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
