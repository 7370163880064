<template>
  <div id="payment">
    <div class="row align-items-center">
      <h5 class="font-weight-bold text-uppercase mb-4" style="margin: auto">
        Quiz
      </h5>
    </div>
    <div class="row" v-if="Object.keys(exam).length > 0">
      <div class="col-md-4" v-for="data in exam" :key="data.id">
        <slot v-if="data.time <= current_time && data.to_time >= current_time">
          <div class="text-center box-body">
            <h5 class="text-uppercase text-success m-0">
              Exam Already Applied
            </h5>
            <slot v-if="data.auto_publish == 'yes'">
              <slot v-if="data.can_view_reslt == 'yes'">
                <router-link
                  :to="{
                    name: 'student.online_exam_result_detail',
                    params: { examId: data.id },
                  }"
                  >View Result</router-link
                >
              </slot>
            </slot>
            <slot v-if="data.can_view_exam_paper == 'yes'">
              <router-link
                :to="{
                  name: 'student.quiz_detail',
                  params: { examId: data.id },
                }"
                :title="data.subject.name"
                v-b-tooltip.hover.topleft
                >Go to question papper</router-link
              >
            </slot>
            <h5 class="text-uppercase m-0" v-if="data.subject">
              {{ data.subject.name }}
            </h5>
            <i class="fas fa-calendar-alt"></i>
            {{ data.full_date }}
            <br />
            <i class="fas fa-clock"></i>
            {{ moment(data.date + " " + data.time).format("h:m A") }} -
            {{ moment(data.date + " " + data.to_time).format("h:m A") }}
          </div>
        </slot>
        <slot v-else>
          <div class="text-center box-body">
            <slot v-if="data.id == applied[data.id]">
              <h5 class="text-uppercase text-success m-0">
                Exam Already Applied
              </h5>
            </slot>
            <slot
              v-if="data.auto_publish == 'yes' && data.id == applied[data.id]"
            >
              <slot v-if="data.can_view_reslt == 'yes'">
                <router-link
                  :to="{
                    name: 'student.online_exam_result_detail',
                    params: { examId: data.id },
                  }"
                  >View Result</router-link
                >
              </slot>
            </slot>
            <slot v-if="data.can_view_exam_paper == 'yes'">
              <router-link
                :to="{
                  name: 'student.quiz_detail',
                  params: { examId: data.id },
                }"
                :title="data.subject.name"
                v-b-tooltip.hover.topleft
                >Go to question papper</router-link
              >
            </slot>
            <h5 class="text-uppercase m-0" v-if="data.subject">
              {{ data.subject.name }}
            </h5>
            <i class="fas fa-calendar-alt"></i>
            {{ data.full_date }}
            <br />
            <i class="fas fa-clock"></i>
            {{ moment(data.date + " " + data.time).format("h:m A") }} -
            {{ moment(data.date + " " + data.to_time).format("h:m A") }}
          </div>
        </slot>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <h5 class="text-center">No Exam Fround Right Now</h5>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      exam: {},
      applied: {},
      current_time: moment().format("HH:mm"),
      errors: [],
    };
  },
  computed: {},
  methods: {},
  created() {
    axios.get("student/quiz-archive").then((response) => {
        if(response.data){
            this.exam = response.data.data? response.data.data : {};
            this.applied = response.data.applied? response.data.applied : {};
        }
    });
    this.$root.loading = false;
  },
};
</script>
