<template>
    <div class="about">
        <div class="row student-profile" v-if="adminssion_info">
            <div class="row align-items-center mb-3" style="margin: auto">
                <h5 class="font-weight-bold text-uppercase">
                    {{ applicant.info.admission_info.title }}
                </h5>
            </div>
            <div class="col-lg-12" v-if="applicant.info">
                <slot v-if="applicant.info.is_payment <= 0">
                    <table class="table">
                        <tr>
                            <td>ApplicantID</td>
                            <td>:</td>
                            <td>{{ applicant.info.applicantid }}</td>
                        </tr>
                        <tr>
                            <td>Applicant Name</td>
                            <td>:</td>
                            <td>{{ applicant.info.name }}</td>
                        </tr>
                        <tr>
                            <td>Mobile</td>
                            <td>:</td>
                            <td>{{ applicant.info.mobile }}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>:</td>
                            <td>{{ applicant.info.email }}</td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td>:</td>
                            <td>{{ applicant.info.exam_fee }}</td>
                        </tr>
                    </table>

                    <slot v-if="site_data.payment_on_off & (ps != 'Paid')">
                        <div class="col-12">
                            <div class="payment-option">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        v-model="paymentgateway"
                                        type="radio"
                                        name="paymentgateway"
                                        id="tbl"
                                        value="tbl"
                                    />
                                    <label class="form-check-label" for="tbl"
                                        >Trust Bank Ltd</label
                                    >
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        v-model="paymentgateway"
                                        type="radio"
                                        name="paymentgateway"
                                        id="dbbl"
                                        value="dbbl"
                                    />
                                    <label class="form-check-label" for="dbbl"
                                        >Dutch Bangla Bank Ltd.</label
                                    >
                                </div>
                            </div>
                        </div>
                        <slot v-if="paymentgateway == 'tbl'">
                            <div class="text-center mt-3">
                                <form
                                    v-if="tblweb"
                                    id="tblWebPayment"
                                    method="post"
                                    target="__blank"
                                    :action="$root.variable.tblweb.api_url"
                                >
                                    <input
                                        type="hidden"
                                        id="OrderID"
                                        name="OrderID"
                                        :value="transactionID"
                                    />
                                    <input
                                        type="hidden"
                                        name="Amount"
                                        :value="applicant.info.exam_fee"
                                    />
                                    <input
                                        type="hidden"
                                        name="FullName"
                                        :value="applicant.info.applicantid"
                                    />
                                    <input
                                        type="hidden"
                                        name="Email"
                                        :value="applicant.info.email"
                                    />
                                    <input
                                        type="hidden"
                                        name="MerchantID"
                                        :value="
                                            $root.variable.tblweb.MerchantID
                                        "
                                    />
                                    <input
                                        type="hidden"
                                        name="PaymentSuccessUrl"
                                        :value="
                                            $root.variable.tblweb.success_url
                                        "
                                    />
                                </form>

                                <button
                                    @click="
                                        paywith_tbl(
                                            applicant.info.applicantid,
                                            applicant.info.exam_fee
                                        )
                                    "
                                    class="btn btn-success"
                                    type="Button"
                                >
                                    Pay Now
                                </button>
                            </div>
                        </slot>
                        <slot v-if="paymentgateway == 'dbbl'">
                            <div class="col-12">
                                <div class="payment-option custom-box">
                                    <div
                                        class="form-check form-check-inline"
                                        v-for="(card_type, index) in $root
                                            .variable.dbbl_options"
                                        :key="index"
                                    >
                                        <label
                                            class="form-check-label"
                                            style="font-size: 10px"
                                        >
                                            <input
                                                class="form-check-input"
                                                v-model="dbbl.cardtype"
                                                type="radio"
                                                name="dbbl_card_type"
                                                id="dbbl_card_type"
                                                :value="index"
                                            />

                                            <img
                                                :src="
                                                    $root.baseurl +
                                                        '/images/' +
                                                        card_type.image
                                                "
                                                style="width: 40px; height: 25px"
                                            />
                                            <br />
                                            {{ card_type.name }}
                                        </label>
                                    </div>
                                    <br />
                                    <button
                                        v-if="dbbl.cardtype"
                                        class="btn btn-success mt-3"
                                        type="Button"
                                        @click="
                                            paywith_dbbl(
                                                applicant.info.applicantid,
                                                applicant.info.exam_fee
                                            )
                                        "
                                    >
                                        Pay Now
                                    </button>
                                </div>
                            </div>
                        </slot>
                        <!-- /Invoice -->
                    </slot>
                </slot>
                <slot v-if="applicant.info.is_payment > 0">
                    <div class="p-3 mb-2 bg-success text-white text-center">
                        You have already Paid
                    </div>
                </slot>
            </div>
        </div>

        <div class="panel-body" v-else>
            <div
                class="alert alert-danger col-4"
                role="alert"
                style="margin: auto"
                v-if="is_hit"
            >
                <b>Admission is close</b>
            </div>
        </div>
    </div>
</template>

<script>
const breadcumb = [
    {
        title: "Applicant Dashboard"
    }
];
export default {
    name: "Login",
    components: {},
    data() {
        return {
            tblweb: false,
            breadcrumbTitle: "",
            text: "",
            errors: {},
            post: null,
            error: null,
            breadcumb: breadcumb,
            ps: "Processing",
            paymentgateway: null,
            applicant: {
                info: {
                    admission_info: {},
                    std_class: null
                }
            },
            site_data: {
                payment_on_off: false
            },
            transactionID: "",
            dbbl: {
                amount: null,
                cardtype: null,
                txnrefnum: null
            },
            tbl: {
                amount: null,
                applicantid: null
            },
            adminssion_info: "",
            is_hit: false
        };
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    },
    watch: {
        // call again the method if the route changes
        $route: "fetchData"
    },
    methods: {
        submit: function(e) {
            this.$root.loading = true;
            axios
                .post("/applicant/login/submit", this.data)
                .then(res => {
                    this.$root.loading = false;
                    if (!res.data) {
                        this.responseMsg = true;
                        this.text = "Sorry!! Your Login Information is Wrong";
                    }
                })
                .catch(error => {
                    if (error) {
                        this.responseMsg = true;
                        this.text = "Sorry!! Your Login Information is Wrong";
                    }
                });
        },
        fetchData: function() {
            this.error = this.post = null;
            this.loading = true;
            // replace `getPost` with your data fetching util / API wrapper
        },
        paynow: function() {},
        paywith_dbbl: function(studentid, amount) {
            this.$root.loading = true;
            this.dbbl.amount = amount;
            this.dbbl.txnrefnum = studentid;
            axios
                .post("applicant/dbblcheckout", this.dbbl)
                .then(res => {
                    if (res.data.is_true) {
                        window.location.href = res.data.url;
                    } else {
                        this.$toaster.error("Sorry! Someting Wrong");
                    }
                })
                .then(() => {
                    this.$root.loading = false;
                });
        },
        paywith_tbl: function(studentid, amount) {
            this.$root.loading = true;
            this.tblweb = true;
            this.tbl.amount = amount;
            this.tbl.applicantid = studentid;
            axios
                .post("applicant/tblcheckout", this.tbl)
                .then(res => {
                    $("#OrderID").val(res.data.RefID);
                    $("#tblWebPayment").submit();
                    this.tblweb = false;
                })
                .then(() => {
                    this.$root.loading = false;
                    this.tblweb = false;
                });
        },
        get_data() {
            axios.get("/all_layout_data/topHead").then(respons => {
                this.site_data = respons.data.site_data;
            });
        },
        get_adminssion_info: function() {
            let url = "/applicant/adminssion_info";
            axios.get(url).then(res => {
                this.adminssion_info = res.data;
                this.is_hit = true;
            });
        }
    },
    mounted() {
        this.loggedIn();
        this.loggedApplicant();
        this.get_data();
        this.get_adminssion_info();
        this.$root.loading = false;
    },
    created() {
        setInterval(() => {
            this.get_adminssion_info();
        }, 10000);
    }
};
</script>
