<template>
  <div class="about" v-if="!$root.loading">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <div class="contact py-4">
      <div class="container">
        <div class="row" v-if="result.payment_status">
          <div class="col-md-12">
            <div v-if="result.payment_status=='Paid'" class="alert alert-success" role="alert">
                <h3>Your Trunsection Complete Successfully!</h3>
                <p>
                  <strong>Transection ID :</strong>
                  {{result.transactionID}}
                </p>
                <p>
                  <strong>Ref. ID :</strong>
                  {{result.RefID}}
                </p>
                <p>
                  <strong>Amount :</strong>
                  {{result.amount}}
                </p>
            </div>
            <div v-if="result.payment_status!='Paid'" class="alert alert-danger" role="alert">
              <h3>Your Trunsection is declined!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./pages/Banner.vue";
export default {
  name: "Message",
  components: {
    Banner
  },
  data() {
    return {
      result: {},
      breadcrumbTitle: "Message",
      breadcumb: [{ title: "Message" }]
    };
  },
  methods: {
    asyncData() {
      this.get_data();
    },
    get_data() {
      this.$root.loading = false;
        axios
        .get("success_message/"+this.$route.params.trans_id)
        .then(response => {
            this.result = response.data;
        })
        .catch(errer => {})
        .then(always => {
            this.$root.loading = false;
        });
    }
  },
  mounted() {
    this.get_data();
    this.$root.loading = false;
  },
  created() {
    this.$root.loading = true;
  }
};
</script>
