<template>
  <div class="about">
    <Banner :msg="breadcrumbTitle" :data="breadcumb"></Banner>
    <!--Welcome-text-->
    <div class="welcome-text py-4">
      <div class="container">
        <div class="row align-items-center">
          <h5 class="font-weight-bold text-uppercase" style="margin: auto">
            Applicant Login
          </h5>
        </div>
        <div class>
          <form v-on:submit.prevent="submit" class="form-row px-3" id="form">
            <div class="Login col-md-5" style="margin: auto; margin-top: 3%">
              <fieldset class="border rounded p-2 py-4 w-100 shadow-sm">
                <div class="col-12">
                  <div class="form-group col-12">
                    <div class="alert alert-danger" v-if="responseMsg">
                      <a
                        href="#"
                        class="close"
                        data-dismiss="alert"
                        aria-label="close"
                        title="close"
                        >×</a
                      >
                      {{ text }}
                    </div>
                    <label
                      class="col-form-label-sm font-weight-bold mb-0"
                      >Applicant ID</label
                    >
                    <input
                      type="text"
                      v-model="data.applicantid"
                      required
                      placeholder="Applicant ID"
                      class="form-control shadow-none"
                    />
                    <small
                      v-if="errors && errors.applicantid"
                      class="text-danger"
                      >{{ errors.applicantid[0] }}</small
                    >
                  </div>
                  <div class="form-group col-12">
                    <label
                      class="col-form-label-sm font-weight-bold mb-0"
                      >Mobile</label
                    >
                    <input
                      type="text"
                      v-model="data.password"
                      required
                      placeholder="Mobile"
                      class="form-control shadow-none"
                    />
                    <small
                      v-if="errors && errors.password"
                      class="text-danger"
                      >{{ errors.password[0] }}</small
                    >
                  </div>

                  <div class="form-group col-12">
                    <button
                      class="btn btn-sm btn-success mt-3 float-right"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
      <!-- {{errors}} -->
    </div>
    <!--//Welcome-text-->
  </div>
</template>

<script>
import Banner from "./../pages/Banner.vue";
const breadcumb = [
  {
    title: "Applicant Login",
  },
];
export default {
  name: "Login",
  components: {
    Banner,
  },
  data() {
    return {
      data: {
        applicantid: null,
        password: null,
      },
      responseMsg: false,
      breadcrumbTitle: "",
      text: "",
      errors: {},
      breadcumb: breadcumb,
    };
  },
  methods: {
    submit: function (e) {
      this.$root.loading = true;
      axios
        .post("/applicant/login/submit", this.data)
        .then((respons) => {
          this.$root.loading = false;
          if (respons.data) {
            this.$root.applicant.login = true;
            this.$router.push("/applicant/dashboard");
              this.dominionList();
              this.getRoleList();
              this.getAllSystemVariable();
              this.gteLoggedUser();
          } else {
            this.responseMsg = true;
            this.text = "Sorry!! Your Login Information is Wrong";
          }
        })
        .catch((error) => {
          this.$root.loading = false;
          if (error.response.status === 422) {
            this.responseMsg = true;
            this.text = "Sorry!! Admission time is over";
          } else {
            if (error) {
              this.responseMsg = true;
              this.text = "Sorry!! Your Login Information is Wrong";
            }
          }
        });
    },

      dominionList() {
          axios
              .get("dominionList")
              .then(response => {
                  this.$root.allDominionList = response.data;
              })
              .catch(error => {
                  console.log(error.response);
              });
      },
      getRoleList() {
          axios
              .get("roleList")
              .then(response => {
                  this.$root.roleList = response.data;
              })
              .catch(error => {
                  console.log(error.response);
              });
      },
      getAllSystemVariable() {
          axios
              .get("variablelist")
              .then(response => {
                  this.$root.variable = response.data;
              })
              .catch(error => {
                  console.log(error.response);
              });
      },
      gteLoggedUser() {
          axios
              .get('/userinfo')
              .then(response => {
                  this.$root.logged_user = response.data;
                  this.$root.user_permissions = response.data.permisson.permission_list;
                  this.$root.login = true

              })
              .catch(error => {
                  this.$root.login = false
              })
      },
  },
  mounted() {
    // this.loggedOrNot();
  },
};
</script>
