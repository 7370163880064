<template>
  <div class="home-services" v-if="!$root.loading">
    <h4 class="title text-white"> </h4>
    <div class="ho-event ho-event-mob-bot-sp">
      <ul>
        <li v-for="(item, index) in notices" :key="index">
          <div class="ho-ev-date py-0">
            <!-- <span>{{moment(item.start_date).format('DD')}}</span><span>
                    {{moment(item.start_date).format('MMM,YYYY')}}
            </span>-->
            <img class="rounded" :src="NoticeImage" alt />
          </div>
          <div class="ho-ev-link">
            <router-link
              v-if="item.type==='content'"
              :to="{ name:'notice.detail', params: {category: item.category, slug: item.slug }}"
            >
              <h4>{{item.title}}</h4>
            </router-link>
            <a v-else :href="item.FullUrl+ item.file" target="_blank">
              <h4>{{item.title}}</h4>
            </a>
            <p
              style="white-space: normal;"
              v-if="item.type==='content'"
            >{{ getPostBody(item.description,50) }}</p>
          </div>
        </li>
      </ul>
    </div>
    <router-link
      :to="{ name:'notice', params: { category: 'General Notice' }}"
      class="btn btn-light mt-3"
    >View All</router-link>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Notice",
  data() {
    return {
      notices: {},
      NoticeImage: require("./../../../assets/images/photo-4.jpg")
    };
  },
  methods: {
    get_data() {
      this.$root.loading = true;
      axios
        .get("/home/type/notice")
        .then(respons => {
          this.notices = respons.data.notices;
          this.$root.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
