<template>
  <div class="TeacherView" style="margin-top:130px" v-if="!$root.loading">
    <!-- <Breadcrumb :msg="breadcrumbTitle" :data="breadcumb"></Breadcrumb> -->
    <div class="card shadow-sm border-top-custom">
      <div class="card-header py-3">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <form v-on:submit.prevent="search" class="form-row px-3">
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.class_id"
                  :options="extradata2.class_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>Select Class</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col p-0">
                <b-form-select
                  v-model="search_data.section_id"
                  :options="extradata2.section_list"
                  size="sm"
                  class="mb-0"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>Select Section</option>
                  </template>
                </b-form-select>
              </div>
              <div class="form-group mb-0 col">
                <button type="submit" class="btn btn-sm btn-primary rounded-0">
                  <span class="fa fa-search form-control-feedback"></span> Search
                </button>
              </div>
            </form>
          </div>
          <!-- <div class="col-md-3 ml-auto pr-0 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
              <router-link :to="{name:'routineList'}" class="btn btn-sm btn-dark">
                <i class="fas fa-list"></i>
                Back To {{base_route| capitalize}} List
              </router-link>
            </div>
          </div>-->
        </div>
      </div>
      <div class="card-body">
        <div class="tableList py-2">
          <div class="table-responsive">
            <table class="table table-bordered tablesorter table-hover tableLay" v-if="showTime">
              <thead v-if="message == null">
                <tr>
                  <td>Summer</td>
                  <td v-if="data && data.routineDetails[0]">
                    {{ data.routineDetails[0].details[0].duration.start }} -
                    {{ data.routineDetails[0].details[0].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[1]">
                    {{ data.routineDetails[0].details[1].duration.start }} -
                    {{ data.routineDetails[0].details[1].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[2]">
                    {{ data.routineDetails[0].details[2].duration.start }} -
                    {{ data.routineDetails[0].details[2].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[3]">
                    {{ data.routineDetails[0].details[3].duration.start }} -
                    {{ data.routineDetails[0].details[3].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[4]">
                    {{ data.routineDetails[0].details[4].duration.start }} -
                    {{ data.routineDetails[0].details[4].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[5]">
                    {{ data.routineDetails[0].details[5].duration.start }} -
                    {{ data.routineDetails[0].details[5].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[6].duration.start }} -
                    {{ data.routineDetails[0].details[6].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[7].duration.start }} -
                    {{ data.routineDetails[0].details[7].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[8].duration.start }} -
                    {{ data.routineDetails[0].details[8].duration.end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[9]?
                    data.routineDetails[0].details[9].duration.start:'' }} -
                    {{ data.routineDetails[0].details[9]?
                    data.routineDetails[0].details[9].duration.end:'' }}
                  </td>
                </tr>
                <tr>
                  <td>Winter</td>
                  <td v-if="data && data.routineDetails[0]">
                    {{ data.routineDetails[0].details[0].duration.winter_start }} -
                    {{ data.routineDetails[0].details[0].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[1]">
                    {{ data.routineDetails[0].details[1].duration.winter_start }} -
                    {{ data.routineDetails[0].details[1].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[2]">
                    {{ data.routineDetails[0].details[2].duration.winter_start }} -
                    {{ data.routineDetails[0].details[2].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[3]">
                    {{ data.routineDetails[0].details[3].duration.winter_start }} -
                    {{ data.routineDetails[0].details[3].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[4]">
                    {{ data.routineDetails[0].details[4].duration.winter_start }} -
                    {{ data.routineDetails[0].details[4].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[5]">
                    {{ data.routineDetails[0].details[5].duration.winter_start }} -
                    {{ data.routineDetails[0].details[5].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[6].duration.winter_start }} -
                    {{ data.routineDetails[0].details[6].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[7].duration.winter_start }} -
                    {{ data.routineDetails[0].details[7].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[8].duration.winter_start }} -
                    {{ data.routineDetails[0].details[8].duration.winter_end }}
                  </td>
                  <td v-if="data && data.routineDetails[6]">
                    {{ data.routineDetails[0].details[9]?
                    data.routineDetails[0].details[9].duration.winter_start:'' }} -
                    {{ data.routineDetails[0].details[9]?
                    data.routineDetails[0].details[9].duration.winter_end:'' }}
                  </td>
                </tr>
              </thead>
              <h3 v-if="message != null">{{message}}</h3>
              <tbody v-if="message == null">
                <slot v-for="(routineD, index) in data.routineDetails">
                  <tr :key="'A'+index">
                    <td style="width:10px;">{{ routineD.day }}</td>
                    <td colspan="10" class="dataAll">
                      <table class="table table-bordered tablesorter table-hover tableLay mb-0">
                        <slot v-for="(routine,index1) in routineD.details">
                          <td :key="index1" class="perGap">
                            <slot
                              v-if="routine.subject && routine.status == null || routine.status"
                            >
                              <div class="teacher mb-2">{{ routine.teacher }}</div>
                              <hr />
                              <b>{{ routine.subject }}</b>
                            </slot>
                            <slot v-else>
                              <span class="d-flex align-items-center">OFF</span>
                            </slot>
                          </td>
                        </slot>
                        <slot v-if="Object.keys(routineD.details).length <=9">
                          <td class="perGap">
                            <span class="d-flex align-items-center">OFF</span>
                          </td>
                        </slot>
                      </table>
                    </td>
                  </tr>
                </slot>
              </tbody>
            </table>
          </div>
          <!-- <pre>{{ search_data }}</pre> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/Breadcrumb";
const base_route = "classroutine";
const base_route2 = "Report";
const sub_route = "ResultSheet";
const breadcumb = {
  0: {
    title: "Class Routine",
    route_name: base_route + ".index",
    params: {}
  },
  1: {
    title: "Class Routine view",
    route_name: base_route + ".show",
    params: {}
  }
};
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      showTime: false,
      message: null,
      breadcumb: breadcumb,
      breadcrumbTitle: "Class Routine",
      base_route: base_route,

      search_data: {
        class_id: null,
        section_id: null,
        value: null
      },
      data: {
        routineDetails: {}
      },

      extradata: {
        subject_list: []
      },
      extradata2: [],
      table: {
        //title: "Simple Table",
        //columns: tableColumns,
        //routes: routes_name,
        datas: []
      }
    };
  },
  watch: {
    "search_data.class_id": function(val, oldval) {
      if (val) {
        this.getList3("Section", "section_list", val, "std_class_id");
        this.search_data.section_id = null;
        this.search_data.field_name = null;
        this.search_data.value = null;
      }
    }
  },
  methods: {
    get_datas: function(page_index) {
      this.$root.loading = true;
      let url = "/" + base_route2 + "/" + sub_route;
      if (page_index > 0) {
        url = "/" + base_route2 + "/" + sub_route + "/?page=" + page_index;
      }
      axios
        .get(url, { params: this.search_data })
        .then(respons => {
          this.extradata2 = respons.data.extradata;
        })
        .catch(error => {
          console.log(error.respons);
        })
        .then(response => {
          this.$root.loading = false;
        });
    },
    get_data() {
      var id1 = null;
      for (var i = 0; i < this.table.datas.length; i++) {
        if (this.table.datas[i].section_id == this.search_data.section_id) {
          this.id1 = this.table.datas[i]._id;
          this.message = null;
          break;
        } else {
          this.id1 = null;
        }
      }
      this.$root.loading = true;

      if (this.id1 != null) {
        axios
          .get(this.base_route + "/" + this.id1)
          .then(respons => {
            this.data = respons.data;
          })
          .catch(error => {
            console.log(error);
          })
          .then(response => {
            this.$root.loading = false;
          });
      } else {
        this.message = "No Routine Found";
        this.$root.loading = false;
      }
    },
    get_datas_routine: function(page_index) {
      let url = "/api/classroutine";
      if (page_index > 0) {
        url = "/api/classroutine";
      }
      axios
        .get(url, { params: this.search_data })
        .then(respons => {
          // console.log(respons.data.data);
          this.table.datas = respons.data.data;
          this.meta = respons.data.meta;
          this.links = respons.data.links;
          // this.$parent.$parent.debug_data = respons;
          // console.log(respons);
        })
        .catch(error => {
          this.$parent.debug_data = error;
          console.log(error.respons);
        })
        .then(response => {
          this.$root.loading = false;
        });
    },
    search() {
      this.get_data(this.$route.params.id);
      this.showTime = true;
    }
  },
  mounted() {
    //this.get_data(this.$route.params.id);
    this.getSubjectList();
    this.get_datas();
    this.get_datas_routine();
  }
};
</script>


<style>
.perGap1 {
  width: 13px !important;
  font-size: 11px !important;
  line-height: 14px !important;
  height: 70px;
}
.perGap {
  height: 90px;
  font-size: 11px !important;
}
.tableLay {
  table-layout: fixed;
}
.dataAll {
  padding: 0px !important;
}
hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
</style>
